import { Box, Typography } from "@mui/material";
import TabsContainer from "../UpdateStudy/TabsContainer";
import { HeaderStyle } from "./updateStudy.style";

const UpdateStudy = () => {
  return (
    <>
      <Box sx={HeaderStyle}>
        <Typography variant="subtitle2" fontWeight="medium">
          Update Study
        </Typography>
      </Box>
      <TabsContainer />
    </>
  );
};

export default UpdateStudy;
