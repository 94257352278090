import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
  Button,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import {
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../../Common/styles/table";
import LinkConsentModal from "./LinkConsentTab";

const ConsentTab = ({ id }: any) => {
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openLinkModal, setOpenLinkModal] = useState<boolean>(false);
  const [qList, setQList] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleLinkModalVisibility = (visible: boolean) => {
    setOpenLinkModal(visible);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setIsLoading(true);
        let url = `/studies/${id}/consent-form/link?pageNo=${
          page + 1
        }&pageSize=${pageSize}`;
        const res: AxiosResponse = await http.get(url);
        const newSites = res.data?.data?.map((data: any) => {
          return {
            id: data?.id,
            name: data?.consentForm?.name,
          };
        });
        setQList(newSites);
        setTotal(res.data?.meta?.paginationOptions?.totalItems || 0);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchSites();
  }, [page, setTotal, setQList, toggleLoader, id]);

  const unlinkQuestion = async (pivotId: string) => {
    try {
      setIsLoading(true);
      const res: AxiosResponse = await http.delete(
        `/studies/${id}/consent-form/link/${pivotId}`
      );
      toastMessage("success", res.data.message);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      errorToastMessage(err as Error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          mb: 2.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleLinkModalVisibility(true)}
        >
          Link to Study
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="center">Options</StyledTableCell>
          </TableRow>
        </TableHead>
        {!isLoading && (
          <>
            <TableBody>
              {qList.map((row: any) => (
                <TableRow key={row?.id}>
                  <StyledTableCell>{row?.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      component="span"
                      sx={{
                        textDecoration: "underline",
                        textUnderlineOffset: "4.5px",
                        cursor: "pointer",
                      }}
                      onClick={() => unlinkQuestion(row.id)}
                    >
                      Unlink
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {total > pageSize && (
                <TableRow>
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={total}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                </TableRow>
              )}
            </TableFooter>
          </>
        )}
      </Table>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {openLinkModal && (
        <LinkConsentModal
          open={openLinkModal}
          id={id}
          handleModal={handleLinkModalVisibility}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default ConsentTab;
