import { Box, Button, IconButton, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../Redux/hooks";
import {
  addMemberSignature,
  deleteMemberSignature,
  editMemberSignature,
} from "../../../../../../Redux/reducers/consentSlice";
import { CardHeader, CardStyle } from "../../../consentForm.style";
import MemberEdit from "./MemberEdit";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ReactQuill from "react-quill";

const MemberSignature = () => {
  const dispatch = useAppDispatch();
  const { memberSignature } = useAppSelector((state) => state.consent);

  const handleDeleteMemSignature = (id: string) => {
    dispatch(deleteMemberSignature(id));
  };

  const handleEditMemSignature = (index: number, key: string, value: any) => {
    dispatch(editMemberSignature({ index, key, value }));
  };

  const handleAddMemSignature = () => {
    dispatch(addMemberSignature());
  };

  return (
    <>
      <Typography
        variant="h3"
        fontWeight="medium"
        color="text.tertiary"
        mb={3.75}
      >
        Study team member signature(s)
      </Typography>
      <Typography variant="subtitle1" color="text.primary" mb={3.75}>
        Study team member signature(s) can be customized, added or deleted
        below.
      </Typography>
      {memberSignature.map((item: any, index: number) => {
        return (
          <Box key={item.id}>
            {item.edit ? (
              <Box sx={CardStyle} mb={5}>
                <Box sx={CardHeader}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    color="text.tertiary"
                  >
                    Study team member signature(s)
                  </Typography>
                </Box>
                <MemberEdit data={item} index={index} />
              </Box>
            ) : (
              <Box sx={CardStyle} mb={2.5}>
                <Box sx={CardHeader}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    color="text.tertiary"
                  >
                    Study team member signature(s)
                  </Typography>
                  <Box>
                    <IconButton
                      onClick={() =>
                        handleEditMemSignature(index, "edit", true)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteMemSignature(item.id)}
                    >
                      <DeleteIcon sx={{ color: "#F05252" }} />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ p: "20px 20px 40px 20px" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="medium"
                    mb={1.25}
                    color="text.tertiary"
                  >
                    Signature status
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    mb={3.75}
                  >
                    {item.required ? "Required" : "Not required"}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight="medium"
                    mb={1.25}
                    color="text.tertiary"
                  >
                    Signature statement
                  </Typography>
                  <ReactQuill
                    readOnly={true}
                    theme={"bubble"}
                    className={"quill-container quill-bubble-md"}
                    defaultValue={
                      item.statement
                        ? item.statement
                        : "You have not added the signature statement."
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        );
      })}
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddMemSignature}
      >
        Add study team signature
      </Button>
    </>
  );
};

export default MemberSignature;
