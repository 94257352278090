export const VideoOnIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_7172)">
      <path
        d="M15.3334 5.16699L10.6667 8.50033L15.3334 11.8337V5.16699Z"
        stroke="#52525B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33342 3.83301H2.00008C1.2637 3.83301 0.666748 4.42996 0.666748 5.16634V11.833C0.666748 12.5694 1.2637 13.1663 2.00008 13.1663H9.33342C10.0698 13.1663 10.6667 12.5694 10.6667 11.833V5.16634C10.6667 4.42996 10.0698 3.83301 9.33342 3.83301Z"
        stroke="#52525B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_7172">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const VideoOffIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_6889)">
      <path
        d="M7.10663 3.83301H9.33329C9.68691 3.83301 10.0261 3.97348 10.2761 4.22353C10.5261 4.47358 10.6666 4.81272 10.6666 5.16634V7.39301L11.3333 8.05967L15.3333 5.16634V11.833M10.6666 11.1663V11.833C10.6666 12.1866 10.5261 12.5258 10.2761 12.7758C10.0261 13.0259 9.68691 13.1663 9.33329 13.1663H1.99996C1.64634 13.1663 1.3072 13.0259 1.05715 12.7758C0.807102 12.5258 0.666626 12.1866 0.666626 11.833V5.16634C0.666626 4.81272 0.807102 4.47358 1.05715 4.22353C1.3072 3.97348 1.64634 3.83301 1.99996 3.83301H3.33329L10.6666 11.1663Z"
        stroke="#E02424"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666626 1.16699L15.3333 15.8337"
        stroke="#E02424"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_6889">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AudioOnIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_7021)">
      <path
        d="M5.33337 15.833H10.6667"
        stroke="#52525B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.167V15.8337"
        stroke="#52525B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.16699C7.46957 1.16699 6.96086 1.37771 6.58579 1.75278C6.21071 2.12785 6 2.63656 6 3.16699V8.50033C6 9.03076 6.21071 9.53947 6.58579 9.91454C6.96086 10.2896 7.46957 10.5003 8 10.5003C8.53043 10.5003 9.03914 10.2896 9.41421 9.91454C9.78929 9.53947 10 9.03076 10 8.50033V3.16699C10 2.63656 9.78929 2.12785 9.41421 1.75278C9.03914 1.37771 8.53043 1.16699 8 1.16699V1.16699Z"
        stroke="#52525B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 7.16699V8.50033C12.6667 9.738 12.175 10.925 11.2999 11.8002C10.4247 12.6753 9.23772 13.167 8.00004 13.167C6.76236 13.167 5.57538 12.6753 4.70021 11.8002C3.82504 10.925 3.33337 9.738 3.33337 8.50033V7.16699"
        stroke="#52525B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_7021">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AudioOffIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_6886)">
      <path
        d="M5.33337 15.833H10.6667"
        stroke="#E02424"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666626 1.16699L15.3333 15.8337"
        stroke="#E02424"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.72705V3.16705C10.0005 2.67107 9.81669 2.1926 9.48425 1.82452C9.15181 1.45644 8.69447 1.22501 8.201 1.17517C7.70753 1.12532 7.21314 1.26061 6.81381 1.55478C6.41448 1.84894 6.1387 2.28099 6.04 2.76705M6 6.50039V8.50039C6.00035 8.89569 6.11783 9.28203 6.33762 9.61061C6.55741 9.93918 6.86964 10.1953 7.23487 10.3465C7.60011 10.4977 8.00197 10.5373 8.3897 10.4603C8.77743 10.3833 9.13364 10.1931 9.41333 9.91372L6 6.50039Z"
        stroke="#E02424"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 11.8003C10.6826 12.4646 9.84813 12.9193 8.93712 13.1061C8.0261 13.2928 7.08006 13.2031 6.22037 12.8485C5.36068 12.4939 4.62655 11.8905 4.11216 11.1158C3.59776 10.341 3.32657 9.43026 3.33338 8.50033V7.16699M12.6667 7.16699V8.50033C12.6665 8.77529 12.6419 9.04969 12.5934 9.32033"
        stroke="#E02424"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.167V15.8337"
        stroke="#E02424"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_6886">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const EndCallIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 11.7797V13.7797C14.6675 13.9654 14.6294 14.1492 14.555 14.3193C14.4807 14.4894 14.3716 14.6421 14.2348 14.7676C14.0979 14.8932 13.9364 14.9887 13.7605 15.0482C13.5847 15.1077 13.3983 15.1298 13.2134 15.1131C11.1619 14.8902 9.19137 14.1892 7.46004 13.0664C5.84926 12.0428 4.48359 10.6772 3.46004 9.06641C2.33336 7.32721 1.6322 5.34707 1.41337 3.28641C1.39671 3.10205 1.41862 2.91625 1.4777 2.74082C1.53679 2.5654 1.63175 2.4042 1.75655 2.26749C1.88134 2.13077 2.03324 2.02155 2.20256 1.94675C2.37189 1.87196 2.55493 1.83325 2.74004 1.83307H4.74004C5.06357 1.82989 5.37723 1.94446 5.62254 2.15543C5.86786 2.3664 6.02809 2.65937 6.07337 2.97974C6.15779 3.61978 6.31434 4.24822 6.54004 4.85307C6.62973 5.09169 6.64915 5.35102 6.59597 5.60033C6.5428 5.84964 6.41928 6.07848 6.24004 6.25974L5.39337 7.10641C6.34241 8.77544 7.72434 10.1574 9.39337 11.1064L10.24 10.2597C10.4213 10.0805 10.6501 9.95697 10.8994 9.9038C11.1488 9.85063 11.4081 9.87004 11.6467 9.95974C12.2516 10.1854 12.88 10.342 13.52 10.4264C13.8439 10.4721 14.1396 10.6352 14.3511 10.8847C14.5625 11.1343 14.6748 11.4528 14.6667 11.7797Z"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
