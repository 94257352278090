import React, { useState } from "react";
import {
  BackButton,
  LayoutBox,
  CancelButton,
  MainContainer,
} from "./style/ForgotPasswordStyles";
import { inputLabelStyle } from "./style/SharedStyle";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { useNavigate, useSearchParams } from "react-router-dom";

function SetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleContinue = () => {
    navigate("../login");
  };

  const handleSubmit = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        token: token,
        newPassword: values.password,
      };
      const res = await http.post("/auth/set-password", body);
      toastMessage("success", res?.data?.message);
      setIsSubmitted(true);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <MainContainer>
      <BackButton />
      {isSubmitted ? (
        <LayoutBox>
          <Typography fontWeight="medium" fontSize={38}>
            Password reset
          </Typography>
          <Typography mt={1} mb={7} variant="subtitle2">
            Your password has been successfully reset. Click below to log in.
          </Typography>
          <Button
            onClick={handleContinue}
            size="large"
            variant="contained"
            fullWidth
          >
            Continue
          </Button>
        </LayoutBox>
      ) : (
        <Formik
          initialValues={{
            password: "",
            passwordConfirm: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .required("Required")
              .matches(
                // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
              ),
            passwordConfirm: Yup.string()
              .required()
              .oneOf([Yup.ref("password")], "Your passwords do not match."),
          })}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <LayoutBox>
                <Typography fontWeight="medium" fontSize={38}>
                  Set new Password
                </Typography>
                <Typography mt={1} mb={5} variant="subtitle2">
                  Your new password must be different from previous used
                  passwords.
                </Typography>

                <Box>
                  <InputLabel sx={inputLabelStyle} htmlFor="password-box1">
                    Password
                  </InputLabel>
                  <TextField
                    {...formik.getFieldProps("password")}
                    error={
                      formik.touched.password && formik.errors.password
                        ? true
                        : false
                    }
                    helperText=" Minimum 8 characters with at least 1 number and a special character"
                    id="password-box1"
                    fullWidth
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box mt={4} mb={6}>
                  <InputLabel sx={inputLabelStyle} htmlFor="password-box2">
                    Password
                  </InputLabel>
                  <TextField
                    {...formik.getFieldProps("passwordConfirm")}
                    error={
                      formik.touched.passwordConfirm &&
                      formik.errors.passwordConfirm
                        ? true
                        : false
                    }
                    helperText="Both password must match."
                    id="password-box2"
                    fullWidth
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {submitLoader ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      type="submit"
                    >
                      Reset Password
                    </Button>
                    <CancelButton />
                  </>
                )}
              </LayoutBox>
            </form>
          )}
        </Formik>
      )}
    </MainContainer>
  );
}

export default SetPassword;
