import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { ModalBaseStyles } from "../Common/styles/modal";
import { CloseButton, DividerWithBG } from "../Common/UI/CommonModalDesign";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { updatedInputLabelStyle } from "./dashboard.style";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AddStudyModalProps, StudyDataType } from "./dashboard.type";
import { useState } from "react";

const yupSchema = Yup.object({
  name: Yup.string().required("Site name is required"),
  description: Yup.string().required("Description is Required"),
});

function AddStudyModal({ open, closeModal, refreshPage }: AddStudyModalProps) {
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const handleAddStudy = async (values: StudyDataType) => {
    try {
      setSubmitLoader(true);
      const { name, description } = values;
      const body: StudyDataType = {
        name: name,
        description: description,
        orgId: "80c9c35a-4e36-442f-acd3-6d4efb9691af",
      };
      const res: AxiosResponse = await http.post("/studies", body);
      toastMessage("success", res.data?.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, p: 4 }}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: "",
            description: "",
          }}
          validationSchema={yupSchema}
          onSubmit={(values: StudyDataType) => {
            handleAddStudy(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">Add Study</Typography>
                <CloseButton buttonClick={closeModal} />
              </Box>
              <DividerWithBG />
              <InputLabel sx={updatedInputLabelStyle} htmlFor="name">
                Study name
              </InputLabel>
              <TextField
                fullWidth
                error={
                  formik.touched?.name && formik.errors.name ? true : false
                }
                id="name"
                type="text"
                {...formik.getFieldProps("name")}
                helperText={
                  formik.touched?.name && formik.errors?.name
                    ? formik.errors?.name
                    : " "
                }
                placeholder="Enter"
                variant="outlined"
              />

              <InputLabel sx={updatedInputLabelStyle} htmlFor="description">
                description
              </InputLabel>
              <TextField
                fullWidth
                error={
                  formik.touched?.description && formik.errors.description
                    ? true
                    : false
                }
                id="description"
                type="text"
                {...formik.getFieldProps("description")}
                helperText={
                  formik.touched?.description && formik.errors?.description
                    ? formik.errors?.description
                    : " "
                }
                placeholder="Enter"
                variant="outlined"
              />
              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1.5,
                }}
              >
                {submitLoader ? (
                  <CircularProgress size={25} />
                ) : (
                  <>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Add Study
                    </Button>
                  </>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default AddStudyModal;
