import { Box, Radio, SxProps, TextField } from "@mui/material";
import { useState } from "react";

function indxToAlpha(indx: number) {
  const index = indx % 26;
  return String.fromCharCode(65 + index);
}

type CustomRadioProps = {
  index: number;
  choice: any;
  selectedValue: null | string | string[];
  clickHandler: Function;
  type: string;
};

const unselectedRadioStyle: SxProps = {
  minHeight: "50px",
  py: 1,
  px: 2,
  mb: 2,
  display: "flex",
  alignItems: "center",
  borderRadius: "6px",
  border: 1,
  borderColor: "border.type1",
  backgroundColor: "#ffffff",
  cursor: "pointer",
};

const selectedRadioStyle: SxProps = {
  ...unselectedRadioStyle,
  backgroundColor: "primary.light",
};

const indexStyle: SxProps = {
  mr: 1,
  width: "24px",
  height: "24px",
  border: 1,
  borderColor: "primary.main",
  backgroundColor: "primary.light",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  typography: "subtitle1",
  fontWeight: "medium",
  flexShrink: 0,
};

const CustomRadio: React.FC<CustomRadioProps> = ({
  index,
  choice,
  clickHandler,
  selectedValue,
  type,
}) => {
  return (
    <Box
      onClick={() => clickHandler(choice.id)}
      sx={
        type === "radio"
          ? selectedValue === choice.id
            ? selectedRadioStyle
            : unselectedRadioStyle
          : selectedValue
          ? selectedValue.includes(choice.id)
            ? selectedRadioStyle
            : unselectedRadioStyle
          : unselectedRadioStyle
      }
    >
      <Box sx={indexStyle}>{indxToAlpha(index)}</Box>
      {choice.isOther ? (
        <TextField
          placeholder="Others, Specify"
          size="small"
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        choice.label
      )}
    </Box>
  );
};

export const RadioType: React.FC<any> = ({ question }) => {
  const { fields } = question;
  const [val, setVal] = useState<string | null>(null);

  const clickHandler = (id: string) => {
    setVal((prev) => (prev === id ? null : id));
  };

  return (
    <Box>
      {fields.map((choice: any, index: number) => (
        <CustomRadio
          type="radio"
          choice={choice}
          key={choice.id}
          index={index}
          selectedValue={val}
          clickHandler={clickHandler}
        />
      ))}
    </Box>
  );
};

export const CheckboxType: React.FC<any> = ({ question }) => {
  const { fields } = question;
  const [val, setVal] = useState<string[] | null>(null);

  const clickHandler = (id: string) => {
    setVal((prev) => {
      if (!prev) {
        return [id];
      } else if (prev.includes(id)) {
        return prev.filter((selId) => selId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <Box>
      {fields.map((choice: any, index: number) => (
        <CustomRadio
          type="checkbox"
          choice={choice}
          key={choice.id}
          index={index}
          selectedValue={val}
          clickHandler={clickHandler}
        />
      ))}
    </Box>
  );
};

export const LikertType: React.FC<any> = ({ question, showChinese }) => {
  const { fields } = question;
  const [selected, setSelected] = useState(null);
  return (
    <div className="preview-likert">
      {fields.map((choice: any) => (
        <div
          key={choice.id}
          onClick={() => setSelected(choice.id)}
          className="likert-option"
          style={{ cursor: "pointer" }}
        >
          <Radio
            checked={choice.id === selected}
            sx={{
              color: "border.type3",
              "&.Mui-checked": {
                color: "primary.main",
              },
            }}
          />
          <div>{choice.label}</div>
        </div>
      ))}
    </div>
  );
};
