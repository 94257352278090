import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import { AuditTrailData } from "../../../utils/AuditTableData";
import {
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";

const AuditTrailTable = () => {
  const [data] = useState(AuditTrailData);
  // const [rowId, setRowId] = useState("");
  const [sortColumn, setSortColumn] = useState<undefined | string>();
  const [sortOrder, setSortOrder] = useState<undefined | string>();
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(0);

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (order: string, column: string) => {
    setSortOrder(order);
    setSortColumn(column);
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };
  return (
    <>
      <Box sx={{ pl: "21px", pr: "21px", pt: "20px" }}>
        <Table>
          <TableHead sx={{ overflow: "scroll" }}>
            <TableRow>
              <StyledTableCell>
                Date and Time
                <SortLabel column="dt" />
              </StyledTableCell>
              <StyledTableCell>
                Actor <SortLabel column="actor" />
              </StyledTableCell>
              <StyledTableCell>
                Type of User <SortLabel column="user" />
              </StyledTableCell>
              <StyledTableCell>
                Screening ID <SortLabel column="sid" />
              </StyledTableCell>
              <StyledTableCell>
                Action <SortLabel column="action" />
              </StyledTableCell>
              <StyledTableCell>
                Additional Info <SortLabel column="info" />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <StyledTableCell>{row.dt}</StyledTableCell>
                <StyledTableCell>{row.actor}</StyledTableCell>
                <StyledTableCell>{row.user}</StyledTableCell>
                <StyledTableCell>{row.sid}</StyledTableCell>
                <StyledTableCell>{row.action}</StyledTableCell>
                <StyledTableCell>{row.info}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={TablePaginationStyle}
                count={total}
                page={page}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[pageSize]}
                onPageChange={handleChangePage}
                labelDisplayedRows={paginationLabel}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </>
  );
};

export default AuditTrailTable;
