import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import QuestionBuilder from "./Components/Study/QuestionBuilder/QuestionBuilder";
import StudyLayout from "./Components/Study/StudyLayout";
import ConsentForm from "./Components/Study/ConsentForm/Consent";
import AuditTrail from "./Components/Study/AuditTrail/AuditTrail";
import SubjectProfile from "./Components/Study/Subjects/SubjectProfile/SubjectProfile";
import Subjects from "./Components/Study/Subjects/Subjects";
import Login from "./Components/auth/Login";
import SignUp from "./Components/auth/SignUp";
import ForgotPassword from "./Components/auth/ForgotPassword";
import SetPassword from "./Components/auth/SetPassword";
import Screening from "./Components/Study/Screening/Screening";
import UpdateStudy from "./Components/Study/UpdateStudy/UpdateStudy";
import ConsentBuilder from "./Components/Study/ConsentForm/ConsentBuilder/ConsentBuilder";
import UserRoles from "./Components/Study/UserRoles/userRole";
import QuestionPreview from "./Components/Study/QuestionPreview/QuestionPreview";
import ScoreList from "./Components/Study/Scores/Scores";
import Check from "./Components/auth/check";
import {
  AuthenticatedRoute,
  UnAuthenticatedRoute,
} from "./Components/Common/routes/Route";
import CreateStore from "./Components/Study/Scores/CreateScore";
import Scheduling from "./Components/Scheduling/Scheduling";
import ConsentSigning from "./Components/Study/ConsentSigning/ConsentSigning";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "auth",
        element: (
          <UnAuthenticatedRoute>
            <Outlet />
          </UnAuthenticatedRoute>
        ),
        children: [
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "signup",
            element: <SignUp />,
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "set-password",
            element: <SetPassword />,
          },
          {
            path: "check",
            element: <Check />,
          },
        ],
      },
      {
        path: "scheduling",
        element: <Scheduling />,
      },
      {
        path: "dashboard",
        element: (
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "study/:id",
        element: (
          <AuthenticatedRoute>
            <StudyLayout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="screening" replace />,
          },
          {
            path: "screening",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Screening />,
              },
              { path: "builder", element: <QuestionBuilder /> },
              { path: "preview", element: <QuestionPreview /> },
              { path: "score", element: <ScoreList /> },
              { path: "score/create", element: <CreateStore /> },
            ],
          },
          {
            path: "details",
            element: <UpdateStudy />,
          },
          {
            path: "users",
            element: <UserRoles />,
          },
          {
            path: "subjects",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Subjects />,
              },
              { path: ":subjectId", element: <SubjectProfile /> },
              { path: "consent-sign/:subjectId", element: <ConsentSigning /> },
            ],
          },
          {
            path: "consent",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ConsentForm />,
              },
              {
                path: ":consentId/consent-builder",
                element: <ConsentBuilder />,
              },
            ],
          },
          {
            path: "audit",
            element: <AuditTrail />,
          },
        ],
      },
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "*",
        element: <Navigate to="/dashboard" replace />,
      },
    ],
  },
]);

export default routes;
