import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Slot } from "../../type";
import { rowFlexStyle } from "../Common/styles/flex";
import {
  buttonStyle,
  confirmButtonGroup,
  confirmButtonStyle,
  confirmTimeButtonStyle,
  timeSelectorContainer,
  timingheaderText,
} from "./Scheduling.style";

type Props = {
  loading: boolean;
  selectedDate: DateTime | null;
  slotArray: Slot[];
  selectedSlotLabel: string;
  selectSlot: Function;
  handleConfirm: Function;
};

const SlotContainer: React.FC<Props> = ({
  selectedDate,
  slotArray,
  selectedSlotLabel,
  selectSlot,
  handleConfirm,
  loading,
}) => {
  return (
    <Box sx={timeSelectorContainer}>
      <Typography
        mb={1}
        sx={timingheaderText}
        variant="subtitle2"
        fontWeight="medium"
      >
        {selectedDate && selectedDate?.toFormat("EEEE, LLLL yyyy")}
      </Typography>
      {loading ? (
        <Box sx={{ ...rowFlexStyle, justifyContent: "center", mt: 4 }}>
          <CircularProgress size={25} />
        </Box>
      ) : (
        <Box>
          {slotArray.map((slot) => {
            return selectedSlotLabel === slot.label ? (
              <Box key={slot.label} sx={confirmButtonGroup}>
                <Button
                  sx={confirmTimeButtonStyle}
                  variant="contained"
                  fullWidth
                  onClick={() => selectSlot("")}
                >
                  {slot.label}
                </Button>
                <Button
                  sx={confirmButtonStyle}
                  variant="contained"
                  fullWidth
                  onClick={() => handleConfirm(slot.milliseconds)}
                >
                  Confirm
                </Button>
              </Box>
            ) : (
              <Button
                key={slot.label}
                sx={buttonStyle}
                variant="outlined"
                fullWidth
                disabled={!slot.enabled}
                onClick={() => selectSlot(slot.label)}
              >
                {slot.label}
              </Button>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SlotContainer;
