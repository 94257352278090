export const SuccessAptIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1501_15635)">
      <path
        d="M120.362 66.6333C121.681 65.1254 121.681 62.8746 120.362 61.3667L109.652 49.12L111.144 32.9311C111.328 30.9331 110.003 29.1079 108.047 28.6634L92.212 25.0667L83.9214 11.0296C82.8982 9.29727 80.7476 8.59448 78.8989 9.38832L63.9987 15.7867L49.094 9.3864C47.2472 8.59339 45.0988 9.29384 44.0743 11.0229L35.7854 25.0133L19.9611 28.5639C17.9998 29.004 16.6697 30.8313 16.8536 32.8329L18.3454 49.0667L7.62617 61.3674C6.31192 62.8755 6.31391 65.1227 7.63083 66.6285L18.3454 78.88L16.8528 95.123C16.6692 97.1207 17.9937 98.9454 19.95 99.3897L35.7854 102.987L44.0699 116.969C45.0962 118.702 47.2504 119.401 49.0986 118.602L63.9987 112.16L78.9034 118.56C80.7502 119.353 82.8986 118.653 83.923 116.924L92.212 102.933L108.047 99.3366C110.003 98.8921 111.328 97.0669 111.144 95.0689L109.652 78.88L120.362 66.6333ZM50.0254 85.3867L37.332 72.5867C35.252 70.5067 35.252 67.1467 37.332 65.0667L37.7054 64.6933C39.7854 62.6133 43.1987 62.6133 45.2787 64.6933L53.8654 73.3333L81.332 45.8133C83.412 43.7333 86.8254 43.7333 88.9054 45.8133L89.2787 46.1867C91.3587 48.2667 91.3587 51.6267 89.2787 53.7067L57.7054 85.3867C55.5187 87.4667 52.1587 87.4667 50.0254 85.3867Z"
        fill="#1C64F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1501_15635">
        <rect width="128" height="128" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
