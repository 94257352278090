import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface consentState {
  formText: string;
  checkbox: any[];
  signature: string;
  memberSignature: any[];
  title: string;
  formId: string;

  patientSign: any;
  adminSign: any;
  openTokCreds: any;

  loading: boolean;
  error: string;
  finalised: boolean;
  formSubmitting: boolean;
}

const initialState: consentState = {
  formText: "",
  checkbox: [],
  signature: "",
  memberSignature: [],
  title: "",
  formId: "",

  patientSign: null,
  adminSign: null,
  openTokCreds: null,

  loading: true,
  error: "",
  finalised: false,
  formSubmitting: false,
};

export const consentSignSlice = createSlice({
  name: "consent-sign",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    signCheckbox: (
      state,
      action: PayloadAction<{ key: string; checked: boolean }>
    ) => {
      state.checkbox = state.checkbox.map((item) => {
        if (item.id === action.payload.key) {
          return {
            ...item,
            isChecked: action.payload.checked,
          };
        } else {
          return item;
        }
      });
    },
    signAllCheckbox: (state) => {
      state.checkbox = state.checkbox.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
    },
    setOpentokData: (state, action: PayloadAction<any>) => {
      state.openTokCreds = action.payload;
    },
    setContentData: (state, action: PayloadAction<any>) => {
      const { formText, signature, memberSignature, checkbox, title, formId } =
        action.payload;
      state.title = title;
      state.formId = formId;
      state.checkbox = checkbox;
      state.formText = formText;
      state.memberSignature = memberSignature;
      state.signature = signature;
    },
    signingCleanup: (state) => {
      state.checkbox = [];
      state.formText = "";
      state.memberSignature = [];
      state.signature = "";
      state.title = "";
      state.formId = "";

      state.patientSign = null;
      state.adminSign = null;
      state.openTokCreds = null;

      state.loading = true;
      state.error = "";
      state.finalised = false;
      state.formSubmitting = false;
    },
    setAdminSign: (state, action: PayloadAction<any>) => {
      state.adminSign = action.payload;
    },
    setPatientSign: (state, action: PayloadAction<any>) => {
      state.patientSign = action.payload;
    },
    setFinalise: (state, action: PayloadAction<boolean>) => {
      state.finalised = action.payload;
    },
    setFormSubmitting: (state, action: PayloadAction<boolean>) => {
      console.log(action);
      state.formSubmitting = action.payload;
    },
  },
});

export const {
  setLoader,
  setError,
  signingCleanup,
  setContentData,

  signCheckbox,
  signAllCheckbox,
  setPatientSign,
  setAdminSign,
  setFinalise,
  setFormSubmitting,

  setOpentokData,
} = consentSignSlice.actions;

export default consentSignSlice.reducer;
