import { Box, Button } from "@mui/material";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignatureBox = () => {
  const sigCanvas = useRef<any>();

  const clear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  return (
    <>
      <SignatureCanvas
        ref={(ref) => {
          sigCanvas.current = ref;
        }}
        canvasProps={{ className: "canvas-element" }}
      />
      <Box mt={4} mb={5}>
        <Button variant="contained">Submit</Button>
        <Button sx={{ ml: "10px" }} variant="outlined" onClick={clear}>
          Clear
        </Button>
      </Box>
    </>
  );
};

export default SignatureBox;
