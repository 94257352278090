import { Box, Button, IconButton, Typography } from "@mui/material";
import { headerStyle } from "./QBPreview.style";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../Redux/hooks";

const QbPreviewHeader: React.FC<{
  name: string;
}> = ({ name }) => {
  const { qid, status } = useAppSelector((state) => state.question);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box sx={headerStyle}>
      <IconButton onClick={handleBack}>
        <ChevronLeftIcon
          fontSize="large"
          sx={{
            color: "text.tertiary",
          }}
        />
      </IconButton>
      <Typography variant="h2" color="text.secondary" sx={{ ml: 2.5 }}>
        {name || "Form Preview"}
      </Typography>
      <span style={{ marginLeft: "auto" }}></span>
      {status === "active" && (
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`../score?id=${qid}&name=${name}`);
          }}
        >
          View Score
        </Button>
      )}
    </Box>
  );
};

export default QbPreviewHeader;
