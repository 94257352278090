import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ContentCopy, Settings, Delete } from "@mui/icons-material";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  handleQuestionClone,
  handleQuestionDelete,
} from "../../../../Redux/reducers/questionSlice";
import { SxProps } from "@mui/material";
import { rowFlexStyle } from "../../../Common/styles/flex";
import { openQuestionSettings } from "../../../../Redux/actions/questionAction";

type Props = {
  index: number;
  parentIndex?: number;
  editable: boolean;
};

const QuestionSettingsStyle: SxProps = {
  ...rowFlexStyle,
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
};

const SettingsComp = ({ index, parentIndex, editable }: Props) => {
  const dispatch = useAppDispatch();

  const handleSettingsClick = () => {
    dispatch(openQuestionSettings(index, parentIndex));
  };

  const handleCopyClick = () => {
    dispatch(
      handleQuestionClone({
        currentQIndex: index,
        parentQIndex: parentIndex,
      })
    );
  };

  const handleDeleteClick = () => {
    dispatch(
      handleQuestionDelete({
        currentQIndex: index,
        parentQIndex: parentIndex,
      })
    );
  };

  return (
    <Box sx={QuestionSettingsStyle}>
      <IconButton
        aria-label="settings"
        size="medium"
        onClick={handleSettingsClick}
      >
        <Settings fontSize="medium" sx={{ color: "primary.main" }} />
      </IconButton>

      {editable && (
        <IconButton aria-label="copy" size="medium" onClick={handleCopyClick}>
          <ContentCopy fontSize="medium" sx={{ color: "primary.main" }} />
        </IconButton>
      )}

      {editable && (
        <IconButton
          aria-label="delete"
          size="medium"
          onClick={handleDeleteClick}
        >
          <Delete fontSize="medium" sx={{ color: "error.main" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default SettingsComp;
