import { Box, Typography } from "@mui/material";
import AuditTrailTable from "./AuditTrailTable";
import { useState } from "react";
import { AuditTable, HeaderContainer, StyledTextField } from "./audit.style";

const AuditTrail = () => {
  const [searchText, setsearchText] = useState("");

  return (
    <>
      <HeaderContainer>
        <Typography
          variant="subtitle2"
          fontWeight="medium"
          color="text.tertiary"
          mr={4}
        >
          Audit Trail
        </Typography>
        <StyledTextField
          placeholder="Search"
          value={searchText}
          onChange={(e) => setsearchText(e.target.value)}
        />
      </HeaderContainer>
      <Box sx={AuditTable}>
        <AuditTrailTable />
      </Box>
    </>
  );
};

export default AuditTrail;
