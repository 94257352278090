import {
  Divider,
  Box,
  Button,
  IconButton,
  InputLabel,
  Modal,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  debounce,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { updatedInputLabelStyle } from "../../../Dashboard/dashboard.style";
import { ModalBaseStyles } from "../../../Common/styles/modal";

const LinkQuestionModal = ({ open, handleModal, id, refreshPage }: any) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedSites, setSelectedSites] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    handleModal(false);
  }, [handleModal]);

  const handleSubmit = async () => {
    try {
      if (selectedSites.length === 0) {
        toastMessage("warning", "Choose atleast one consent form to add");
        return;
      }
      setSubmitLoader(true);
      const body = {
        consentFormId: selectedSites?.map((site) => site?.id),
      };
      const res: AxiosResponse = await http.post(
        `studies/${id}/consent-form/link`,
        body
      );
      toastMessage("success", res.data?.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
      }, 500),
    [setSearchText]
  );

  useEffect(() => {
    const fetchSites = async (searchText: string) => {
      try {
        setIsLoading(true);
        let url = `/studies/${id}/consent-form?page=1&size=12&q=${searchText}`;
        const res: AxiosResponse = await http.get(url);
        const newResults = res.data.data?.map((result: any) => {
          return {
            id: result?.id,
            name: result?.name,
          };
        });
        setSearchResults(newResults);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    if (searchText) {
      fetchSites(searchText);
    } else {
      setSearchResults([]);
    }
  }, [searchText, id, setIsLoading, setSearchResults]);

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, p: 4, minHeight: "20vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" fontWeight="medium" color="text.tertiary">
            Link to Study
          </Typography>
          <IconButton onClick={closeModal}>
            <CloseIcon sx={{ color: "text.tertiary" }} />
          </IconButton>
        </Box>
        <Divider
          sx={{
            borderColor: "border.type1",
            marginTop: "10px",
          }}
        />
        <InputLabel sx={updatedInputLabelStyle} htmlFor="name">
          Search For Consent form
        </InputLabel>
        <Autocomplete
          multiple
          options={searchResults}
          filterOptions={(x) => x}
          getOptionLabel={(option) => option.name}
          popupIcon={<SearchIcon />}
          sx={{
            "& .MuiAutocomplete-popupIndicatorOpen": {
              transform: "none",
            },
          }}
          loading={isLoading}
          loadingText={<CircularProgress size={20} />}
          noOptionsText="No Results"
          onInputChange={(_1: any, value: string) => modifySearchTerm(value)}
          onChange={(_1: any, value: any) => setSelectedSites(value)}
          clearOnBlur={false}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Search..." />
          )}
        />
        <Box
          sx={{
            mt: 7.5,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1.5,
          }}
        >
          {submitLoader ? (
            <CircularProgress size={25} />
          ) : (
            <>
              <Button variant="contained" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Link to Study
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default LinkQuestionModal;
