import { useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { NavLink, useNavigate } from "react-router-dom";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import {
  StudyAuditIcon,
  StudyConsentIcon,
  StudyDetailsIcon,
  StudyScreeningIcon,
  StudyUsersIcon,
} from "./Assets";
import { LargeLogo, SmallLogo } from "../Assets/Logo";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";

const openDrawerWidth = 240;
const closedDrawerWidth = 60;

const openedMixin = (theme: Theme): CSSObject => ({
  width: openDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.background.econsentBg,
  padding: theme.spacing(2.5, 1),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: closedDrawerWidth,
  backgroundColor: theme.palette.background.econsentBg,
  padding: theme.spacing(2.5, 1),
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: openDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StudySidebar: React.FC = () => {
  const [open, setOpen] = useState(
    localStorage.getItem("econsent-study-sidebar") === "open"
  );
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
    localStorage.setItem("econsent-study-sidebar", open ? "close" : "open");
  };

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  return (
    <Drawer variant="permanent" open={open} className="study-drawer">
      {open ? (
        <Tooltip title="Back to all studies">
          <Box onClick={handleLogoClick} sx={{ cursor: "pointer" }}>
            <LargeLogo />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title="Back to all studies">
          <Box
            onClick={handleLogoClick}
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <SmallLogo />
          </Box>
        </Tooltip>
      )}
      <Divider sx={{ my: 2.5 }} />
      <NavLink
        title="Screening"
        className={({ isActive }) => "item" + (isActive ? " active" : "")}
        to={{ pathname: "screening" }}
      >
        <StudyScreeningIcon />
        {open && <Typography variant="subtitle1">Screening</Typography>}
      </NavLink>
      <NavLink
        title="Study Details"
        className={({ isActive }) => "item" + (isActive ? " active" : "")}
        to={{ pathname: "details" }}
      >
        <StudyDetailsIcon />
        {open && <Typography variant="subtitle1">Study Details</Typography>}
      </NavLink>
      <NavLink
        title="User & Roles"
        className={({ isActive }) => "item" + (isActive ? " active" : "")}
        to={{ pathname: "users" }}
      >
        <StudyUsersIcon />
        {open && <Typography variant="subtitle1">User & Roles</Typography>}
      </NavLink>
      <NavLink
        title="Consent Forms"
        className={({ isActive }) => "item" + (isActive ? " active" : "")}
        to={{ pathname: "consent" }}
      >
        <StudyConsentIcon />
        {open && <Typography variant="subtitle1">Consent Forms</Typography>}
      </NavLink>
      <NavLink
        title="Subjects"
        className={({ isActive }) => "item" + (isActive ? " active" : "")}
        to={{ pathname: "subjects" }}
      >
        <StudyUsersIcon />
        {open && <Typography variant="subtitle1">Subjects</Typography>}
      </NavLink>
      <NavLink
        title="Audit Trail"
        className={({ isActive }) => "item" + (isActive ? " active" : "")}
        to={{ pathname: "audit" }}
      >
        <StudyAuditIcon />
        {open && <Typography variant="subtitle1">Audit Trail</Typography>}
      </NavLink>
      <IconButton onClick={toggleDrawer} disableRipple sx={{ mt: "auto" }}>
        {open ? (
          <ChevronLeftOutlined htmlColor="#ffffff" fontSize="medium" />
        ) : (
          <ChevronRightOutlined htmlColor="#ffffff" fontSize="medium" />
        )}
      </IconButton>
    </Drawer>
  );
};

export default StudySidebar;
