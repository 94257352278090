import { Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { addFormText } from "../../../../../Redux/reducers/consentSlice";
import {
  quillFormats,
  quillModules,
} from "../../../../../utils/reactQuillOptions";

const FormText = () => {
  const dispatch = useAppDispatch();
  const { formText } = useAppSelector((state) => state.consent);

  const handleFormText = (value: string) => {
    dispatch(addFormText(value));
  };
  return (
    <>
      <Typography variant="h3" fontWeight="medium" color="text.tertiary" mb={2}>
        Form Text
      </Typography>
      <ReactQuill
        modules={quillModules}
        formats={quillFormats}
        onChange={handleFormText}
        defaultValue={formText}
        className="quill-container quill-container-lg"
        style={{
          maxWidth: "782px",
        }}
      />
    </>
  );
};

export default FormText;
