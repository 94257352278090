import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import http from "../../../../utils/http";
import { langArray } from "../../../../utils/lang";
import { toastMessage, errorToastMessage } from "../../../../utils/toast";
import { buttonLoaderSx } from "../../../Common/styles/modal";
import { consentFormListType } from "../Consent";
import { InputLabelStyle, InputStyle } from "../consentForm.style";

const yupSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  country: Yup.string().required("Country is required"),
  language: Yup.string().required("Language is required"),
  versionNumber: Yup.string().optional(),
  versionDate: Yup.string().optional(),
  approvalDate: Yup.string().optional(),
});

interface SettingsDataType extends consentFormListType {
  approvalDate?: string;
}

const SettingsTab = () => {
  const { consentId, id } = useParams();
  const [settingsData, setSettingsData] = useState<SettingsDataType>({
    name: "",
    country: "",
    language: "",
    versionNumber: "",
    versionDate: "",
    approvalDate: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleCFSettings = async (values: SettingsDataType) => {
    try {
      setSubmitLoader(true);
      const {
        name,
        country,
        language,
        versionNumber,
        versionDate,
        approvalDate,
      } = values;
      const body: SettingsDataType = {
        name: name,
        language: language,
        country: country,
      };
      const res = await http.patch(
        `/studies/${id}/consent-form/${consentId}`,
        body
      );
      toastMessage("success", res.data?.message);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const handleFetchCFData = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(
          `/studies/${id}/consent-form/${consentId}`
        );
        const processedData = {
          country: res.data?.data?.country,
          language: res.data?.data?.language,
          name: res.data?.data?.name,
        };
        setSettingsData(processedData);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    handleFetchCFData();
  }, [consentId, id, setSettingsData, setSubmitLoader, setIsLoading]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: settingsData.name,
        country: settingsData.country,
        language: settingsData.language,
        versionNumber: "",
        versionDate: "",
        approvalDate: "",
      }}
      validationSchema={yupSchema}
      onSubmit={(values) => {
        handleCFSettings(values);
      }}
    >
      {(formik) => (
        <>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Form
              style={{
                maxWidth: "620px",
              }}
            >
              <InputLabel sx={InputLabelStyle} htmlFor="formName">
                Form name
              </InputLabel>
              <TextField
                sx={InputStyle}
                fullWidth
                error={
                  formik.touched?.name && formik.errors.name ? true : false
                }
                id="formName"
                type="text"
                {...formik.getFieldProps("name")}
                helperText={
                  formik.touched?.name && formik.errors?.name
                    ? formik.errors?.name
                    : null
                }
                placeholder="Enter"
                variant="outlined"
              />

              <InputLabel sx={InputLabelStyle} htmlFor="country">
                country
              </InputLabel>
              <TextField
                sx={InputStyle}
                fullWidth
                error={
                  formik.touched?.country && formik.errors?.country
                    ? true
                    : false
                }
                id="country"
                type="text"
                {...formik.getFieldProps("country")}
                helperText={
                  formik.touched?.country && formik.errors?.country
                    ? formik.errors?.country
                    : null
                }
                select
                placeholder="Please Enter"
                variant="outlined"
              >
                {["India", "USA", "United Kingdom", "Australia", "Canada"].map(
                  (option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  )
                )}
              </TextField>

              <InputLabel sx={InputLabelStyle} htmlFor="language">
                Language
              </InputLabel>
              <TextField
                sx={InputStyle}
                fullWidth
                error={
                  formik.touched?.language && formik.errors.language
                    ? true
                    : false
                }
                id="language"
                type="text"
                {...formik.getFieldProps("language")}
                helperText={
                  formik.touched?.language && formik.errors?.language
                    ? formik.errors?.language
                    : null
                }
                select
                placeholder="Please Enter"
                variant="outlined"
              >
                {langArray.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <Box sx={{ display: "flex", gap: "30px" }}>
                <Box flex={1}>
                  <InputLabel sx={InputLabelStyle} htmlFor="versionNumber">
                    version number
                  </InputLabel>
                  <TextField
                    fullWidth
                    sx={InputStyle}
                    error={
                      formik.touched?.versionNumber &&
                      formik.errors.versionNumber
                        ? true
                        : false
                    }
                    id="versionNumber"
                    type="text"
                    {...formik.getFieldProps("versionNumber")}
                    helperText={
                      formik.touched?.versionNumber &&
                      formik.errors?.versionNumber
                        ? formik.errors?.versionNumber
                        : null
                    }
                    placeholder="Enter"
                    variant="outlined"
                  />
                </Box>

                <Box flex={1}>
                  <InputLabel sx={InputLabelStyle} htmlFor="versionDate">
                    version Date
                  </InputLabel>

                  <DatePicker
                    onChange={(momentVal) => {
                      if (momentVal?.isValid()) {
                        formik.setFieldValue(
                          "versionDate",
                          momentVal.toISOString()
                        );
                      }
                    }}
                    value={
                      formik.values.versionDate
                        ? moment(formik.values.versionDate)
                        : null
                    }
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={InputStyle}
                        error={
                          formik.touched?.versionDate &&
                          formik.errors?.versionDate
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched?.versionDate &&
                          formik.errors?.versionDate
                            ? formik.errors?.versionDate
                            : null
                        }
                        id="versionDate"
                        placeholder="Enter"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </Box>

              <InputLabel sx={InputLabelStyle} htmlFor="language">
                approval date IRB-EC
              </InputLabel>
              <DatePicker
                onChange={(momentVal) => {
                  if (momentVal?.isValid()) {
                    formik.setFieldValue(
                      "approvalDate",
                      momentVal.toISOString()
                    );
                  }
                }}
                value={
                  formik.values.approvalDate
                    ? moment(formik.values.approvalDate)
                    : null
                }
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      error={
                        formik.touched?.approvalDate &&
                        formik.errors.approvalDate
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched?.approvalDate &&
                        formik.errors?.approvalDate
                          ? formik.errors?.approvalDate
                          : null
                      }
                      placeholder="Enter"
                      variant="outlined"
                      id="approvalDate"
                      sx={{ height: "48px", mb: 3.75, minWidth: "295px" }}
                    />
                  );
                }}
              />
              <Box mt={3.75}>
                {submitLoader ? (
                  <Box sx={buttonLoaderSx}>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        mr: 1.25,
                      }}
                    >
                      Save Changes
                    </Button>
                    <Button onClick={handleBack} variant="outlined">
                      Cancel
                    </Button>
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </>
      )}
    </Formik>
  );
};

export default SettingsTab;
