import { SxProps } from "@mui/material";

export const inputLabelStyle = {
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "18px",
  opacity: 0.4,
};

export const iconStyle = {
  fontSize: "14px",
  marginRight: "5px",
  marginBottom: "-2px",
};

export const afterInputTextStyle = {
  fontWeight: 400,
  fontSize: "14px",
  opacity: 0.8,
};

export const grayBox: SxProps = {
  backgroundColor: "gray",
  height: "100vh",
  width: "59vw",
  backgroundImage: "url('/landing.png')",
  backgroundSize: "cover",
  backgroundPosition: "top",
};

export const inputContainer = {
  height: "100vh",
  flex: 1,
  minWidth: "1px",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

export const link = {
  color: "#1C64F2",
  textTransform: "none",
  fontSize: "12px",
};
