import { ErrorOutline, Circle } from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  ListItemContainerStyle,
  ListItemStatusContainer,
} from "./dashboard.style";

type Props = {
  data: {
    id: string;
    name: string;
    status: string;
  };
  acceptHandler: Function;
  rejectHandler: Function;
  loadingId: string;
};

const InvitationItem: React.FC<Props> = ({
  data,
  rejectHandler,
  acceptHandler,
  loadingId,
}) => (
  <Paper sx={ListItemContainerStyle}>
    <Box sx={ListItemStatusContainer}>
      <Circle
        sx={{
          color: data.status === "Live" ? "#33BC28" : "primary.main",
          fontSize: "13px",
        }}
      />
      <Typography
        ml={1.5}
        variant="subtitle1"
        color={data.status === "Live" ? "#33BC28" : "text.tertiary"}
      >
        {data.status}
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography mr={1} variant="subtitle1" fontWeight="medium">
        {data.name}
      </Typography>
      <ErrorOutline
        style={{ color: "#A1A1AA", fontSize: "16px", cursor: "pointer" }}
      />
    </Box>
    <Box sx={{ marginLeft: "auto" }}>
      {loadingId === data.id ? (
        <CircularProgress sx={{ mr: 2 }} />
      ) : (
        <>
          <Button
            variant="contained"
            onClick={() => acceptHandler(data.id)}
            size="large"
          >
            Accept Invitation
          </Button>
          <Button
            sx={{ marginLeft: 2.5 }}
            variant="outlined"
            color="error"
            onClick={() => rejectHandler(data.id)}
            size="large"
          >
            Reject Invitation
          </Button>
        </>
      )}
    </Box>
  </Paper>
);

export default InvitationItem;
