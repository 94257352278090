import { Box, styled, SxProps, TextField } from "@mui/material";

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",
  padding: "0 20px",
  height: "91px",
  width: "100%",
  borderBottom: "1px solid",
  borderColor: theme.palette.border?.type1,
  backgroundColor: "#ffffff",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F9FAFB",
    borderRadius: 16,
    width: "562px",
    height: "50px",
    "& fieldset": {
      border: "1px solid",
      borderColor: theme.palette.border?.type1,
    },
  },
}));

export const SubjectsContainer: SxProps = {
  padding: 2.5,
  backgroundColor: "background.lightBg",
  height: "calc(100% - 155px)",
  overflow: "auto",
};

export const SubjectsCardContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit,207px)",
  gap: 2.5,
  mb: 5,
};

export const CustomCard = styled(Box)(() => ({
  display: "flex",
  minWidth: "max-content",
  flexDirection: "column",
  height: "112px",
  borderRadius: "16px",
  padding: "20px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06),0px 1px 3px rgba(0, 0, 0, 0.1)",
  border: "2px solid transparent",
}));

export const HeaderBarStyle: SxProps = {
  width: "100%",
  height: "75px",
  padding: 2.5,
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  backgroundColor: "#ffffff",
};

export const CardLinkText: SxProps = {
  textDecoration: "underline",
  textUnderlineOffset: "2px",
  textTransform: "uppercase",
};

export const CardLg = styled("div")(() => ({
  background: "#ffffff",
  minHeight: "260px",
  borderRadius: "8px",
  padding: "12px 20px 20px 20px",
}));

export const CardSm = styled("div")(() => ({
  background: "#ffffff",
  minHeight: "260px",
  borderRadius: "8px",
  padding: "12px 20px 20px 20px",
}));

export const Col4Container: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit,minmax(210px,1fr))",
  rowGap: "30px",
  color: "text.tertiary",
};

export const Col3Container: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit,minmax(270px,1fr))",
  rowGap: "30px",
  color: "text.tertiary",
};

export const CardHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  minHeight: "51px",
  borderBottom: "1px solid",
  borderColor: theme.palette.border?.type1,
  marginBottom: "20px",
}));

export const CardItem = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

export const ActionsBarContainer: SxProps = {
  display: "flex",
  gap: "20px",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingBottom: 2.5,
};

export const ProfileTabContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: { sm: "1fr", lg: "2fr 1fr" },
  gap: "20px",
};

export const IconTextContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
};
