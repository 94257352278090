import { SxProps } from "@mui/material";
import { colFlexStyle, rowFlexStyleAlignCenter } from "../Common/styles/flex";
import { inputLabelStyle } from "../Common/UI/CommonModalDesign";

export const dashboardHeaderStyle: SxProps = {
  backgroundColor: "background.econsentBg",
  height: "64px",
  px: 2,
  ...rowFlexStyleAlignCenter,
};

export const dashboardTitleContainerStyle: SxProps = {
  ...rowFlexStyleAlignCenter,
  height: "68px",
  borderBottom: "1px solid",
  borderBottomColor: "border.type1",
  px: 5,
};

export const dashboardTabHeaderContainer: SxProps = {
  borderBottom: 1,
  borderColor: "border.type1",
};

export const dashboardTabContainer: SxProps = {
  height: "calc(100% - 193px)",
  overflow: "auto",
};

export const dashboardCardContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit,214px)",
  gap: 2,
  mt: 2.5,
  mb: 5,
};

export const ListItemContainerStyle: SxProps = {
  height: "84px",
  borderRadius: "8px",
  mb: 2,
  px: 3.75,
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
  ...rowFlexStyleAlignCenter,
};

export const ListItemStatusContainer: SxProps = {
  width: "25%",
  ...rowFlexStyleAlignCenter,
};

export const noItemsStyles: SxProps = {
  ...rowFlexStyleAlignCenter,
  justifyContent: "center",
  width: "100%",
  mb: 2,
};

export const CardStyle: SxProps = {
  ...colFlexStyle,
  justifyContent: "space-between",
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  border: "1px solid rgba(33, 33, 33, 0.13)",
  height: "144px",
  p: 2,
};

export const updatedInputLabelStyle = {
  ...inputLabelStyle,
  mt: "10px",
};

export const ChartCard: SxProps = {
  border: " 1px solid rgba(33, 33, 33, 0.13)",
  borderRadius: 2,
  maxWidth: "50%",
  p: 2,
};

export const ChartContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const DoughnutContainer: SxProps = {
  flex: 1,
  height: "220px",
  maxWidth: "50%",
  minWidth: 0,
  position: "relative",
};

export const ChartCenterText: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
};
