import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  setFetchingError,
  setLoader,
  setSubmitLoader,
  saveAll,
} from "../reducers/consentSlice";
import { AppThunk } from "../store";
import { consentSaveFormat, consentViewFormat } from "../../utils/consent";

export const loadCFContentDetails =
  (id?: string, consentId?: string): AppThunk =>
  async (dispatch) => {
    try {
      if (!id || !consentId) {
        dispatch(setFetchingError("Something went wrong"));
        return;
      }
      dispatch(setLoader(true));
      let { data } = await http.get(
        `/studies/${id}/consent-form/${consentId}/settings`
      );
      const { formText, signature, memberSignature, checkbox } =
        consentViewFormat(data?.data?.config);
      dispatch(saveAll({ formText, signature, memberSignature, checkbox }));
      dispatch(setLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setLoader(false));
      dispatch(setFetchingError("Something went wrong"));
    }
  };

export const submitCFContent =
  (id?: string, consentId?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { formText, checkbox, signature, memberSignature } =
        getState().consent;
      dispatch(setSubmitLoader(true));
      const formattedData = consentSaveFormat(
        formText,
        checkbox,
        signature,
        memberSignature
      );

      const { data } = await http.post(
        `/studies/${id}/consent-form/${consentId}/settings`,
        { config: formattedData }
      );
      toastMessage("success", data.message);
      dispatch(setSubmitLoader(false));
      dispatch(loadCFContentDetails(id, consentId));
    } catch (err) {
      dispatch(setSubmitLoader(false));
      errorToastMessage(err as Error);
    }
  };
