import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  LayoutBox,
  BackButton,
  CancelButton,
  MainContainer,
} from "./style/ForgotPasswordStyles";
import { inputLabelStyle } from "./style/SharedStyle";
import { Formik } from "formik";
import * as Yup from "yup";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import http from "../../utils/http";

const schema = Yup.object({
  email: Yup.string().email("Not a valid Email").required("Email is required"),
});

function ForgotPassword() {
  const [email, setEmail] = useState<string>("");
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const handleResend = () => {
    submitForm(email);
  };

  const submitForm = async (emailStr: string) => {
    try {
      setSubmitLoader(true);
      const body = {
        email: emailStr,
      };
      const res = await http.post("/auth/reset-password", body);
      toastMessage("success", res?.data?.message);
      setEmail(emailStr);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <MainContainer>
      <BackButton />

      {email ? (
        <LayoutBox>
          <Typography fontWeight="medium" fontSize={38}>
            Check your email
          </Typography>
          <Box mt={1} mb={5}>
            <Typography variant="subtitle2" component={"span"}>
              We sent a password reset link to{" "}
            </Typography>
            <Typography
              fontWeight="medium"
              variant="subtitle2"
              component={"span"}
            >
              {email}
            </Typography>
          </Box>
          {submitLoader ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={25} />
            </Box>
          ) : (
            <Typography variant="subtitle2">
              Didn’t receive the email?{" "}
              <Button
                variant="text"
                sx={{ color: "text.link", textTransform: "none" }}
                onClick={handleResend}
              >
                {" "}
                Click to resend
              </Button>
            </Typography>
          )}
        </LayoutBox>
      ) : (
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitForm(values.email);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <LayoutBox>
                <Typography fontWeight="medium" fontSize={38}>
                  Forgot your Password
                </Typography>
                <Typography mt={1} mb={4} variant="subtitle2">
                  Enter your email address, and we’ll send you an email with all
                  the instructions.
                </Typography>
                <Box mb={7} className="email-input">
                  <InputLabel sx={inputLabelStyle} htmlFor="email">
                    Email
                  </InputLabel>
                  <TextField
                    error={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                    {...formik.getFieldProps("email")}
                    variant="standard"
                    fullWidth
                    id="email"
                    type="email"
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                  />
                </Box>
                {submitLoader ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth
                      type="submit"
                    >
                      Send me instructions
                    </Button>
                    <CancelButton />
                  </>
                )}
              </LayoutBox>
            </form>
          )}
        </Formik>
      )}
    </MainContainer>
  );
}

export default ForgotPassword;
