import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import {
  CardHeader,
  CardLg,
  FormLabelStyle,
  IOSSwitch,
  SwitchContainer,
} from "../updateStudy.style";
import { TabProps } from "../updateStudy.type";

const ConsentOptions = ({ editable, id }: TabProps) => {
  const [options, setOptions] = useState({
    videoESign: false,
    inPersonESign: false,
    lockICF: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);

  const handleChange = (e: any, name: string) => {
    if (editable) {
      setOptions((prev) => {
        return {
          ...prev,
          [name]: e.target.checked,
        };
      });
    }
  };

  const handleUpdate = async () => {
    try {
      setSubmitLoader(true);
      const body = {
        isVEnabledForIcfSigning: options?.videoESign,
        isInpersonEsignEnabled: options?.inPersonESign,
        isLockIcfByDefaultEnabled: options?.lockICF,
      };
      const res: AxiosResponse = await http.patch(
        `/studies/${id}/config/consent-options`,
        body
      );
      toastMessage("success", res.data?.message);
      setToggleLoader((prev) => !prev);
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    const fetchConsentOptions = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(
          `/studies/${id}/config/consent-options`
        );
        const {
          isInpersonEsignEnabled,
          isLockIcfByDefaultEnabled,
          isVEnabledForIcfSigning,
        } = res.data?.data;
        const newOptions = {
          videoESign: isVEnabledForIcfSigning,
          inPersonESign: isInpersonEsignEnabled,
          lockICF: isLockIcfByDefaultEnabled,
        };
        setOptions(newOptions);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };

    fetchConsentOptions();
  }, [id, toggleLoader]);

  return (
    <CardLg>
      <CardHeader>
        <Typography
          variant="subtitle2"
          fontWeight="medium"
          color="text.tertiary"
        >
          Consent Options
        </Typography>
      </CardHeader>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" fontWeight="regular" mb={3.75}>
            You can change your settings for your consent options below.
          </Typography>
          <FormControl>
            <FormGroup sx={SwitchContainer}>
              <FormControlLabel
                control={<IOSSwitch />}
                checked={options.videoESign}
                onChange={(e) => handleChange(e, "videoESign")}
                label="Video calling for signing"
                sx={FormLabelStyle}
              />
              <FormControlLabel
                control={<IOSSwitch />}
                checked={options.inPersonESign}
                onChange={(e) => handleChange(e, "inPersonESign")}
                label="In-person electronic signature"
                sx={FormLabelStyle}
              />
              <FormControlLabel
                control={<IOSSwitch />}
                checked={options.lockICF}
                onChange={(e) => handleChange(e, "lockICF")}
                label="Lock ICF by default"
                sx={FormLabelStyle}
              />
            </FormGroup>
            {editable && (
              <Box mt={5}>
                {submitLoader ? (
                  <CircularProgress size={25} />
                ) : (
                  <Button variant="contained" onClick={handleUpdate}>
                    Save Changes
                  </Button>
                )}
              </Box>
            )}
          </FormControl>
        </>
      )}
    </CardLg>
  );
};

export default ConsentOptions;
