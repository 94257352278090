import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  InputLabelStyle,
  LoadingContainer,
  PhoneInputStyle,
  StyledTextArea,
  StyledTextField,
} from "./updateStudy.style";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { AxiosResponse } from "axios";
import { TabProps } from "./updateStudy.type";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { uploadFile } from "../../../utils/upload";
import { Image } from "@mui/icons-material";
import { rowFlexStyleAlignCenter } from "../../Common/styles/flex";

const yupSchema = Yup.object({
  studyName: Yup.string().required("study name is required"),
  studyDesc: Yup.string().required("study description is required"),
  firstName: Yup.string().required("first name is required"),
  lastName: Yup.string().required("last name is required"),
  email: Yup.string().required("email is required"),
  phone: Yup.string().required("phone is required").min(10),
});

const GeneralTab = ({ editable, id }: TabProps) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [generalDetails, setGeneralDetails] = useState({
    studyName: "",
    studyDesc: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    logoUrl: "",
  });

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleUpdateStudy = async (values: any) => {
    try {
      setSubmitLoader(true);
      const {
        studyName,
        studyDesc,
        firstName,
        lastName,
        email,
        phone,
        logoUrl,
      } = values;
      const body = {
        name: studyName,
        description: studyDesc,
        logoUrl: logoUrl,
        contact: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phone,
        },
      };
      const res: AxiosResponse = await http.patch(`/studies/${id}`, body);
      toastMessage("success", res.data?.message);
      refreshPage();
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    const fetchGeneralDetails = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(`studies/${id}/general`);
        const data = res.data?.data;
        const newDetails = {
          studyName: data?.name,
          studyDesc: data?.description,
          firstName: data?.contact?.firstName,
          lastName: data?.contact?.lastName,
          email: data?.contact?.email,
          phone: data?.contact?.phoneNumber,
          logoUrl: data?.logoUrl,
        };
        setGeneralDetails(newDetails);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };

    fetchGeneralDetails();
  }, [id, setGeneralDetails, toggleLoader]);

  const handleFileUpload = async (setFieldValue: Function, event: any) => {
    try {
      if (event?.currentTarget?.files?.[0]) {
        setImageLoading(true);
        const fileUrl = await uploadFile(
          event?.currentTarget?.files?.[0],
          "study_image"
        );
        setFieldValue("logoUrl", fileUrl);
        setImageLoading(false);
      }
    } catch (err) {
      setImageLoading(false);
      errorToastMessage(err as Error);
    }
  };

  return isLoading ? (
    <LoadingContainer>
      <CircularProgress size={50} />
    </LoadingContainer>
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{
        studyName: generalDetails?.studyName,
        studyDesc: generalDetails?.studyDesc,
        firstName: generalDetails?.firstName,
        lastName: generalDetails?.lastName,
        email: generalDetails?.email,
        phone: generalDetails?.phone,
        logoUrl: generalDetails?.logoUrl,
      }}
      validationSchema={yupSchema}
      onSubmit={(values) => {
        handleUpdateStudy(values);
      }}
    >
      {(formik) => (
        <Form style={{ maxWidth: "782px" }}>
          <Typography variant="h3" fontWeight="medium" mb={2.5}>
            General Settings
          </Typography>
          <InputLabel sx={InputLabelStyle} htmlFor="studyName">
            Name of study
          </InputLabel>
          <StyledTextField
            fullWidth
            error={
              formik.touched?.studyName && formik.errors.studyName
                ? true
                : false
            }
            id="studyName"
            name="studyName"
            value={formik.values.studyName}
            onChange={(e) => {
              if (editable) formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched?.studyName && formik.errors?.studyName
                ? formik.errors?.studyName
                : null
            }
            placeholder="Enter"
            sx={{ mb: 3 }}
          />
          <Box mb={2} sx={{ display: "flex", alignItems: "flex-end" }}>
            <Box sx={{ mr: 2 }}>
              {formik.values.logoUrl ? (
                <img
                  src={formik.values.logoUrl}
                  alt="question"
                  style={{ height: "100px", width: "100px" }}
                />
              ) : (
                <Box
                  sx={{
                    height: "100px",
                    width: "100px",
                    background: "#E5E7EB",
                    ...rowFlexStyleAlignCenter,
                    justifyContent: "center",
                  }}
                >
                  <Image />
                </Box>
              )}
            </Box>
            <input
              accept="image/*"
              id="question-image"
              hidden
              type="file"
              onChange={(e) => {
                handleFileUpload(formik.setFieldValue, e);
              }}
            />
            {imageLoading ? (
              <Box>
                <CircularProgress size={25} />
              </Box>
            ) : (
              <label htmlFor="question-image">
                <Button variant="outlined" component="span">
                  Upload
                </Button>
              </label>
            )}
          </Box>
          <FormHelperText sx={{ color: "text.grey", mt: 2, mb: 2.5 }}>
            (Upload a .png or .jpeg files that has a min height 30 pixels, and a
            min width 40 pixels)
          </FormHelperText>
          <InputLabel htmlFor="studyDesc" sx={InputLabelStyle}>
            Study Description
          </InputLabel>
          <StyledTextArea
            id="studyDesc"
            fullWidth
            multiline
            rows={4}
            placeholder="Type here..."
            name="studyDesc"
            value={formik.values.studyDesc}
            onChange={(e) => {
              if (editable) formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched?.studyDesc && formik.errors.studyDesc
                ? true
                : false
            }
            helperText={
              formik.touched?.studyDesc && formik.errors?.studyDesc
                ? formik.errors?.studyDesc
                : " "
            }
            sx={{ mb: 2.5 }}
          />
          <Typography variant="h3" fontWeight="medium" mb={2.5}>
            Contact Settings
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="regular"
            color="text.tertiary"
            mb={1.25}
          >
            Main Contact
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="regular"
            color="text.grey"
            mb={2.5}
          >
            Define a main contact for your study team by selecting from your
            list of study admins in the dropdown below.
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box flex={1}>
              <InputLabel sx={InputLabelStyle} htmlFor="firstName">
                First Name
              </InputLabel>
              <StyledTextField
                id="firstName"
                fullWidth
                placeholder="Enter"
                name="firstName"
                value={formik.values.firstName}
                onChange={(e) => {
                  if (editable) formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched?.firstName && formik.errors.firstName
                    ? true
                    : false
                }
                helperText={
                  formik.touched?.firstName && formik.errors?.firstName
                    ? formik.errors?.firstName
                    : " "
                }
              />
            </Box>
            <Box flex={1}>
              <InputLabel sx={InputLabelStyle} htmlFor="lastName">
                Last Name
              </InputLabel>
              <StyledTextField
                id="lastName"
                fullWidth
                placeholder="Enter"
                name="lastName"
                value={formik.values.lastName}
                onChange={(e) => {
                  if (editable) formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched?.lastName && formik.errors.lastName
                    ? true
                    : false
                }
                helperText={
                  formik.touched?.lastName && formik.errors?.lastName
                    ? formik.errors?.lastName
                    : " "
                }
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box flex={1}>
              <InputLabel sx={InputLabelStyle} htmlFor="email">
                Email
              </InputLabel>
              <StyledTextField
                fullWidth
                id="email"
                placeholder="Enter"
                name="email"
                value={formik.values.email}
                onChange={(e) => {
                  if (editable) formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched?.email && formik.errors.email ? true : false
                }
                helperText={
                  formik.touched?.email && formik.errors?.email
                    ? formik.errors?.email
                    : " "
                }
              />
            </Box>
            <Box flex={1}>
              <InputLabel sx={InputLabelStyle} htmlFor="phone">
                Phone Number
              </InputLabel>
              <PhoneInput
                country="us"
                inputProps={{
                  name: "phone",
                }}
                value={formik.values.phone}
                onChange={(phone) => formik.setFieldValue("phone", phone)}
                disabled={!editable}
                onBlur={formik.handleBlur}
                inputStyle={{
                  ...PhoneInputStyle,
                  borderColor:
                    formik.touched.phone && formik.errors.phone
                      ? "#d32f2f"
                      : "#DCDCE0",
                }}
                buttonStyle={{
                  borderColor:
                    formik.touched.phone && formik.errors.phone
                      ? "#d32f2f"
                      : "#DCDCE0",
                  borderTopLeftRadius: 12,
                  borderBottomLeftRadius: 12,
                }}
                containerClass="phone-number-input"
              />
              <FormHelperText error={true} sx={{ marginX: 1.75 }}>
                {formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : " "}
              </FormHelperText>
            </Box>
          </Box>
          {editable && (
            <Box mt={4}>
              {submitLoader ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  <Button variant="contained" type="submit">
                    Save Changes
                  </Button>
                  <Button variant="outlined" sx={{ marginLeft: "20px" }}>
                    Cancel
                  </Button>
                </>
              )}
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default GeneralTab;
