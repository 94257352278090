import { ErrorOutline, Circle } from "@mui/icons-material";
import { Box, Typography, Paper } from "@mui/material";
import React from "react";
import {
  ListItemContainerStyle,
  ListItemStatusContainer,
} from "./dashboard.style";
import { StudyItemProps } from "./dashboard.type";

const StudyItem: React.FC<StudyItemProps> = ({ data, clickHandler }) => (
  <Paper
    sx={{ ...ListItemContainerStyle, cursor: "pointer" }}
    elevation={1}
    onClick={() => clickHandler(data?.id)}
  >
    <Box sx={ListItemStatusContainer}>
      <Circle
        sx={{
          color: data?.status === "Live" ? "#33BC28" : "primary.main",
          fontSize: "13px",
        }}
      />
      <Typography
        ml={1.5}
        variant="subtitle1"
        color={data?.status === "Live" ? "#33BC28" : "text.tertiary"}
      >
        {data?.status}
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography mr={1} variant="subtitle1" fontWeight="medium">
        {data?.name}
      </Typography>
      <ErrorOutline
        style={{ color: "#A1A1AA", fontSize: "16px", cursor: "pointer" }}
      />
    </Box>
    <Box sx={{ marginLeft: "auto", display: "flex" }}>
      <Typography variant="subtitle1" fontWeight="medium">
        Main contact:
      </Typography>
      <Typography variant="subtitle1">&nbsp; {data?.description}</Typography>
    </Box>
  </Paper>
);

export default StudyItem;
