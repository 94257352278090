import { createTheme } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
declare module "@mui/material/styles" {
  interface PaletteOptions {
    border?: {
      type1: string;
      type2: string;
      type3: string;
    };
    badge?: {
      greenText: string;
      greenBg: string;
      redText: string;
      redBg: string;
      orangeText: string;
      orangeBg: string;
    };
  }

  interface Palette {
    border?: {
      type1: string;
      type2: string;
      type3: string;
    };
    badge?: {
      greenText: string;
      greenBg: string;
      redText: string;
      redBg: string;
      orangeText: string;
      orangeBg: string;
    };
  }
  interface TypeBackground {
    econsentBg?: string;
    lightBg?: string;
    dividerBg?: string;
    tableHeaderBg?: string;
  }

  interface TypeText {
    grey?: string;
    link?: string;
    tertiary?: string;
    lightText?: string;
  }
}

export const theme = createTheme({
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "150%",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "150%",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "150%",
    },
    h1: { fontWeight: 700, fontSize: 33, lineHeight: "150%" },
    h2: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: "150%",
    },
    h3: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "150%",
    },
    button: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "150%",
    },
  },
  palette: {
    primary: {
      main: "#262626",
      light: "#dcdcdc",
    },
    error: {
      main: "#F05252",
    },
    text: {
      primary: "#212121",
      secondary: "#1F2A37",
      tertiary: "#111928",
      grey: "#6B7280",
      link: "#1C64F2",
      lightText: "#52575C",
    },
    badge: {
      greenBg: "rgba(51, 188, 40, 0.1)",
      greenText: "#33BC28",
      redText: "#E02424",
      redBg: "#ff6854",
      orangeBg: "#FEECDC",
      orangeText: "#FF8A4C",
    },
    background: {
      econsentBg: "#262626",
      lightBg: "#FAFAFA",
      dividerBg: "#ECECEF",
      tableHeaderBg: "#F3F4F6",
    },
    border: {
      type1: "#E5E7EB",
      type2: "#E7E7E7",
      type3: "#CFCFC9",
    },
    divider: "rgba(255,255,255,0.1)",
  },
});
