import { Button, styled, SxProps, TextField } from "@mui/material";

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",
  padding: "0 20px",
  height: "91px",
  width: "100%",
  borderBottom: "1px solid",
  borderColor: theme.palette.border?.type1,
  backgroundColor: "#ffffff",
}));

export const StyledTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F9FAFB",
    borderRadius: 16,
    width: "300px",
    height: "50px",
    alignItems: "left",
    "& fieldset": {
      border: "1px solid #E5E7EB",
    },
  },
}));

export const UserTableContainer: SxProps = {
  height: "calc(100% - 155px)",
  overflow: "auto",
};

export const StyledButton = styled(Button)(() => ({
  backgroundColor: "#262626",
  borderRadius: "12px",
  textTransform: "none",
  padding: "9px 13px",
  boxShadow: "none",
}));

export const HeaderTextStyle: SxProps = {
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "150%",
  color: "#111928",
};
