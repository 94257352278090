import { Box, Skeleton, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { noItemsStyles } from "./dashboard.style";
import InvitationItem from "./InvitationItem";

const InvitationTab = () => {
  const [loadingId, setLoadingId] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);

  const [invitationsList, setInvitationsList] = useState<
    {
      id: string;
      name: string;
      status: string;
    }[]
  >([]);

  const acceptInvitationHandler = async (id: string) => {
    try {
      setLoadingId(id);
      const res: AxiosResponse = await http.post(
        `/studies/invite/${id}/accept`
      );
      toastMessage("success", res?.data?.message);
      setLoadingId("");
      setToggleLoader((p) => !p);
    } catch (err) {
      setLoadingId("");
      errorToastMessage(err as Error);
    }
  };

  const rejectInvitationHandler = async (id: string) => {
    try {
      setLoadingId(id);
      const res: AxiosResponse = await http.post(
        `/studies/invite/${id}/decline`
      );
      toastMessage("success", res?.data?.message);
      setLoadingId("");
      setToggleLoader((p) => !p);
    } catch (err) {
      setLoadingId("");
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        setIsLoading(true);
        const userId = localStorage.getItem("userId");
        const res: AxiosResponse = await http.get(`/studies/invite/${userId}`);
        const invs = res.data?.data?.map((inv: any) => {
          return {
            id: inv?.id,
            name: inv?.study?.name,
            status: inv?.study?.status || "test",
          };
        });
        setInvitationsList(invs);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchInvitations();
  }, [toggleLoader, setIsLoading, setInvitationsList]);

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Skeleton variant="rounded" height="84px" animation="wave" />
          <Skeleton variant="rounded" height="84px" animation="wave" />
          <Skeleton variant="rounded" height="84px" animation="wave" />
        </Box>
      ) : invitationsList?.length > 0 ? (
        invitationsList.map((invitation) => (
          <InvitationItem
            key={invitation.id}
            data={invitation}
            acceptHandler={acceptInvitationHandler}
            rejectHandler={rejectInvitationHandler}
            loadingId={loadingId}
          />
        ))
      ) : (
        <Box sx={noItemsStyles}>
          <Typography variant="h2" color="text.grey">
            No Invitations found
          </Typography>
        </Box>
      )}
    </>
  );
};

export default InvitationTab;
