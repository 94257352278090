import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Info } from "luxon";

class CustomLuxonUtils extends AdapterLuxon {
  getWeekdays = () => {
    const days = [...Info.weekdaysFormat("short")];
    return days;
  };
}

export default CustomLuxonUtils;
