import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import {
  CardHeader,
  CardLg,
  FormLabelStyle,
  IOSSwitch,
} from "../updateStudy.style";
import { TabProps } from "../updateStudy.type";

const ScreeningOptions = ({ editable, id }: TabProps) => {
  const [options, setOptions] = useState({
    eligibilityFieldEnabled: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);

  const handleChange = (e: any, name: string) => {
    if (editable) {
      setOptions((prev) => {
        return {
          ...prev,
          [name]: e.target.checked,
        };
      });
    }
  };

  const handleUpdate = async () => {
    try {
      setSubmitLoader(true);
      const body = {
        isEligibilityFieldEnabled: options?.eligibilityFieldEnabled,
      };
      const res: AxiosResponse = await http.patch(
        `/studies/${id}/config/screening-options`,
        body
      );
      toastMessage("success", res.data?.message);
      setToggleLoader((prev) => !prev);
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    const fetchScreeningOptions = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(
          `/studies/${id}/config/screening-options`
        );
        const { isEligibilityFieldEnabled } = res.data?.data;
        const newOptions = {
          eligibilityFieldEnabled: isEligibilityFieldEnabled,
        };
        setOptions(newOptions);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };

    fetchScreeningOptions();
  }, [id, toggleLoader]);

  return (
    <CardLg>
      <CardHeader>
        <Typography
          variant="subtitle2"
          fontWeight="medium"
          color="text.tertiary"
        >
          Screening Options
        </Typography>
      </CardHeader>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" fontWeight="regular" mb={3.75}>
            You can choose to use an eligibility field on your study.
          </Typography>
          <FormControl>
            <FormControlLabel
              control={<IOSSwitch />}
              checked={options?.eligibilityFieldEnabled}
              onChange={(e) => handleChange(e, "eligibilityFieldEnabled")}
              label="Eligibility field"
              sx={FormLabelStyle}
            />

            {editable && (
              <Box mt={5}>
                {submitLoader ? (
                  <CircularProgress size={25} />
                ) : (
                  <Button variant="contained" onClick={handleUpdate}>
                    Save Changes
                  </Button>
                )}
              </Box>
            )}
          </FormControl>
        </>
      )}
    </CardLg>
  );
};

export default ScreeningOptions;
