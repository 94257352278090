import { Typography } from "@mui/material";
import OT, { Session } from "@opentok/client";
import React, { useEffect, useState } from "react";
import { toastMessage } from "../../../utils/toast";
import Publisher from "./Publisher";
import Subscriber from "./Subscriber";

const OpenTok: React.FC<any> = ({ creds }) => {
  const [status, setStatus] = useState("pending");
  const [streams, setStreams] = useState<any[]>([]);
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    if (session) {
      session.on("streamCreated", (event: any) => {
        setStreams((prev) => [...prev, event.stream]);
      });
      session.on("streamDestroyed", (event: any) => {
        setStreams((prev) => {
          const idx = prev.indexOf(event.stream);
          if (idx > -1) {
            const newStreams = prev.filter((_, index) => index !== idx);
            return newStreams;
          }
          return prev;
        });
      });
    }
  }, [session]);

  useEffect(() => {
    let session: Session | undefined;
    // const dontLoad = false;
    if (creds) {
      session = OT.initSession(creds.apiKey, creds.sessionId);
      session.connect(creds.token, (err: any) => {
        if (err) {
          console.log(err);
          setStatus("failed");
          toastMessage("error", err?.message || "Something went wrong");
        } else {
          setStatus("success");
        }
      });
      setSession(session || null);
    }
    return () => {
      if (session) {
        try {
          session.off();
          session.disconnect();
        } catch (err) {
          console.log("error on cleanup");
          console.log(err);
        }
      }
    };
  }, [creds, setStatus]);

  return (
    <>
      {status === "pending" ? (
        <Typography
          variant="subtitle2"
          fontWeight={"medium"}
          color="grey"
          mt={2}
          sx={{ textAlign: "center" }}
        >
          Connecting your call
        </Typography>
      ) : status === "failed" ? (
        <Typography
          variant="subtitle2"
          fontWeight={"medium"}
          color="grey"
          mt={2}
          sx={{ textAlign: "center" }}
        >
          Something went wrong
        </Typography>
      ) : (
        <>
          {session
            ? streams.map((stream) => {
                return (
                  <Subscriber
                    stream={stream}
                    session={session}
                    key={stream.streamId}
                  />
                );
              })
            : null}
          {session ? <Publisher session={session} creds={creds} /> : null}
        </>
      )}
    </>
  );
};

export default OpenTok;
