import { Table, TableHead, TableRow, TableBody, Box } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import { useState } from "react";
import { CTData } from "../../../../utils/consentTabData";
import { StyledSortLabel, StyledTableCell } from "../../../Common/styles/table";

const ConsentFormsTab = () => {
  const [data] = useState(CTData);

  const [sortColumn, setSortColumn] = useState<undefined | string>();
  const [sortOrder, setSortOrder] = useState<undefined | string>();
  const [page, setPage] = useState(0);

  const handleSort = (order: string, column: string) => {
    setSortOrder(order);
    setSortColumn(column);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Consent form
              <SortLabel column="consentForm" />
            </StyledTableCell>
            <StyledTableCell>
              Version <SortLabel column="version" />
            </StyledTableCell>
            <StyledTableCell>
              Consent status <SortLabel column="consentStatus" />
            </StyledTableCell>
            <StyledTableCell>
              Sent on <SortLabel column="sentOn" />
            </StyledTableCell>
            <StyledTableCell>
              Consented on <SortLabel column="consentedOn" />
            </StyledTableCell>
            <StyledTableCell>
              Form status <SortLabel column="formStatus" />
            </StyledTableCell>
            <StyledTableCell align="center">Options</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell>{row.form}</StyledTableCell>
              <StyledTableCell>{row.version}</StyledTableCell>
              <StyledTableCell>
                <Box
                  component="span"
                  sx={{
                    backgroundColor: row.consent
                      ? "rgba(51, 188, 40, 0.1)"
                      : "#FEECDC",
                    color: row.consent ? "#33BC28" : "#FF8A4C",
                    padding: "2px 10px",
                    borderRadius: "8px",
                  }}
                >
                  {row.consent ? "Consented" : "Awaiting consent"}
                </Box>
              </StyledTableCell>
              <StyledTableCell>{row.sentDate}</StyledTableCell>
              <StyledTableCell>{row.consentDate}</StyledTableCell>
              <StyledTableCell>{row.status}</StyledTableCell>
              <StyledTableCell align="center">
                <MoreVert />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TablePagination
              sx={TablePaginationStyle}
              count={1}
              page={page}
              rowsPerPage={5}
              rowsPerPageOptions={[5]}
              onPageChange={handleChangePage}
              labelDisplayedRows={paginationLabel}
            />
          </TableRow>
        </TableFooter> */}
      </Table>
    </>
  );
};

export default ConsentFormsTab;
