import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ModalBaseStyles } from "../../Common/styles/modal";
import {
  CloseButton,
  DividerWithBG,
  inputLabelStyle,
} from "../../Common/UI/CommonModalDesign";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { useParams } from "react-router-dom";
import { uploadFile } from "../../../utils/upload";
import { Image } from "@mui/icons-material";
import { rowFlexStyleAlignCenter } from "../../Common/styles/flex";

const yupSchema = Yup.object({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required").max(200),
  // startDate: Yup.string(),
  // endDate: Yup.string(),
});

interface typeProps {
  visibility: boolean;
  closeModal: () => void;
  id: any;
  questionData: any;
  refreshPage: Function;
}

function AddQuestionnaireModal(props: typeProps) {
  const [loading, setLoading] = useState(false);
  const { visibility, closeModal, id } = props;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { id: studyId } = useParams();

  const submitHandler = async (values: any) => {
    try {
      let data = {
        ...values,
      };
      setFormSubmitting(true);
      if (props.questionData) {
        const res = await http.put(`/questionnaires/${props.questionData.id}`, {
          name: data.title,
          description: data.description,
          imageUrl: data?.imageUrl ? data?.imageUrl : null,
          // studyId: studyId,
        });
        toastMessage("success", res.data.message);
        closeModal();
        props.refreshPage();
      } else {
        const res = await http.post("/questionnaires", {
          name: data.title,
          description: data.description,
          imageUrl: data?.imageUrl ? data?.imageUrl : null,
          studyId: studyId,
        });
        closeModal();
        toastMessage("success", res.data.message);
        props.refreshPage();
      }
    } catch (err) {
      setFormSubmitting(false);
      errorToastMessage(err as Error);
    }
  };

  const handleFileUpload = async (setFieldValue: Function, event: any) => {
    try {
      if (event?.currentTarget?.files?.[0]) {
        setLoading(true);
        const fileUrl = await uploadFile(
          event?.currentTarget?.files?.[0],
          "questionnaire_image"
        );
        setFieldValue("imageUrl", fileUrl);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={visibility} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, p: 4 }}>
        <Formik
          initialValues={{
            title: props?.questionData?.name || "",
            description: props?.questionData?.description || "",
            imageUrl: props?.questionData?.imageUrl || "",
            // startDate: "",
            // endDate: "",
          }}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">
                  {id
                    ? "Edit Screening Questionnaire"
                    : "Create Screening Questionnaire"}
                </Typography>
                <CloseButton buttonClick={closeModal} />
              </Box>
              <DividerWithBG />

              <Typography variant="subtitle2" fontWeight="medium" mb={2}>
                IMAGE
              </Typography>
              <Box mb={2} sx={{ display: "flex", alignItems: "flex-end" }}>
                <Box sx={{ mr: 2 }}>
                  {formik.values.imageUrl ? (
                    <img
                      src={formik.values.imageUrl}
                      alt="question"
                      style={{ height: "100px", width: "100px" }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "100px",
                        width: "100px",
                        background: "#E5E7EB",
                        ...rowFlexStyleAlignCenter,
                        justifyContent: "center",
                      }}
                    >
                      <Image />
                    </Box>
                  )}
                </Box>
                <input
                  accept="image/*"
                  id="question-image"
                  hidden
                  type="file"
                  onChange={(e) => {
                    handleFileUpload(formik.setFieldValue, e);
                  }}
                />
                {loading ? (
                  <Box>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  <label htmlFor="question-image">
                    <Button variant="outlined" component="span">
                      Upload
                    </Button>
                  </label>
                )}
              </Box>
              <InputLabel sx={inputLabelStyle} htmlFor="title">
                Questionnaire title
              </InputLabel>
              <TextField
                fullWidth
                error={
                  formik.touched?.title && formik.errors.title ? true : false
                }
                id="title"
                type="text"
                {...formik.getFieldProps("title")}
                helperText={
                  formik.touched?.title && formik.errors?.title
                    ? formik.errors?.title?.toString()
                    : null
                }
                placeholder="Enter Title"
                variant="outlined"
              />

              <Box
                sx={{
                  mt: 2.5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <InputLabel sx={inputLabelStyle} htmlFor="description">
                    Questionnaire Description
                  </InputLabel>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    error={
                      formik.touched?.description && formik.errors?.description
                        ? true
                        : false
                    }
                    id="description"
                    type="text"
                    {...formik.getFieldProps("description")}
                    helperText={
                      formik.touched?.description && formik.errors?.description
                        ? formik.errors?.description?.toString()
                        : null
                    }
                    placeholder="Type your text here"
                    variant="outlined"
                    inputProps={{
                      maxLength: 200,
                    }}
                  />
                </Box>
                <Typography
                  fontWeight="medium"
                  sx={{ fontSize: "12px", alignSelf: "flex-end" }}
                >
                  {`${formik.values.description.length}`}/200
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  mt: 2.5,
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <InputLabel sx={inputLabelStyle} htmlFor="startDate">
                    Start date
                  </InputLabel>
                  <TextField
                    error={
                      formik.touched?.startDate && formik.errors.startDate
                        ? true
                        : false
                    }
                    id="startDate"
                    type="date"
                    {...formik.getFieldProps("startDate")}
                    helperText={
                      formik.touched?.startDate && formik.errors?.startDate
                        ? formik.errors?.startDate
                        : null
                    }
                    placeholder="DD/MM/YYYY"
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <InputLabel sx={inputLabelStyle} htmlFor="endDate">
                    End date
                  </InputLabel>
                  <TextField
                    error={
                      formik.touched?.endDate && formik.errors?.endDate
                        ? true
                        : false
                    }
                    id="endDate"
                    type="date"
                    {...formik.getFieldProps("endDate")}
                    helperText={
                      formik.touched?.endDate && formik.errors?.endDate
                        ? formik.errors?.endDate
                        : null
                    }
                    placeholder="DD/MM/YYYY"
                    variant="outlined"
                  />
                </Box>
              </Box> */}
              <Box
                sx={{
                  mt: 2.5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {formSubmitting ? (
                  <Box sx={{ mr: 2 }}>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  <>
                    <Button
                      sx={{ mr: 2.5 }}
                      variant="outlined"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                      Save & Add Questions
                    </Button>
                  </>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default AddQuestionnaireModal;
