import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { rowFlexStyle } from "../../../Common/styles/flex";
import { conditions } from "../questionTypes";

type Props = {
  question: any;
  modifyQuestion: Function;
  preview?: boolean;
};

const nameType: any = {
  lt: "maxValue",
  lte: "maxValue",
  eq: "minValue",
  gte: "minValue",
  gt: "minValue",
};

const stateObj: any = {
  lt: {
    condition: "lt",
    code: "",
    maxValue: "",
  },
  lte: {
    condition: "lte",
    code: "",
    maxValue: "",
  },
  between: {
    condition: "between",
    code: "",
    minValue: "",
    maxValue: "",
  },
  eq: {
    condition: "eq",
    code: "",
    minValue: "",
  },
  gte: {
    condition: "gte",
    code: "",
    minValue: "",
  },
  gt: {
    condition: "gt",
    code: "",
    minValue: "",
  },
};

const initState = {
  condition: "lt",
  code: "",
  maxValue: "",
};

const NumberType: React.FC<Props> = ({ question, modifyQuestion, preview }) => {
  const { responseCodes } = question.properties;

  const handleCondition = (value: string, idx: number) => {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: responseCodes.map((r: any, i: number) =>
          i === idx ? { ...stateObj[value] } : r
        ),
      },
    };
    modifyQuestion(newVal);
  };

  const handleInput = (e: any, idx: number) => {
    // const regex = /^[0-9\b]+$/;
    // if (e.target.value === "" || regex.test(e.target.value)) {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: responseCodes.map((r: any, i: number) =>
          i === idx ? { ...r, [e.target.name]: e.target.value } : r
        ),
      },
    };
    modifyQuestion(newVal);
    // }
  };

  const addField = () => {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: [...responseCodes, initState],
      },
    };
    modifyQuestion(newVal);
  };

  const removeField = (idx: number) => {
    const newVal = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: responseCodes.filter((_: any, i: number) => i !== idx),
      },
    };
    modifyQuestion(newVal);
  };

  return (
    <>
      {responseCodes.length > 0 ? (
        <>
          <Grid container alignItems="center" columnSpacing={2} mb={2}>
            <Grid xs={7} item>
              <Typography variant="subtitle1" fontWeight="medium" mb={1}>
                If answer is
              </Typography>
            </Grid>
            <Grid xs={5} item>
              <Typography variant="subtitle1" fontWeight="medium" mb={1}>
                Response Code
              </Typography>
            </Grid>
          </Grid>
          {responseCodes.map((code: any, idx: number) => {
            return (
              <Grid
                container
                alignItems="center"
                columnSpacing={2}
                mb={2}
                key={idx}
              >
                <Grid xs={4} item>
                  <Select
                    fullWidth
                    value={code.condition}
                    onChange={(event) =>
                      handleCondition(event.target.value, idx)
                    }
                  >
                    {conditions["NUM"].map(({ label, value }: any) => (
                      <MenuItem value={value} key={value + label}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid xs={3} item>
                  {code.condition === "between" ? (
                    <Box sx={{ ...rowFlexStyle, alignItems: "center" }}>
                      <TextField
                        name="minValue"
                        value={code["minValue"]}
                        placeholder="Min"
                        type="number"
                        onChange={(e) => handleInput(e, idx)}
                        // maxLength={3}
                        InputProps={{
                          readOnly: preview,
                        }}
                        fullWidth
                      />
                      <Box px={1}>-</Box>
                      <TextField
                        name="maxValue"
                        value={code["maxValue"]}
                        placeholder="Max"
                        type="number"
                        onChange={(e) => handleInput(e, idx)}
                        // maxLength={3}
                        InputProps={{
                          readOnly: preview,
                        }}
                        fullWidth
                      />
                    </Box>
                  ) : (
                    <TextField
                      name={`${nameType[code.condition]}`}
                      value={code[nameType[code.condition]]}
                      placeholder="Value"
                      type="number"
                      onChange={(e) => handleInput(e, idx)}
                      // maxLength={3}
                      InputProps={{
                        readOnly: preview,
                      }}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    name="code"
                    value={code.code}
                    onChange={(e) => handleInput(e, idx)}
                    placeholder="Code"
                    type="number"
                    InputProps={{
                      readOnly: preview,
                    }}
                    fullWidth
                  />
                </Grid>
                {!preview && (
                  <Grid xs={2} item>
                    <IconButton
                      onClick={addField}
                      aria-label="add"
                      size="medium"
                      sx={{ ml: 1 }}
                    >
                      <Add fontSize="medium" sx={{ color: "primary.main" }} />
                    </IconButton>
                    <IconButton
                      onClick={() => removeField(idx)}
                      aria-label="delete"
                      size="medium"
                      sx={{ ml: 1 }}
                    >
                      <Delete fontSize="medium" sx={{ color: "error.main" }} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </>
      ) : preview ? (
        <Typography variant="subtitle1" fontWeight="medium" mb={1} color="grey">
          No Response Codes
        </Typography>
      ) : (
        <Button variant="contained" onClick={addField}>
          Add Response code
        </Button>
      )}
    </>
  );
};

const ChoiceType: React.FC<Props> = ({ question, modifyQuestion, preview }) => {
  const { fields } = question;

  const handleInput = (e: any, idx: number) => {
    const newVal = {
      ...question,
      fields: fields.map((f: any, i: number) =>
        i === idx ? { ...f, [e.target.name]: e.target.value } : f
      ),
    };
    modifyQuestion(newVal);
  };

  return (
    <>
      {fields.map((f: any, idx: number) => (
        <Grid
          key={f.id}
          container
          alignItems="center"
          rowSpacing={1}
          columnSpacing={2}
          mb={2}
        >
          <Grid xs={7} item>
            <Typography variant="subtitle1" fontWeight="medium" mb={1}>
              If choice is
            </Typography>
          </Grid>
          <Grid xs={5} item>
            <Typography variant="subtitle1" fontWeight="medium" mb={1}>
              Response Code
            </Typography>
          </Grid>
          <Grid xs={7} item>
            <TextField
              placeholder={`Choice ${idx + 1}`}
              value={f.isOther ? "Other option" : f.label}
              fullWidth
            />
          </Grid>
          <Grid xs={5} item>
            <TextField
              name="code"
              value={f.code}
              onChange={(e) => handleInput(e, idx)}
              placeholder="Code"
              type="number"
              // maxLength={3}
              InputProps={{
                readOnly: preview,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const CommonType: React.FC<Props> = ({ question, modifyQuestion, preview }) => {
  const { properties } = question;
  const changeHandler = (event: any) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        responseCodes: [{ code: event.target.value }],
      },
    };
    modifyQuestion(newQuestion);
  };

  return (
    <>
      <Typography variant="subtitle1" fontWeight="medium" mb={1}>
        Response code
      </Typography>
      <TextField
        name="code"
        fullWidth
        type="number"
        value={properties?.responseCodes[0]?.code}
        onChange={changeHandler}
        placeholder="Code"
        InputProps={{
          readOnly: preview,
        }}
      />
    </>
  );
};

const responseType: any = {
  multiple_choice: ChoiceType,
  likert_scale: ChoiceType,
  checkbox: ChoiceType,
  yes_no: ChoiceType,
  number: NumberType,
  short_text: CommonType,
  long_text: CommonType,
  date: CommonType,
  date_time: CommonType,
};

const ResponseLogic: React.FC<Props> = ({
  question,
  modifyQuestion,
  preview,
}) => {
  const ResponseComponent = responseType[question.type];

  return (
    <>
      {responseType.hasOwnProperty(question.type) && (
        <ResponseComponent
          question={question}
          modifyQuestion={modifyQuestion}
          preview={preview}
        />
      )}
    </>
  );
};

export default ResponseLogic;
