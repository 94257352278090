import "react-quill/dist/quill.snow.css";
import {
  Box,
  Divider,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { DividerStyle } from "../../consentForm.style";
import MemberSignature from "./MemberSignature/MemberSignature";
import FormText from "./FormText";
import CheckboxComp from "./CheckboxComp";
import SignatureText from "./SignatureText";
import { useNavigate, useParams } from "react-router-dom";
import { submitCFContent } from "../../../../../Redux/actions/consentActions";
import { buttonLoaderSx } from "../../../../Common/styles/modal";

const ContentTab = () => {
  const dispatch = useAppDispatch();
  const { submitLoader, loading, error } = useAppSelector(
    (state) => state.consent
  );
  const navigate = useNavigate();
  const { consentId, id } = useParams();

  const handleSaveChanges = () => {
    dispatch(submitCFContent(id, consentId));
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box maxWidth={"782px"}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography>{error}</Typography>
      ) : (
        <>
          <FormText />
          <Divider sx={DividerStyle} />
          <CheckboxComp />
          <Divider sx={DividerStyle} />
          <SignatureText />
          <Divider sx={DividerStyle} />
          <MemberSignature />
          <Box mt={5}>
            {submitLoader ? (
              <Box sx={buttonLoaderSx}>
                <CircularProgress size={25} />
              </Box>
            ) : (
              <>
                <Button
                  variant="contained"
                  sx={{
                    mr: 1.25,
                  }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
                <Button variant="outlined" onClick={handleBack}>
                  Cancel
                </Button>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ContentTab;
