import { styled, SxProps, TextField } from "@mui/material";

export const HeaderContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "20px",
    padding: "0 20px",
    height: "91px",
    width: "100%",
    borderBottom: "1px solid",
    borderColor: theme.palette.border?.type1,
    backgroundColor: "#ffffff",
  }));
  
   export const StyledTextField = styled(TextField)(() => ({
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F9FAFB",
      borderRadius: 16,
      width: "562px",
      height: "50px",
      alignItems: "left",
      "& fieldset": {
        border: "1px solid #E5E7EB",
      },
    },
  }));

export const AuditTable: SxProps = {
    height: "calc(100% - 155px)",
    overflow: "auto",
  };