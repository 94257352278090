import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  pageSize,
  StyledTableCell,
  TablePaginationStyle,
  paginationLabel,
} from "../../Common/styles/table";
import { greenBadge, orangeBadge } from "../../Common/styles/badge";
import { ChevronLeft, MoreVert } from "@mui/icons-material";
import { debounce } from "lodash";
import { ScoreContainer, ScoreContent, scoreHeaderStyle } from "./scores.style";
import ViewScoreModal from "./ViewScoreModal";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ScoreList: React.FC = () => {
  const [selectedScoreId, setSelectedScoreId] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectId, setSelectId] = useState("");
  const query = useQuery();
  const qid: string = query.get("id") || "";
  const name: string = query.get("name") || "";

  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [scores, setScores] = useState<any[]>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [toggleloader, setToggleLoader] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/score_expressions/list?page=${page}&size=${pageSize}&questionnaireId=${qid}`;
        if (search) {
          url += `&search=${search}`;
        }
        const res = await http.get(url);
        const scores = res.data.data.scoreExpressions.map((item: any) => {
          return {
            id: item?.id,
            name: item?.title,
            date: moment(item?.createdAt).format("DD/MM/YYYY"),
            status: item.status,
          };
        });
        setScores(scores);
        setTotal(res?.data?.data?.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setTotal, setScores, setLoading, qid, page, search, toggleloader]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const deleteScore = async () => {
    try {
      setLoading(true);
      handleClose();
      await http.delete(`/score_expressions/${selectId}`);
      toastMessage("success", "Score deleted successfully");
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const onSelect = (record: any) => {
    if (record.status === "active") {
      setSelectedScoreId(record.id);
    } else if (record.status === "draft") {
      navigate(`create?id=${qid}&name=${name}&scoreId=${record.id}`);
    }
  };

  const closePreview = () => {
    setSelectedScoreId("");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const createScore = () => {
    navigate(`create?id=${qid}&name=${name}`);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((event) => {
        setSearch(event.target.value);
        setPage(1);
      }, 500),
    [setSearch, setPage]
  );

  return (
    <ScoreContainer>
      <Box sx={scoreHeaderStyle}>
        <IconButton onClick={handleBack}>
          <ChevronLeft sx={{ color: "text.secondary" }} fontSize="large" />
        </IconButton>
        <Typography variant="h2" color="text.secondary" sx={{ mx: 2.5 }}>
          Scores
        </Typography>
        <TextField placeholder="Search..." onChange={modifySearchTerm} />
        <span style={{ marginLeft: "auto" }}></span>
        <Button variant="contained" onClick={createScore}>
          Create Score
        </Button>
      </Box>
      <ScoreContent>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Score name</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Options</StyledTableCell>
            </TableRow>
          </TableHead>
          {!isLoading && (
            <>
              <TableBody>
                {scores &&
                  scores.length > 0 &&
                  scores.map((row: any) => (
                    <TableRow key={row.id}>
                      <StyledTableCell>
                        <Box
                          onClick={() => onSelect(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          {row.name}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{row.date}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={
                            row.status === "active" ? greenBadge : orangeBadge
                          }
                        >
                          {row.status}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          onClick={(e) => {
                            setSelectId(row.id);
                            handleClick(e);
                          }}
                        >
                          <MoreVert sx={{ color: "text.secondary" }} />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {total > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={total}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
        {isLoading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : scores.length === 0 ? (
          <Typography
            variant="h1"
            color="grey"
            sx={{ textAlign: "center", mt: 5 }}
          >
            No scores found
          </Typography>
        ) : null}
      </ScoreContent>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={deleteScore}>Delete</MenuItem>
      </Menu>
      {selectedScoreId && (
        <ViewScoreModal
          scoreId={selectedScoreId}
          qid={qid}
          closeHandler={closePreview}
        />
      )}
    </ScoreContainer>
  );
};

export default ScoreList;
