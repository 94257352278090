import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import consentSignSlice from "./reducers/consentSignSlice";
import consentSlice from "./reducers/consentSlice";
import questionSlice from "./reducers/questionSlice";
import userSlice from "./reducers/userSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    question: questionSlice,
    consent: consentSlice,
    consentSign: consentSignSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
