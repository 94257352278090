import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import AddSiteModal from "./AddSiteModal";
import { SiteListType, SiteTabProps } from "./dashboard.type";

const SitesTab = ({ openAddModal, handleModalVisibility }: SiteTabProps) => {
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowId, setRowId] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sitesList, setSitesList] = useState<SiteListType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleModalOpen = (id: string) => {
    setRowId(id);
    handleModalVisibility(true);
  };

  const handleModalClose = () => {
    handleModalVisibility(false);
    setRowId("");
  };

  const handleSort = (order: string, column: string) => {
    setSortOrder(order);
    setSortColumn(column);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setIsLoading(true);
        let url = `/sites/list?pageSize=${pageSize}&pageNo=${page + 1}`;
        if (sortColumn && sortOrder) {
          url += `&sortBy=${sortColumn}&orderBy=${sortOrder}`;
        }
        const res: AxiosResponse = await http.get(url);
        const newSites = res.data?.data.map((site: any) => {
          return {
            id: site?.id,
            name: site?.name,
            abbreviation: site?.abbreviation,
            country: site?.country,
            zipcode: site?.address?.zipcode,
          };
        });
        setSitesList(newSites);
        setTotal(res.data?.meta?.paginationOptions?.totalItems || 0);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchSites();
  }, [page, sortColumn, sortOrder, setTotal, setSitesList, toggleLoader]);

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Site Name
              <SortLabel column="name" />
            </StyledTableCell>
            <StyledTableCell>Abbreviation</StyledTableCell>
            <StyledTableCell>Country</StyledTableCell>
            <StyledTableCell>Zipcode</StyledTableCell>
            <StyledTableCell>Options</StyledTableCell>
          </TableRow>
        </TableHead>
        {!isLoading && (
          <>
            <TableBody>
              {sitesList &&
                sitesList.map((row: SiteListType) => (
                  <TableRow key={row.id}>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.abbreviation}</StyledTableCell>
                    <StyledTableCell>{row.country}</StyledTableCell>
                    <StyledTableCell>{row.zipcode}</StyledTableCell>

                    <StyledTableCell>
                      <Box
                        component="span"
                        sx={{
                          textDecoration: "underline",
                          textUnderlineOffset: "4.5px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleModalOpen(row.id)}
                      >
                        Manage
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {total > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={total}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {openAddModal && (
        <AddSiteModal
          open={openAddModal}
          id={rowId}
          closeModal={handleModalClose}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default SitesTab;
