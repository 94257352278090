import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ModalBaseStyles } from "../../../Common/styles/modal";
import {
  CloseButton,
  DividerWithBG,
  inputLabelStyle,
} from "../../../Common/UI/CommonModalDesign";

const yupSchema = Yup.object({
  language: Yup.string().required(),
  constentForm: Yup.string().required(),
  emailConfirm: Yup.string().required(),
  email: Yup.string().email().required(),
});

interface typeProps {
  open: boolean;
  setOpen: () => void;
}

const updatedInputLabelStyle = {
  ...inputLabelStyle,
  mt: "30px",
};

function AddSubjectModal(props: typeProps) {
  const { open, setOpen } = props;

  return (
    <Modal open={open} onClose={setOpen}>
      <Box sx={{ ...ModalBaseStyles, p: 4 }}>
        <Formik
          initialValues={{
            language: "",
            constentForm: "",
            emailConfirm: "",
            email: "",
          }}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">Add New Subject</Typography>
                <CloseButton buttonClick={setOpen} />
              </Box>
              <DividerWithBG />
              <Typography variant="body1" fontWeight="medium">
                Screening ID
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: " rgba(33, 33, 33, 0.6) ", mt: 1, mb: "10px" }}
              >
                The screening ID will be generated by Econsent EDC and shared
                with eConsent.
              </Typography>

              <InputLabel sx={updatedInputLabelStyle} htmlFor="language">
                Language
              </InputLabel>
              <TextField
                fullWidth
                error={
                  formik.touched?.language && formik.errors.language
                    ? true
                    : false
                }
                id="language"
                type="text"
                {...formik.getFieldProps("language")}
                helperText={
                  formik.touched?.language && formik.errors?.language
                    ? formik.errors?.language
                    : null
                }
                select
                placeholder="Please Enter"
                variant="outlined"
              >
                {["english", "hindi"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <InputLabel sx={updatedInputLabelStyle} htmlFor="constentForm">
                Consent form
              </InputLabel>
              <TextField
                fullWidth
                error={
                  formik.touched?.constentForm && formik.errors.constentForm
                    ? true
                    : false
                }
                id="constentForm"
                type="text"
                {...formik.getFieldProps("constentForm")}
                helperText={
                  formik.touched?.constentForm && formik.errors?.constentForm
                    ? formik.errors?.constentForm
                    : null
                }
                select
                placeholder="Please Enter"
                variant="outlined"
              >
                {["Abc", "Xyz"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <FormControl>
                <FormLabel sx={updatedInputLabelStyle}>
                  Send an email invitation
                </FormLabel>
                <RadioGroup {...formik.getFieldProps("emailConfirm")} row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
                <FormHelperText>
                  {formik.touched?.emailConfirm && formik.errors?.emailConfirm
                    ? formik.errors?.emailConfirm
                    : null}
                </FormHelperText>
              </FormControl>

              <InputLabel sx={updatedInputLabelStyle} htmlFor="email">
                Email address
              </InputLabel>
              <TextField
                fullWidth
                error={
                  formik.touched?.email && formik.errors.email ? true : false
                }
                id="email"
                type="email"
                {...formik.getFieldProps("email")}
                helperText={
                  formik.touched?.email && formik.errors?.email
                    ? formik.errors?.email
                    : null
                }
                placeholder="Enter"
                variant="outlined"
              />

              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button sx={{ mr: 2.5 }} variant="outlined" onClick={setOpen}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Add Subject
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default AddSubjectModal;
