import { Box, Typography, Button, Paper } from "@mui/material";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { ListItemContainerStyle } from "./dashboard.style";

type Props = {
  data: {
    id: string;
    studyName: string;
    patientsName: string;
    scheduledStartTime: string;
    subjectId: string;
    studyId: string;
  };
};

const AppointmentsItem: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const time = DateTime.fromISO(data.scheduledStartTime || "");
  const hours = time.diffNow("hours").toObject().hours;
  const showCall = hours && hours <= 0.5;

  const joinCallHandler = () => {
    navigate(`/study/${data.studyId}/subjects/consent-sign/${data.subjectId}`);
  };

  return (
    <Paper sx={ListItemContainerStyle}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "500px",
          maxWidth: "25%",
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium">
          Study:
        </Typography>
        <Typography ml={1} variant="subtitle2">
          {data.studyName}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", ml: 5 }}>
        <Typography variant="subtitle1" fontWeight="medium">
          Subject:
        </Typography>
        <Typography ml={1} variant="subtitle2">
          {data.patientsName}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
        <Typography variant="subtitle1" fontWeight="medium">
          Appointment Time :
        </Typography>
        <Typography ml={1} variant="subtitle2">
          {DateTime.fromISO(data.scheduledStartTime).toFormat(
            "MMM dd, yyyy hh:mm a"
          )}
        </Typography>
        {showCall && (
          <Button
            variant="contained"
            onClick={joinCallHandler}
            size="large"
            sx={{ ml: 2 }}
          >
            Join Call
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default AppointmentsItem;
