import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ModalBaseStyles, buttonLoaderSx } from "../../Common/styles/modal";
import { useParams } from "react-router-dom";
import {
  CloseButton,
  DividerWithBG,
  inputLabelStyle,
} from "../../Common/UI/CommonModalDesign";
import { consentFormListType } from "./Consent";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { langArray } from "../../../utils/lang";

const yupSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  country: Yup.string().required("country is required"),
  // sites: Yup.string().required("Required"),
  language: Yup.string().required("Language is required"),
  versionNumber: Yup.string().optional(),
  versionDate: Yup.string().optional(),
});

interface typeProps {
  modalVisibility: boolean;
  handleModalClose: () => void;
  id?: string | null;
  refreshPage: Function;
}

const updatedInputLabelStyle = {
  ...inputLabelStyle,
  mt: "30px",
};

function NewConsentModal(props: typeProps) {
  const { modalVisibility, handleModalClose, refreshPage } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const handleAddCF = async (values: consentFormListType) => {
    try {
      setIsLoading(true);
      const { name, language, country } = values;
      const body: consentFormListType = {
        name: name,
        language: language,
        country: country,
      };
      const res = await http.post(`/studies/${id}/consent-form`, body);
      toastMessage("success", res.data?.message);
      handleModalClose();
      refreshPage();
      setIsLoading(false);
      // navigate("/study/:id/consent/consent-builder");
    } catch (err) {
      errorToastMessage(err as Error);
      setIsLoading(false);
    }
  };

  return (
    <Modal open={modalVisibility} onClose={handleModalClose}>
      <Box sx={{ ...ModalBaseStyles, p: 4 }}>
        <Formik
          initialValues={{
            name: "",
            country: "",
            // sites: "",
            language: "",
            versionNumber: "",
            versionDate: "",
          }}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            handleAddCF(values);
            console.log(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">Create New Consent Form</Typography>
                <CloseButton buttonClick={handleModalClose} />
              </Box>
              <DividerWithBG />

              <>
                <InputLabel sx={updatedInputLabelStyle} htmlFor="name">
                  Form name
                </InputLabel>
                <TextField
                  fullWidth
                  error={
                    formik.touched?.name && formik.errors.name ? true : false
                  }
                  id="name"
                  type="text"
                  {...formik.getFieldProps("name")}
                  helperText={
                    formik.touched?.name && formik.errors?.name
                      ? formik.errors?.name
                      : null
                  }
                  placeholder="Enter"
                  variant="outlined"
                />

                <InputLabel sx={updatedInputLabelStyle} htmlFor="country">
                  country
                </InputLabel>
                <TextField
                  fullWidth
                  error={
                    formik.touched?.country && formik.errors?.country
                      ? true
                      : false
                  }
                  id="country"
                  type="text"
                  {...formik.getFieldProps("country")}
                  helperText={
                    formik.touched?.country && formik.errors?.country
                      ? formik.errors?.country
                      : null
                  }
                  select
                  placeholder="Please Enter"
                  variant="outlined"
                >
                  {[
                    "India",
                    "USA",
                    "United Kingdom",
                    "Australia",
                    "Canada",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                {/* <InputLabel sx={updatedInputLabelStyle} htmlFor="sites">
                  site(s)
                </InputLabel>
                <TextField
                  fullWidth
                  error={
                    formik.touched?.sites && formik.errors?.sites ? true : false
                  }
                  id="sites"
                  type="text"
                  {...formik.getFieldProps("sites")}
                  helperText={
                    formik.touched?.sites && formik.errors?.sites
                      ? formik.errors?.sites
                      : null
                  }
                  select
                  placeholder="Please Enter"
                  variant="outlined"
                >
                  {["Abc", "Xyz"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField> */}

                <InputLabel sx={updatedInputLabelStyle} htmlFor="language">
                  Language
                </InputLabel>
                <TextField
                  fullWidth
                  error={
                    formik.touched?.language && formik.errors.language
                      ? true
                      : false
                  }
                  id="language"
                  type="text"
                  {...formik.getFieldProps("language")}
                  helperText={
                    formik.touched?.language && formik.errors?.language
                      ? formik.errors?.language
                      : null
                  }
                  select
                  placeholder="Please Enter"
                  variant="outlined"
                >
                  {langArray.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <InputLabel
                      sx={updatedInputLabelStyle}
                      htmlFor="versionNumber"
                    >
                      version number
                    </InputLabel>
                    <TextField
                      error={
                        formik.touched?.versionNumber &&
                        formik.errors.versionNumber
                          ? true
                          : false
                      }
                      id="versionNumber"
                      type="text"
                      {...formik.getFieldProps("versionNumber")}
                      helperText={
                        formik.touched?.versionNumber &&
                        formik.errors?.versionNumber
                          ? formik.errors?.versionNumber
                          : null
                      }
                      placeholder="Enter"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      sx={updatedInputLabelStyle}
                      htmlFor="versionDate"
                    >
                      version Date
                    </InputLabel>

                    <TextField
                      error={
                        formik.touched?.versionDate && formik.errors.versionDate
                          ? true
                          : false
                      }
                      id="versionDate"
                      type="text"
                      {...formik.getFieldProps("versionDate")}
                      helperText={
                        formik.touched?.versionDate &&
                        formik.errors?.versionDate
                          ? formik.errors?.versionDate
                          : null
                      }
                      placeholder="Enter"
                      variant="outlined"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{ mr: 2.5 }}
                    variant="outlined"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                  {isLoading ? (
                    <Box sx={buttonLoaderSx}>
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    <Button variant="contained" type="submit">
                      Create New Form
                    </Button>
                  )}
                </Box>
              </>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default NewConsentModal;
