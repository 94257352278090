import React, { useState } from "react";
import {
  Button,
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  inputLabelStyle,
  grayBox,
  inputContainer,
  link,
} from "./style/SharedStyle";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { useAppDispatch } from "../../Redux/hooks";
import { setUserAuth } from "../../Redux/reducers/userSlice";

const yupSchema = Yup.object({
  email: Yup.string().required("Required"),
  password: Yup.string()
    .required("Password required")
    .min(8, "atleast 8 characters"),
});

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const forgotPassword = () => {
    navigate("/auth/forgot-password");
  };

  const submitHandler = async (values: any) => {
    try {
      setLoading(true);
      const body = {
        email: values.email,
        password: values.password,
      };
      const res = await http.post("/auth/login", body);
      const { refreshToken, accessToken, id } = res.data.data;
      localStorage.setItem("econsent-accessToken", accessToken);
      localStorage.setItem("econsent-refresh-token", refreshToken);
      localStorage.setItem("userId", id);
      dispatch(
        setUserAuth({
          authenticated: true,
        })
      );
      toastMessage("success", res?.data?.message);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={grayBox}></Box>
      <Box sx={inputContainer} pt={20} pl={9} pr={9}>
        <Typography variant="h1">Log In</Typography>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box mt={6} mb={6}>
                <InputLabel sx={inputLabelStyle} htmlFor="email">
                  Email
                </InputLabel>
                <TextField
                  fullWidth
                  error={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  id="email"
                  type="email"
                  {...formik.getFieldProps("email")}
                  variant="standard"
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                />
              </Box>

              <Box>
                <InputLabel sx={inputLabelStyle} htmlFor="password">
                  Password
                </InputLabel>
                <TextField
                  error={
                    formik.touched.password && formik.errors.password
                      ? true
                      : false
                  }
                  // helperText={
                  //   <Typography variant="body1" sx={{ opacity: 0.4 }}>
                  //     Minimum 8 characters with at least 1 number
                  //   </Typography>
                  // }
                  helperText=" Minimum 8 characters with at least 1 number"
                  {...formik.getFieldProps("password")}
                  id="password"
                  fullWidth
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {loading ? (
                <Box
                  sx={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  disabled={
                    !formik.dirty ||
                    (!formik.errors.password && !formik.errors.email
                      ? false
                      : true)
                  }
                  size="large"
                  type="submit"
                  sx={{ marginTop: "40px" }}
                  variant="contained"
                  fullWidth
                >
                  Log In
                </Button>
              )}
            </form>
          )}
        </Formik>
        <Box>
          <Typography variant="body1" align="center" m={2}>
            By signing in you agree to Econsent{" "}
            <Button sx={link} variant="text">
              Terms of service
            </Button>{" "}
            and{" "}
            <Button sx={link} variant="text">
              Privacy policy.
            </Button>
          </Typography>
          <Typography
            variant="body1"
            align="center"
            fontWeight="medium"
            mt={"30px"}
          >
            <Button
              sx={{ textTransform: "none", color: "text.link" }}
              variant="text"
              onClick={forgotPassword}
            >
              Forgot your password?
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
