import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

export const LayoutBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "550px",
  [theme.breakpoints.down("sm")]: {
    margin: "40px",
  },
}));

export const MainContainer = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const CancelButton = () => {
  let navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }

  return (
    <Box sx={{ alignSelf: "center" }} mt={1}>
      <Button onClick={goBack} variant="text" size="large">
        Cancel
      </Button>
    </Box>
  );
};

export const BackButton = () => {
  let navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }

  return (
    <Box sx={{ top: "40px", left: "40px", position: "absolute" }}>
      <Button
        onClick={goBack}
        sx={{ color: "#A6A6A6" }}
        variant="text"
        size="small"
        startIcon={<ArrowBackIosIcon fontSize="small" />}
      >
        Back
      </Button>
    </Box>
  );
};
