import { Session } from "@opentok/client";
import React, { useEffect, useRef } from "react";

const Subscriber: React.FC<any> = ({
  session,
  stream,
}: {
  session: Session;
  stream: any;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current)
      session.subscribe(stream, ref.current, {
        showControls: false,
        height: "100%",
        width: "100%",
      });
  }, [session, stream]);

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <div ref={ref}></div>
    </div>
  );
};

export default React.memo(Subscriber);
