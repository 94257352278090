import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import TabsContainer from "./TabsContainer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { HeaderBarStyle } from "../subjects.style";

const SubjectProfile: React.FC = () => {
  const [searchParams] = useSearchParams();
  const subjectId = searchParams.get("subjectId");
  const navigate = useNavigate();
  return (
    <>
      <Box sx={HeaderBarStyle}>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeftIcon
            fontSize="large"
            sx={{
              color: "text.tertiary",
            }}
          />
        </IconButton>
        <Typography
          variant="subtitle1"
          fontWeight="medium"
          color="text.tertiary"
        >
          {subjectId}
        </Typography>
      </Box>
      <TabsContainer />
    </>
  );
};

export default SubjectProfile;
