import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import { ClipboardListIcon, ExternalLinkIcon } from "../../Assets";
import {
  CardHeader,
  CardItem,
  CardLg,
  Col4Container,
  CardLinkText,
  CardSm,
  ProfileTabContainer,
  Col3Container,
  IconTextContainer,
} from "../subjects.style";
import {
  colFlexStyle,
  rowFlexStyleAlignCenter,
} from "../../../Common/styles/flex";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage } from "../../../../utils/toast";
import moment from "moment";
import { languageMap } from "../../../../utils/lang";

const ProfileTab = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const { id, subjectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await http.get(`/study-subject/${id}/subject/${subjectId}`);
        const user = res?.data?.data;
        const newdata = {
          screeningId: user?.screeningId,
          subjectId: user?.subjectId,
          email: user?.studyProfile?.mainProfile?.email,
          name:
            user?.studyProfile?.firstName + " " + user?.studyProfile?.lastName,
          consentedOn: user?.consentedOn
            ? moment(user?.consentedOn).format("DD MMMM YYYY hh:mm A")
            : "-",
          consentedSent: user?.consentedOn
            ? moment(user?.consentedOn)
                .add(10, "minutes")
                .format("DD MMMM YYYY hh:mm A")
            : "-",
          eligibilityStatus: user?.eligibilityStatus,
          eligibleOn: user?.eligibleOn
            ? moment(user?.eligibleOn).format("DD MMMM YYYY hh:mm A")
            : "-",
          isConsented: user?.isConsented,
          language: user?.language ? languageMap[user?.language] : "English",
        };
        setData(newdata);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [id, subjectId, setLoading, setData]);

  const startCall = () => {
    navigate(`../consent-sign/${subjectId}`);
  };

  const schedule = () => {
    navigate(
      `/scheduling?subjectId=${data?.screeningId || "110011"}&name=${
        data?.name || "Username"
      }&email=${
        data?.email || "user@econsent.com"
      }&id=${subjectId}&studyId=${id}`
    );
  };

  return loading ? (
    <Box mt={6} sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={ProfileTabContainer}>
      <Box
        sx={{
          ...colFlexStyle,
          gap: "20px",
        }}
      >
        <CardLg>
          <CardHeader>
            <Typography
              variant="subtitle2"
              fontWeight="medium"
              mt={1}
              color="text.tertiary"
            >
              Subject Profile
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight="medium"
              color="text.link"
              sx={{ cursor: "pointer", mt: 1 }}
            >
              Edit
            </Typography>
          </CardHeader>
          <Box sx={Col4Container}>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Screening ID
              </Typography>
              <Box sx={IconTextContainer}>
                <ExternalLinkIcon />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  color="text.link"
                  sx={CardLinkText}
                >
                  {data?.screeningId || "110011"}
                </Typography>
              </Box>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Subject ID
              </Typography>
              <Box sx={IconTextContainer}>
                <ExternalLinkIcon />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  color="text.link"
                  sx={CardLinkText}
                >
                  {data?.subjectId || "ECON10120"}
                </Typography>
              </Box>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Created by
              </Typography>
              <Typography variant="subtitle1">Jackson</Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Site
              </Typography>
              <Typography variant="subtitle1">Harvard University</Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Language
              </Typography>
              <Typography variant="subtitle1">{data?.language}</Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Invite status
              </Typography>
              <Box sx={IconTextContainer}>
                <FiberManualRecordIcon
                  sx={{ fontSize: "10px", color: "#33BC28" }}
                />
                <Typography
                  sx={{
                    color: "#33BC28",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Active
                </Typography>
              </Box>
            </CardItem>
          </Box>
        </CardLg>
        <CardLg>
          <CardHeader>
            <Typography
              variant="subtitle2"
              fontWeight="medium"
              mt={1}
              color="text.tertiary"
            >
              Consent information (most recent)
            </Typography>
            <Box
              sx={{
                ...rowFlexStyleAlignCenter,
                gap: "12px",
              }}
            >
              <Button variant="contained">Add new</Button>
              <IconButton>
                <MoreVert sx={{ fontSize: "24px", color: "text.tertiary" }} />
              </IconButton>
            </Box>
          </CardHeader>
          <Box sx={Col3Container}>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Consent status
              </Typography>
              <Typography variant="subtitle1">
                {data?.isConsented ? "Consented" : "Consent pending"}
              </Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Consent form sent on
              </Typography>
              <Typography variant="subtitle1">{data?.consentedSent}</Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Consent form signed on
              </Typography>
              <Typography variant="subtitle1">{data?.consentedOn}</Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Consent form
              </Typography>
              <Box sx={IconTextContainer}>
                <ClipboardListIcon />
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  color="text.link"
                  sx={CardLinkText}
                >
                  ECONSENT
                </Typography>
              </Box>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Version
              </Typography>
              <Typography variant="subtitle1">1.0</Typography>
            </CardItem>
          </Box>
        </CardLg>
        <CardLg>
          <CardHeader>
            <Typography
              variant="subtitle2"
              fontWeight="medium"
              mt={1}
              color="text.tertiary"
            >
              Screening information
            </Typography>
            <Box
              sx={{
                ...rowFlexStyleAlignCenter,
                gap: "12px",
              }}
            >
              <Button variant="contained">Add new</Button>
              <IconButton>
                <MoreVert sx={{ fontSize: "24px", color: "text.tertiary" }} />
              </IconButton>
            </Box>
          </CardHeader>
          <Box sx={Col3Container}>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Eligibility status
              </Typography>
              <Typography variant="subtitle1">
                {data?.eligibilityStatus ? "Eligible" : "Pending"}
              </Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Changed on
              </Typography>
              <Typography variant="subtitle1">{data?.eligibleOn}</Typography>
            </CardItem>
            <CardItem>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Screening survey
              </Typography>
              <Typography variant="subtitle1">Yes</Typography>
            </CardItem>
          </Box>
        </CardLg>
      </Box>
      <Box
        sx={{
          ...colFlexStyle,
          gap: "20px",
        }}
      >
        <CardSm>
          <CardHeader>
            <Typography
              variant="subtitle2"
              fontWeight="medium"
              mt={1}
              color="text.tertiary"
            >
              Personal details
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight="medium"
              color="text.link"
              sx={{ cursor: "pointer", mt: 1 }}
            >
              Edit
            </Typography>
          </CardHeader>
          <CardItem>
            <Typography
              variant="subtitle1"
              fontWeight="medium"
              color="text.tertiary"
            >
              Consent information
            </Typography>
            <Box sx={IconTextContainer}>
              <MailOutlineIcon sx={{ fontSize: "22px" }} />
              <Typography
                variant="subtitle1"
                sx={{
                  textDecoration: "underline",
                  textUnderlineOffset: "4px",
                }}
              >
                {data?.email || "jackson.jack@econsent.com"}
              </Typography>
            </Box>
          </CardItem>
        </CardSm>
        {data?.eligibilityStatus && !data?.isConsented && (
          <CardSm>
            <CardHeader>
              <Typography
                variant="subtitle2"
                fontWeight="medium"
                mt={1}
                color="text.tertiary"
              >
                Consent Call
              </Typography>
            </CardHeader>
            <Button variant="contained" onClick={startCall} sx={{ mr: 2.5 }}>
              Start Call
            </Button>
            <Button variant="contained" onClick={schedule}>
              Schedule Call
            </Button>
          </CardSm>
        )}
      </Box>
    </Box>
  );
};

export default ProfileTab;
