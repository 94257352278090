import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../../../Common/UI/TabPanel";
import ContentTab from "./ContentTab/ContentTab";
import SettingsTab from "./SettingsTab";
import CBHeader from "./CBHeader";
import "../../../../styles/quillContainer.scss";
import PublishModal from "./PublishModal";
import Preview from "./Preview";
import { loadCFContentDetails } from "../../../../Redux/actions/consentActions";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../Redux/hooks";

const ConsentBuilder = () => {
  const [value, setValue] = useState("content");
  const [openModal, setOpenModal] = useState(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const { consentId, id } = useParams();
  const dispatch = useAppDispatch();

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handlePreviewChange = () => {
    setShowPreview((prevVal) => !prevVal);
  };

  useEffect(() => {
    if (value === "content") {
      dispatch(loadCFContentDetails(id, consentId));
    }
  }, [id, consentId]);

  return (
    <>
      {showPreview ? (
        <Preview handlePreviewChange={handlePreviewChange} />
      ) : (
        <>
          <CBHeader
            value={value}
            openModal={handleModalOpen}
            showPreview={showPreview}
            handlePreviewChange={handlePreviewChange}
          />
          <Box sx={{ borderBottom: 1, borderColor: "border.type1" }}>
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab label="Content" value="content" {...a11yProps(0)} />
              <StyledTab label="Settings" value="settings" {...a11yProps(1)} />
            </StyledTabs>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 205px)",
              overflow: "auto",
            }}
          >
            <MainTabPanel value={value} index={"content"}>
              <ContentTab />
            </MainTabPanel>
            <MainTabPanel value={value} index={"settings"}>
              <SettingsTab />
            </MainTabPanel>
          </Box>
          <PublishModal open={openModal} closeModal={handleModalClose} />
        </>
      )}
    </>
  );
};

export default ConsentBuilder;
