import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { HeaderStyle } from "./consentForm.style";
import { ConsentTable, StyledTextField } from "./consent.style";
import AddIcon from "@mui/icons-material/Add";
import MoreVert from "@mui/icons-material/MoreVert";
import NewConsentModal from "./NewConsentModal";
import {
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { AxiosResponse } from "axios";
import { errorToastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { languageMap } from "../../../utils/lang";

export interface consentFormListType {
  name: string;
  country: string;
  language: string;
  id?: string;
  status?: string;
  sites?: string;
  versionNumber?: string;
  versionDate?: string;
}

const Consent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [editId, setEditId] = useState<string | undefined>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [sortColumn, setSortColumn] = useState<undefined | string>();
  const [sortOrder, setSortOrder] = useState<undefined | string>();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchText, setsearchText] = useState("");

  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [consentFormList, setConsentFormList] = useState<consentFormListType[]>(
    []
  );

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleModalOpen = () => {
    setModalVisibility(true);
  };

  const handleModalClose = () => {
    setModalVisibility(false);
  };

  const handleSort = (order: string, column: string) => {
    setSortOrder(order);
    setSortColumn(column);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setsearchText(val);
        setPage(0);
      }, 500),
    [setsearchText, setPage]
  );

  const handleRowClick = (id: any) => {
    navigate(`${id}/consent-builder`);
  };

  useEffect(() => {
    const fetchConsentDetails = async () => {
      try {
        setIsLoading(true);
        let url = `/studies/${id}/consent-form?pageSize=${pageSize}&pageNo=${
          page + 1
        }`;
        if (sortColumn && sortOrder) {
          url += `&sortBy=${sortColumn}&orderBy=${sortOrder}`;
        }
        if (searchText) {
          url += `&q=${searchText}`;
        }
        const res: AxiosResponse = await http.get(url);
        const newCFList = res.data?.data?.map((item: any) => {
          return {
            name: item?.name,
            country: item?.country,
            language: item?.language,
            id: item?.id,
          };
        });
        setConsentFormList(newCFList);
        setTotal(res.data?.meta?.paginationOptions?.totalItems || 0);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchConsentDetails();
  }, [
    page,
    sortColumn,
    sortOrder,
    setTotal,
    setConsentFormList,
    toggleLoader,
    searchText,
    id,
    setPage,
  ]);

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  const duplicateConsent = () => {
    handleMenuClose();
    setEditId("");
  };

  return (
    <>
      <Box sx={HeaderStyle}>
        <Typography variant="subtitle2" fontWeight="fontWeightMedium">
          Consent Forms
        </Typography>
        <StyledTextField
          sx={{ ml: 5 }}
          placeholder="Search for Consent Forms"
          onChange={(e) => modifySearchTerm(e.target.value)}
        />
        <Button
          onClick={handleModalOpen}
          variant="contained"
          startIcon={<AddIcon />}
          size="large"
          sx={{ marginLeft: "auto" }}
        >
          Add Consent form
        </Button>
      </Box>

      <Box sx={ConsentTable}>
        <Box sx={{ padding: "20px" }}>
          <Table stickyHeader>
            <TableHead sx={{ overflow: "scroll" }}>
              <TableRow>
                <StyledTableCell>
                  Name
                  <SortLabel column="name" />
                </StyledTableCell>
                <StyledTableCell>
                  Version
                  {/* <SortLabel column="version" /> */}
                </StyledTableCell>
                <StyledTableCell>
                  Status
                  {/* <SortLabel column="status" /> */}
                </StyledTableCell>
                <StyledTableCell>
                  Country
                  {/* <SortLabel column="country" /> */}
                </StyledTableCell>
                <StyledTableCell>
                  Language
                  {/* <SortLabel column="lang" /> */}
                </StyledTableCell>
                <StyledTableCell>
                  Site
                  {/* <SortLabel column="site" /> */}
                </StyledTableCell>
                <StyledTableCell>Options</StyledTableCell>
              </TableRow>
            </TableHead>
            {!isLoading && (
              <>
                <TableBody>
                  {consentFormList.map((row) => (
                    <TableRow key={row.id}>
                      <StyledTableCell
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleRowClick(row.id)}
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          component="span"
                          sx={{
                            backgroundColor: row.name
                              ? "rgba(51, 188, 40, 0.1)"
                              : "#FEECDC",
                            color: row.name ? "#33BC28" : "#FF8A4C",
                            padding: "2px 10px",
                            borderRadius: "8px",
                          }}
                        >
                          {row.name ? "Published" : "Archived"}
                        </Box>
                        {/* {row.status} */}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ textTransform: "capitalize" }}>
                          {row.country}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {languageMap[row.language] || row.language}
                      </StyledTableCell>
                      <StyledTableCell>All</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          component="span"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            setEditId(row.id);
                            handleMenuClick(e);
                          }}
                        >
                          <MoreVert />
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {total > pageSize && (
                      <TablePagination
                        sx={TablePaginationStyle}
                        count={total}
                        page={page}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={paginationLabel}
                      />
                    )}
                  </TableRow>
                </TableFooter>
              </>
            )}
          </Table>
          {isLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
        {modalVisibility && (
          <NewConsentModal
            modalVisibility={modalVisibility}
            handleModalClose={handleModalClose}
            refreshPage={refreshPage}
          />
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem onClick={duplicateConsent}>Duplicate</MenuItem>
          <MenuItem onClick={duplicateConsent}>Delete</MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default Consent;
