import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { HeaderStyle } from "../consentForm.style";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppSelector } from "../../../../Redux/hooks";
import ReactQuill from "react-quill";
import CheckBox from "./preview/CheckBox";
import MemberSignature, { SignatureItem } from "./preview/MemberSignature";

const bodySx: SxProps = {
  height: "calc(100% - 144px)",
  display: "flex",
  justifyContent: "center",
  bgcolor: " #F5F5F5",
  overflow: "auto",
  alignItems: "flex-start",
};

const dividerSx: SxProps = {
  bgcolor: "background.dividerBg",
  mt: "40px",
  mb: "30px",
};

export default function Preview({
  handlePreviewChange,
}: {
  handlePreviewChange: () => void;
}) {
  const { loading, error, formText, checkbox, signature, memberSignature } =
    useAppSelector((state) => state.consent);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography>{error}</Typography>
      ) : (
        <>
          <Box sx={HeaderStyle}>
            <Box display="flex" alignItems="center">
              <IconButton onClick={handlePreviewChange}>
                <ChevronLeftIcon
                  fontSize="large"
                  sx={{ color: "text.tertiary" }}
                />
              </IconButton>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color="text.tertiary"
              >
                Preview
              </Typography>
            </Box>
          </Box>
          <Box sx={bodySx}>
            <Box
              p={5}
              my={5}
              sx={{
                width: "60%",
                bgcolor: "#fff",
                maxWidth: "900px",
                borderRadius: "12px",
              }}
            >
              <ReactQuill
                readOnly={true}
                theme={"bubble"}
                className={"quill-container quill-bubble-md"}
                defaultValue={formText}
              />
              <Divider variant="fullWidth" sx={dividerSx} />

              <CheckBox data={checkbox} />
              <SignatureItem
                item={{
                  header: "Subject Signature",
                  statement: signature,
                }}
              />
              <MemberSignature data={memberSignature} />

              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <Button sx={{ px: "110px" }} variant="contained" size="large">
                  Finalize Document
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
