import Card from "./Card";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, IconButton, Skeleton } from "@mui/material";
import { SettingsIcon } from "../Assets";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HeaderBar from "./HeaderBar";
import SubjectTable from "./SubjectTable";
import AddSubjectModal from "./subjectsModals/AddSubjectModal";
import { useState, useEffect } from "react";
import {
  SubjectsContainer,
  SubjectsCardContainer,
  ActionsBarContainer,
} from "./subjects.style";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";

const Subjects: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subjectMetrics, setSubjectMetrics] = useState<any>([]);

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  useEffect(() => {
    const fetchSubjectMetrics = async () => {
      try {
        setIsLoading(true);
        const orgId = "80c9c35a-4e36-442f-acd3-6d4efb9691af";
        const res: AxiosResponse = await http.get(
          `/studies/${orgId}/subject-dashboard`
        );
        const data = res.data?.data;
        const newMetrics = [
          {
            id: 1,
            title: "All",
            count: data?.subjects,
            color: "#262626",
          },
          {
            id: 2,
            title: "Awaiting Consent",
            count: data?.awaitingConsent,
            color: "#9F580A",
          },
          {
            id: 3,
            title: "Consented",
            count: data?.consented,
            color: "#33BC28",
          },
        ];
        setSubjectMetrics(newMetrics);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };

    fetchSubjectMetrics();
  }, [setSubjectMetrics, setIsLoading]);

  return (
    <>
      {openModal && <AddSubjectModal open={openModal} setOpen={handleModal} />}

      <HeaderBar setOpen={handleModal} />
      <Box sx={SubjectsContainer}>
        <Box sx={SubjectsCardContainer}>
          {isLoading
            ? [1, 2, 3].map((item: number) => {
                return (
                  <Skeleton
                    key={item}
                    variant="rounded"
                    height="112px"
                    animation="wave"
                    sx={{
                      borderRadius: "16px",
                    }}
                  />
                );
              })
            : subjectMetrics.map((item: any) => {
                const { id, title, count, color } = item;
                return (
                  <Card
                    key={id}
                    title={title}
                    statusCount={count}
                    color={color}
                  />
                );
              })}
        </Box>
        <Box sx={ActionsBarContainer}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button variant="outlined" startIcon={<ExpandMoreIcon />}>
              Actions
            </Button>
            <Button
              variant="outlined"
              startIcon={<FilterAltIcon sx={{ fontSize: "16px" }} />}
            >
              Filter
            </Button>
          </Box>
        </Box>
        <SubjectTable />
      </Box>
    </>
  );
};

export default Subjects;
