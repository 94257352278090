import { useState } from "react";
import Box from "@mui/material/Box";
import GeneralTab from "./GeneralTab";
import {
  a11yProps,
  StyledTab,
  StyledTabs,
  MainTabPanel,
} from "../../Common/UI/TabPanel";
import PersonalTab from "./PersonalTab";
import ConfigurationsTab from "./ConfigurationsTab/ConfigurationsTab";
import SitesTab from "./SitesTab/SitesTab";
import { useParams } from "react-router";
import QuestionnaireTab from "./QuestionnaireTab/QuestionnaireTab";
import ConsentTab from "./ConsentTab/ConsentTab";

export default function TabsContainer() {
  const [value, setValue] = useState(
    sessionStorage.getItem("update-study-tabs") || "General"
  );
  const { id } = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    sessionStorage.setItem("update-study-tabs", newValue);
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="General" value="General" {...a11yProps(0)} />
          <StyledTab
            label="Configurations"
            value="Configurations"
            {...a11yProps(1)}
          />
          <StyledTab
            label="Personal details fields"
            value="Personal details fields"
            {...a11yProps(2)}
          />
          <StyledTab label="Sites" value="Sites" {...a11yProps(3)} />
          <StyledTab
            label="Screening Questionnaire"
            value="Screening Questionnaire"
            {...a11yProps(4)}
          />
          <StyledTab label="Consent forms" value="consent" {...a11yProps(5)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 207px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={value} index={"General"}>
          <GeneralTab editable={true} id={id} />
        </MainTabPanel>
        <MainTabPanel value={value} index={"Configurations"}>
          <ConfigurationsTab editable={true} id={id} />
        </MainTabPanel>
        <MainTabPanel value={value} index={"Personal details fields"}>
          <PersonalTab editable={true} id={id} />
        </MainTabPanel>
        <MainTabPanel value={value} index={"Sites"}>
          <SitesTab id={id} />
        </MainTabPanel>
        <MainTabPanel value={value} index={"Screening Questionnaire"}>
          <QuestionnaireTab id={id} />
        </MainTabPanel>
        <MainTabPanel value={value} index={"consent"}>
          <ConsentTab id={id} />
        </MainTabPanel>
      </Box>
    </>
  );
}
