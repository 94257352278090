import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ReactQuill from "react-quill";

import { useAppDispatch } from "../../../../../../Redux/hooks";
import {
  editMemberSignature,
  saveMemberSignature,
} from "../../../../../../Redux/reducers/consentSlice";
import {
  quillFormatsThin,
  quillModulesThin,
} from "../../../../../../utils/reactQuillOptions";
import { LabelStyle, MemberEditLabel } from "../../../consentForm.style";

const MemberEdit = ({ data, index }: any) => {
  const [item, setItem] = useState({ ...data });
  const dispatch = useAppDispatch();

  const handleSave = () => {
    dispatch(
      saveMemberSignature({
        index,
        data: {
          ...item,
          edit: false,
        },
      })
    );
  };

  const handleCancel = (key: string, value: boolean) => {
    dispatch(editMemberSignature({ index, key, value }));
  };

  const handleEdit = (key: string, value: any) => {
    setItem((prev: any) => {
      return { ...prev, [key]: value };
    });
  };

  return (
    <Box
      sx={{
        p: "20px 20px 30px 20px",
      }}
    >
      <Typography variant="subtitle1" fontWeight="medium" color="text.tertiary">
        Signature status
      </Typography>
      <Box mb={5} display="flex" gap="10px" alignItems="center">
        <Checkbox
          checked={item.required}
          onChange={(e) => handleEdit("required", e.target.checked)}
          color="primary"
        />
        <Typography variant="subtitle1" color="text.primary">
          {item.required ? "Required" : "Not required"}
        </Typography>
      </Box>
      <InputLabel htmlFor="signature-header" sx={LabelStyle}>
        Signature header
      </InputLabel>
      <Typography variant="subtitle1" color="text.primary" mb={2}>
        Please use this to give your customized signature title. (Max 60
        characters)
      </Typography>
      <TextField
        id="signature-header"
        fullWidth
        placeholder="Study team member signature"
        inputProps={{
          maxLength: 60,
        }}
        sx={{
          maxWidth: "700px",
          mb: 5,
        }}
        value={item.header}
        onChange={(e) => handleEdit("header", e.target.value)}
      />
      <InputLabel htmlFor="signature-statement" sx={LabelStyle}>
        Signature statement
      </InputLabel>
      <Typography variant="subtitle1" color="text.primary" mb={2}>
        Please use this to define the signature statement for the user that will
        sign this consent form.
      </Typography>
      <ReactQuill
        id="signature-statement"
        modules={quillModulesThin}
        formats={quillFormatsThin}
        defaultValue={item.statement}
        placeholder="Type"
        className="quill-container quill-container-md"
        onChange={(e) => handleEdit("statement", e)}
        style={{
          maxWidth: "700px",
          marginBottom: "30px",
        }}
      />
      {/* <Box display="flex" gap="20px" mb={5}>
        <Box flexGrow={1}>
          <InputLabel htmlFor="date" sx={MemberEditLabel}>
            Date
          </InputLabel>
          <TextField
            id="date"
            type="date"
            fullWidth
            placeholder="Please Select"
            value={item.date}
            onChange={(e) => handleEdit("date", e.target.value)}
          />
        </Box>
        <Box flexGrow={1}>
          <InputLabel htmlFor="full-name" sx={MemberEditLabel}>
            Full Name
          </InputLabel>
          <TextField
            id="full-name"
            fullWidth
            placeholder="Please Select"
            value={item.fullName}
            onChange={(e) => handleEdit("name", e.target.value)}
          />
        </Box>
      </Box> */}
      <Button
        variant="contained"
        sx={{ mr: 1.25 }}
        onClick={() => handleSave()}
      >
        Save Changes
      </Button>
      <Button variant="outlined" onClick={() => handleCancel("edit", false)}>
        Cancel
      </Button>
    </Box>
  );
};
export default MemberEdit;
