import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { SmallLogo } from "../Assets/Logo";
import {
  dashboardHeaderStyle,
  dashboardTabContainer,
  dashboardTabHeaderContainer,
  dashboardTitleContainerStyle,
} from "./dashboard.style";
import {
  StyledTabs,
  StyledTab,
  MainTabPanel,
  a11yProps,
} from "../Common/UI/TabPanel";
import DashboardTab from "./DashboardTab";
import InvitationTab from "./InvitationTab";
import StudiesTab from "./StudiesTab";
import SitesTab from "./SitesTab";
import AppointmentsTab from "./AppointmentsTab";
import AddIcon from "@mui/icons-material/Add";
import LogoutContainer from "../Common/UI/LogoutContainer";

const Dashboard = () => {
  const [value, setValue] = useState<string>(
    sessionStorage.getItem("dashboard-tabs") || "dashboard"
  );
  const [openSiteModal, setSiteModal] = useState<boolean>(false);
  const [openStudyModal, setStudyModal] = useState<boolean>(false);

  const handleSiteModalVisibility = (visible: boolean) => {
    setSiteModal(visible);
  };

  const handleStudyModalVisibility = (visible: boolean) => {
    setStudyModal(visible);
  };

  const handleChange = (_: any, newValue: string) => {
    sessionStorage.setItem("dashboard-tabs", newValue);
    setValue(newValue);
  };

  const ButtonInTabs = () => {
    return (
      <Box ml="auto" alignSelf="center">
        {value === "studies" && (
          <Button
            variant="contained"
            onClick={() => handleStudyModalVisibility(true)}
            startIcon={<AddIcon />}
          >
            Add Study
          </Button>
        )}
        {value === "sites" && (
          <Button
            variant="contained"
            onClick={() => handleSiteModalVisibility(true)}
            startIcon={<AddIcon />}
          >
            Add Site
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box sx={dashboardHeaderStyle}>
        <SmallLogo />
        <span style={{ marginLeft: "auto" }}></span>
        <LogoutContainer />
      </Box>
      <Box sx={dashboardTitleContainerStyle}>
        <Typography variant="subtitle2" fontWeight="medium">
          Econsent Dashboard
        </Typography>
      </Box>
      <Box sx={dashboardTabHeaderContainer}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          sx={{
            pl: 3,
            pr: 5,
          }}
        >
          <StyledTab label="Dashboard" value="dashboard" {...a11yProps(0)} />
          <StyledTab label="Studies" value="studies" {...a11yProps(1)} />
          <StyledTab label="Invitations" value="invitation" {...a11yProps(2)} />
          <StyledTab label="Sites" value="sites" {...a11yProps(3)} />
          <StyledTab
            label="Appointments"
            value="appointments"
            {...a11yProps(4)}
          />
          <ButtonInTabs />
        </StyledTabs>
      </Box>
      <Box sx={dashboardTabContainer}>
        <MainTabPanel value={value} index={"dashboard"}>
          <DashboardTab />
        </MainTabPanel>
        <MainTabPanel value={value} index={"studies"}>
          <StudiesTab
            openAddModal={openStudyModal}
            handleModalVisibility={handleStudyModalVisibility}
          />
        </MainTabPanel>
        <MainTabPanel value={value} index={"invitation"}>
          <InvitationTab />
        </MainTabPanel>
        <MainTabPanel value={value} index={"sites"}>
          <SitesTab
            openAddModal={openSiteModal}
            handleModalVisibility={handleSiteModalVisibility}
          />
        </MainTabPanel>
        <MainTabPanel value={value} index={"appointments"}>
          <AppointmentsTab />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default Dashboard;
