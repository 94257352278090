import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { ModalBaseStyles, buttonLoaderSx } from "../Common/styles/modal";
import { CloseButton, DividerWithBG } from "../Common/UI/CommonModalDesign";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { useEffect, useState } from "react";
import { updatedInputLabelStyle } from "./dashboard.style";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  AddSiteModalProps,
  SiteDataType,
  SiteBodyDataType,
} from "./dashboard.type";

const yupSchema = Yup.object({
  name: Yup.string().required("Site name is required"),
  abbreviation: Yup.string().required("Abbreviation is Required"),
  addressLine1: Yup.string().required("Address is Required"),
  country: Yup.string().required("Country is Required"),
});

function AddSiteModal(props: AddSiteModalProps) {
  const { open, closeModal, id, refreshPage } = props;
  const [siteData, setSiteData] = useState<SiteDataType>({
    name: "",
    abbreviation: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const handleAddSites = async (values: SiteDataType) => {
    try {
      setSubmitLoader(true);
      const {
        name,
        abbreviation,
        addressLine1,
        addressLine2,
        zipcode,
        state,
        city,
        country,
      } = values;
      const body: SiteBodyDataType = {
        name: name,
        abbreviation: abbreviation,
        country: country,
        address: {
          addressLine1: addressLine1,
          zipcode: zipcode,
          city: city,
          addressLine2: addressLine2,
          state: state,
          country: country,
        },
      };
      let res;
      if (id) {
        res = await http.patch(`/sites/${id}`, body);
      } else {
        body.orgId = "80c9c35a-4e36-442f-acd3-6d4efb9691af";
        res = await http.post("/sites", body);
      }
      toastMessage("success", res.data?.message);
      closeModal();
      refreshPage();
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const handleFetchSiteData = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(`/sites/${id}`);
        setSiteData({
          ...res.data.data,
          addressLine1: res.data.data?.address?.addressLine1,
          addressLine2: res.data.data?.address?.addressLine2,
          city: res.data.data?.address?.city,
          state: res.data.data?.address?.state,
          zipcode: res.data.data?.address?.zipcode,
          country: res.data.data?.address?.country,
        });
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
        closeModal();
      }
    };
    if (id) {
      handleFetchSiteData();
    }
  }, [id, setSiteData, closeModal, setIsLoading]);

  const handleZipcodeLookup = async (zipcode: any, setValue: any) => {
    try {
      if (zipcode?.length >= 5) {
        const res: AxiosResponse = await http.get(
          `/geo/search?zipcode=${zipcode}`
        );
        const data = res.data?.data;
        if (data) {
          setValue("city", data?.city || "");
          setValue("country", data?.country || "");
          setValue("state", data?.state || "");
        }
      }
    } catch (err) {
      // errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, p: 4 }}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: siteData.name,
            abbreviation: siteData.abbreviation,
            addressLine1: siteData.addressLine1,
            addressLine2: siteData.addressLine2,
            city: siteData.city,
            zipcode: siteData.zipcode,
            state: siteData.state,
            country: siteData.country,
          }}
          validationSchema={yupSchema}
          onSubmit={(values: SiteDataType) => {
            handleAddSites(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">
                  {id ? "Edit site" : "Add site"}
                </Typography>
                <CloseButton buttonClick={closeModal} />
              </Box>
              <DividerWithBG />
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <InputLabel sx={updatedInputLabelStyle} htmlFor="name">
                    Site name
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.name && formik.errors.name ? true : false
                    }
                    id="name"
                    type="text"
                    {...formik.getFieldProps("name")}
                    helperText={
                      formik.touched?.name && formik.errors?.name
                        ? formik.errors?.name
                        : null
                    }
                    placeholder="Enter"
                    variant="outlined"
                  />

                  <InputLabel
                    sx={updatedInputLabelStyle}
                    htmlFor="abbreviation"
                  >
                    Abbreviation
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.abbreviation && formik.errors.abbreviation
                        ? true
                        : false
                    }
                    id="abbreviation"
                    type="text"
                    {...formik.getFieldProps("abbreviation")}
                    helperText={
                      formik.touched?.abbreviation &&
                      formik.errors?.abbreviation
                        ? formik.errors?.abbreviation
                        : null
                    }
                    placeholder="Enter"
                    variant="outlined"
                  />
                  <InputLabel
                    sx={updatedInputLabelStyle}
                    htmlFor="addressLine1"
                  >
                    Address Line 1
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.addressLine1 && formik.errors.addressLine1
                        ? true
                        : false
                    }
                    id="addressLine1"
                    type="text"
                    {...formik.getFieldProps("addressLine1")}
                    helperText={
                      formik.touched?.addressLine1 &&
                      formik.errors?.addressLine1
                        ? formik.errors?.addressLine1
                        : null
                    }
                    placeholder="Enter Address"
                    variant="outlined"
                  />
                  <InputLabel
                    sx={updatedInputLabelStyle}
                    htmlFor="addressLine2"
                  >
                    Address Line 2
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.addressLine2 && formik.errors.addressLine2
                        ? true
                        : false
                    }
                    id="addressLine2"
                    type="text"
                    {...formik.getFieldProps("addressLine2")}
                    helperText={
                      formik.touched?.addressLine2 &&
                      formik.errors?.addressLine2
                        ? formik.errors?.addressLine2
                        : null
                    }
                    placeholder="Enter Address"
                    variant="outlined"
                  />
                  <InputLabel sx={updatedInputLabelStyle} htmlFor="zipcode">
                    Zipcode
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.zipcode && formik.errors.zipcode
                        ? true
                        : false
                    }
                    id="zipcode"
                    type="text"
                    {...formik.getFieldProps("zipcode")}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      handleZipcodeLookup(
                        formik.values?.zipcode,
                        formik.setFieldValue
                      );
                    }}
                    helperText={
                      formik.touched?.zipcode && formik.errors?.zipcode
                        ? formik.errors?.zipcode
                        : null
                    }
                    placeholder="Enter Zipcode"
                    variant="outlined"
                  />
                  <InputLabel sx={updatedInputLabelStyle} htmlFor="city">
                    City
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.city && formik.errors.city ? true : false
                    }
                    id="city"
                    type="text"
                    {...formik.getFieldProps("city")}
                    helperText={
                      formik.touched?.city && formik.errors?.city
                        ? formik.errors?.city
                        : null
                    }
                    placeholder="Enter City"
                    variant="outlined"
                  />
                  <InputLabel sx={updatedInputLabelStyle} htmlFor="state">
                    State
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.state && formik.errors.state
                        ? true
                        : false
                    }
                    id="state"
                    type="text"
                    {...formik.getFieldProps("state")}
                    helperText={
                      formik.touched?.state && formik.errors?.state
                        ? formik.errors?.state
                        : null
                    }
                    placeholder="Enter state"
                    variant="outlined"
                  />

                  <InputLabel sx={updatedInputLabelStyle} htmlFor="country">
                    Country
                  </InputLabel>
                  <TextField
                    fullWidth
                    error={
                      formik.touched?.country && formik.errors?.country
                        ? true
                        : false
                    }
                    id="country"
                    type="text"
                    {...formik.getFieldProps("country")}
                    helperText={
                      formik.touched?.country && formik.errors?.country
                        ? formik.errors?.country
                        : null
                    }
                    select
                    placeholder="Please Enter"
                    variant="outlined"
                  >
                    {[
                      "India",
                      "USA",
                      "United Kingdom",
                      "Australia",
                      "Canada",
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box
                    sx={{
                      mt: 7.5,
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1.5,
                    }}
                  >
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                    {submitLoader ? (
                      <Box sx={buttonLoaderSx}>
                        <CircularProgress size={25} />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        {id ? "Edit site" : "Add site"}
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default AddSiteModal;
