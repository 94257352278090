import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
  Button,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import {
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../../Common/styles/table";
import { SiteListType } from "../../../Dashboard/dashboard.type";
import LinkStudyModal from "./LinkStudyModal";

const SitesTab = ({ id }: any) => {
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openLinkModal, setOpenLinkModal] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sitesList, setSitesList] = useState<SiteListType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleLinkModalVisibility = (visible: boolean) => {
    setOpenLinkModal(visible);
  };

  const handleSort = (order: string, column: string) => {
    setSortOrder(order);
    setSortColumn(column);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setIsLoading(true);
        let url = `/studies/${id}/sites?pageNo=${
          page + 1
        }&pageSize=${pageSize}`;
        if (sortColumn && sortOrder) {
          url += `&sortBy=${sortColumn}&orderBy=${sortOrder}`;
        }
        const res: AxiosResponse = await http.get(url);
        const newSites = res.data?.data?.map((data: any) => {
          return {
            id: data?.id,
            name: data?.site?.name,
            abbreviation: data?.site?.abbreviation,
            country: data?.site?.country,
          };
        });
        setSitesList(newSites);
        setTotal(res.data?.meta?.paginationOptions?.totalItems || 0);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchSites();
  }, [page, sortColumn, sortOrder, setTotal, setSitesList, toggleLoader, id]);

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  const unlinkSite = async (pivotId: string) => {
    try {
      setIsLoading(true);
      const res: AxiosResponse = await http.delete(
        `/studies/${id}/sites/${pivotId}`
      );
      toastMessage("success", res.data.message);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      errorToastMessage(err as Error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          mb: 2.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleLinkModalVisibility(true)}
        >
          Link to Study
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Name
              <SortLabel column="name" />
            </StyledTableCell>
            <StyledTableCell>
              Abbreviation <SortLabel column="abbreviation" />
            </StyledTableCell>
            <StyledTableCell>
              Country <SortLabel column="country" />
            </StyledTableCell>
            <StyledTableCell>Options</StyledTableCell>
          </TableRow>
        </TableHead>
        {!isLoading && (
          <>
            <TableBody>
              {sitesList &&
                sitesList?.map((row: SiteListType) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell>{row?.name}</StyledTableCell>
                    <StyledTableCell>{row?.abbreviation}</StyledTableCell>
                    <StyledTableCell>{row?.country}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        component="span"
                        sx={{
                          textDecoration: "underline",
                          textUnderlineOffset: "4.5px",
                          cursor: "pointer",
                        }}
                        onClick={() => unlinkSite(row.id)}
                      >
                        Unlink
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              {total > pageSize && (
                <TableRow>
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={total}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                </TableRow>
              )}
            </TableFooter>
          </>
        )}
      </Table>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {openLinkModal && (
        <LinkStudyModal
          open={openLinkModal}
          id={id}
          handleModal={handleLinkModalVisibility}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default SitesTab;
