import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserAuth } from "../../../Redux/reducers/userSlice";

const LogoutContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const openMenuItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const logout = () => {
    handleDialogClose();
    dispatch(setUserAuth({ authenticated: false }));
    localStorage.removeItem("econsent-accessToken");
    localStorage.removeItem("econsent-refresh-token");
    localStorage.removeItem("userId");
    // navigate("/auth/login");
  };

  return (
    <div>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to logout?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={logout} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={openMenuItem} size="small">
        <Avatar alt="User" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            openDialog();
          }}
        >
          logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LogoutContainer;
