import { Box, Checkbox, Typography } from "@mui/material";

export default function CheckBox({ data }: any) {
  return (
    <Box>
      {data?.map((item: any) => {
        return (
          <Box
            key={item.id}
            sx={{ display: "flex", alignItems: "center" }}
            title={item.label || ""}
          >
            <Checkbox checked={item.isChecked} />
            <Typography>{item.statement}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}
