import { Box, Button, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { HeaderStyle } from "../consentForm.style";
import { useNavigate } from "react-router-dom";

const CBHeader = ({
  openModal,
  handlePreviewChange,
  showPreview,
  value,
}: any) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={HeaderStyle}>
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleBack}>
          <ChevronLeftIcon fontSize="large" sx={{ color: "text.tertiary" }} />
        </IconButton>
        <Typography
          variant="subtitle1"
          fontWeight="medium"
          color="text.tertiary"
        >
          Consent form | 1.0
        </Typography>
      </Box>
      <Box sx={{ marginLeft: "auto" }}>
        {/* <Button variant="outlined" sx={{ mr: 2 }} onClick={openModal}>
          Publish
        </Button> */}
        {/* <Button variant="outlined" sx={{ mr: 3.25 }}>
          Download
        </Button> */}
        {value === "content" && (
          <Button
            variant="text"
            startIcon={<VisibilityIcon />}
            onClick={handlePreviewChange}
          >
            Preview
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CBHeader;
