import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  scoreValidationHeaderStyle,
  scoreVItemActionStyle,
} from "./scores.style";

type Props = {
  updateScore: Function;
  handleRemoveScore: Function;
  scoreEval: any[];
};

const ScoreValidation = (props: Props) => {
  const addScore = () => {
    props.updateScore(0, "ADD", "", "");
  };

  const handleChange = (val: any, key: string, idx: number) => {
    props.updateScore(idx, "CHANGE", key, val);
  };

  const removeScore = (index: any) => {
    props.handleRemoveScore(index);
  };

  return (
    <Box sx={{ background: "#ffffff" }}>
      <Box sx={scoreValidationHeaderStyle}>
        <Typography typography="subtitle2" fontWeight="medium">
          Score
        </Typography>
        <IconButton onClick={() => addScore()}>
          <Add sx={{ color: "text.tertiary" }} />
        </IconButton>
      </Box>
      {props.scoreEval.map((_: any, index: any) => {
        return (
          <Box key={`${index}scoreEval`}>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Typography fontWeight="medium" sx={{ flexShrink: 0 }}>
                If X
              </Typography>
              <Select
                sx={{ mx: 1, width: "250px" }}
                value={props.scoreEval[index].condition}
                onChange={(e) =>
                  handleChange(e.target.value, "condition", index)
                }
              >
                <MenuItem value="gt" key="gt">
                  Greater than
                </MenuItem>
                <MenuItem value="gte" key="gte">
                  Greater than or equal to
                </MenuItem>
                <MenuItem value="lt" key="lt">
                  Less than
                </MenuItem>
                <MenuItem value="lte" key="lte">
                  Less than or equal to
                </MenuItem>
                <MenuItem value="between" key="between">
                  Between
                </MenuItem>
                <MenuItem value="eq" key="eq">
                  Equal to
                </MenuItem>
                {/* <Select.Option value="not_eq">Not equal to</Select.Option> */}
              </Select>

              {props.scoreEval[index].condition !== "between" ? (
                <>
                  {(props.scoreEval[index].condition === "gt" ||
                    props.scoreEval[index].condition === "gte" ||
                    props.scoreEval[index].condition === "eq" ||
                    props.scoreEval[index].condition === "not_eq") && (
                    <TextField
                      name="minValue"
                      type="number"
                      onChange={(e) =>
                        handleChange(Number(e.target.value), "minValue", index)
                      }
                      value={
                        props.scoreEval[index].minValue === 0
                          ? ""
                          : props.scoreEval[index].minValue
                      }
                    />
                  )}

                  {/* max value */}
                  {(props.scoreEval[index].condition === "lt" ||
                    props.scoreEval[index].condition === "lte") && (
                    <TextField
                      name="maxValue"
                      type="number"
                      onChange={(e) =>
                        handleChange(Number(e.target.value), "maxValue", index)
                      }
                      value={
                        props.scoreEval[index].maxValue === 0
                          ? ""
                          : props.scoreEval[index].maxValue
                      }
                    />
                  )}
                </>
              ) : (
                <>
                  <TextField
                    name="minValue"
                    type="number"
                    onChange={(e) =>
                      handleChange(Number(e.target.value), "minValue", index)
                    }
                    value={
                      props.scoreEval[index].minValue === 0
                        ? ""
                        : props.scoreEval[index].minValue
                    }
                  />
                  <Typography sx={{ mx: 0.5 }} fontWeight="medium">
                    and
                  </Typography>
                  <TextField
                    name="maxValue"
                    type="number"
                    onChange={(e) =>
                      handleChange(Number(e.target.value), "maxValue", index)
                    }
                    value={
                      props.scoreEval[index].maxValue === 0
                        ? ""
                        : props.scoreEval[index].maxValue
                    }
                  />
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Typography sx={{ mr: 2 }} fontWeight="medium">
                then score
              </Typography>
              <TextField
                name="score"
                type="number"
                onChange={(e) =>
                  handleChange(
                    e.target.value !== "" ? Number(e.target.value) : "",
                    "score",
                    index
                  )
                }
                value={props.scoreEval[index].score}
              />
            </Box>
            <Box sx={scoreVItemActionStyle}>
              <IconButton onClick={() => addScore()} sx={{ mr: 1 }}>
                <Add sx={{ color: "text.tertiary" }} />
              </IconButton>
              <IconButton onClick={() => removeScore(index)}>
                <Remove sx={{ color: "text.tertiary" }} />
              </IconButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ScoreValidation;
