import { Box, Skeleton, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  ChartCard,
  ChartCenterText,
  ChartContainer,
  dashboardCardContainer,
  DoughnutContainer,
  noItemsStyles,
} from "./dashboard.style";
import CircleIcon from "@mui/icons-material/Circle";
import DbCard from "./DbCard";
import { errorToastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";

const colors = ["#16BDCA", "#FACA15", "#FDBA8C", "#1C64F2", "#0E9F6E"];

const options: any = {
  maintainAspectRatio: false,
  responsive: true,
  cutout: "50%",
  spacing: 0,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(245,245,245,0.8)",
      titleColor: "#333333",
      bodyColor: "#333333",
      usePointStyle: true,
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
      yAlign: "bottom",
      xAlign: "center",
    },
  },
};

ChartJS.register(ArcElement, Tooltip);

const DashboardTab = () => {
  const [cardData, setCardData] = useState<any>([]);
  const [topStudies, setTopStudies] = useState<any>([]);
  const [studyTotal, setStudyTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchDashboardMetrics = async () => {
      try {
        setIsLoading(true);
        const orgId = "80c9c35a-4e36-442f-acd3-6d4efb9691af";
        const res: AxiosResponse = await http.get(
          `/organisations/${orgId}/dashboard`
        );
        const data = res.data?.data;
        const newMetrics = [
          {
            id: 1,
            title: "Sites",
            count: data?.sites,
          },
          {
            id: 2,
            title: "Users",
            count: data?.subjects,
          },
          {
            id: 3,
            title: "Live/ Total Studies",
            count: `${data?.liveStudies}/${data?.totalStudies}`,
          },
          {
            id: 6,
            title: "Invitations Pending",
            count: data?.pendingInvitations,
          },
        ];
        setCardData(newMetrics);
        setTopStudies([...data?.topStudies]);
        let total = 0;
        data?.topStudies?.forEach((entry: any) => {
          total += entry?.total;
        });
        setStudyTotal(total);
        setIsLoading(false);
        setError(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
        setError(true);
      }
    };

    fetchDashboardMetrics();
  }, [setCardData, setIsLoading, setTopStudies, setStudyTotal, setError]);

  const chartData: any = useMemo(() => {
    return {
      labels: topStudies?.map((entry: any) => entry?.name),
      datasets: [
        {
          data: topStudies?.map((entry: any) => entry?.total),
          backgroundColor: colors,
          hoverOffset: 4,
          borderWidth: 0,
        },
      ],
    };
  }, [topStudies]);

  return isLoading ? (
    <>
      <Box sx={dashboardCardContainer}>
        {[1, 2, 3, 4].map((item: number) => {
          return (
            <Skeleton
              key={item}
              variant="rounded"
              height="144px"
              animation="wave"
              sx={{
                borderRadius: "12px",
              }}
            />
          );
        })}
      </Box>
      <Skeleton
        variant="rounded"
        height="316px"
        animation="wave"
        sx={{
          borderRadius: "12px",
          maxWidth: "50%",
        }}
      />
    </>
  ) : error ? (
    <Typography variant="h2" color="text.lightText">
      Something went wrong !
    </Typography>
  ) : (
    <>
      <Box sx={dashboardCardContainer}>
        {cardData.map((item: any) => {
          const { id, title, count } = item;
          return <DbCard key={id} title={title} count={count} />;
        })}
      </Box>
      <Box sx={ChartCard}>
        <Typography
          variant="subtitle1"
          fontWeight="medium"
          color="#6B7280"
          sx={{ mb: 5 }}
        >
          Studies
        </Typography>
        {studyTotal > 0 ? (
          <Box sx={ChartContainer}>
            <Box sx={DoughnutContainer}>
              <Doughnut data={chartData} options={options} />
              <Box sx={ChartCenterText}>
                <Typography
                  fontSize="12px"
                  fontWeight="light"
                  color="text.secondary"
                >
                  Subjects
                </Typography>
                <Typography variant="h2" color="text.secondary">
                  {studyTotal}
                </Typography>
              </Box>
            </Box>
            <Box flex={1}>
              {topStudies.map((item: any, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center", gap: 1.25 }}
                  >
                    <CircleIcon
                      htmlColor={colors[index]}
                      sx={{ fontSize: "8px" }}
                    />
                    <Typography variant="subtitle2" color="text.secondary">
                      {item?.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Box sx={noItemsStyles}>
            <Typography variant="h2" color="text.grey">
              No Study Data
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DashboardTab;
