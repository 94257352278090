import {
  styled,
  SwitchProps,
  Switch,
  SxProps,
  Button,
  TextField,
} from "@mui/material";
import { colFlexStyle } from "../../Common/styles/flex";

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "40px",
  height: "24.5px",
  padding: 1.5,
  marginRight: "20px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const SwitchContainer: SxProps = {
  ...colFlexStyle,
  gap: 3.75,
};

export const FormLabelStyle: SxProps = {
  m: 0,
  "& .MuiFormControlLabel-label": {
    fontSize: "16px",
    fontWeight: "regular",
  },
};

export const CardLg = styled("div")(() => ({
  background: "#ffffff",
  maxWidth: "879px",
  borderRadius: "8px",
  padding: "20px 20px 30px 20px",
  boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.1)",
  marginBottom: "20px",
}));

export const CardHeader = styled("div")(({ theme }) => ({
  height: "43px",
  borderBottom: "1px solid",
  borderColor: theme.palette.border?.type1,
  marginBottom: "20px",
}));

export const FileButton = styled(Button)(() => ({
  borderRadius: "12px",
  textTransform: "none",
  padding: "9px 13px",
  boxShadow: "none",
}));

export const StyledTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "16px",
    height: "46px",
    "& fieldset": {
      border: "1px solid #E5E7EB",
    },
  },
}));

export const StyledTextArea = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "16px",
    "& fieldset": {
      border: "1px solid #E5E7EB",
    },
  },
}));

export const PhoneInputStyle = {
  borderRadius: 12,
  height: "46px",
  fontSize: "16px",
  width: "100%",
};

export const HeaderStyle: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
  height: "82px",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  backgroundColor: "#ffffff",
};

export const ConfigContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

export const InputLabelStyle: SxProps = {
  fontSize: "16px",
  fontWeight: "regular",
  color: "text.tertiary",
  mb: 1,
};

export const LoadingContainer = styled("div")(() => ({
  height: "calc(100vh - 247px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ConfigLabelStyle: SxProps = {
  fontSize: "16px",
  fontWeight: 600,
  mb: 1,
  mt: "30px",
  textOverflow: "visible",
  whiteSpace: "normal",
};
