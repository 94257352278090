import { Card, styled, SxProps } from "@mui/material";

export const HeaderStyle: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  px: 2.5,
  height: "82px",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  backgroundColor: "#ffffff",
};

export const QuestionContainer: SxProps = {
  height: "calc(100% - 146px)",
  overflow: "auto",
};

export const CardsContainer = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill,minmax(250px,1fr))",
  rowGap: "25px",
  columnGap: "25px",
  padding: "20px",
};

export const StyledCard = styled(Card)(() => ({
  height: "326px",
  backgroundColor: "#fff",
  border: "1px solid rgba(33, 33, 33, 0.13)",
  borderRadius: "6px",
  cursor: "pointer",
}));

export const CardContentStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "18px 16px",
  height: "109px",
};
