import { Box, Typography } from "@mui/material";
import { CardStyle } from "./dashboard.style";

interface Props {
  title: string;
  count: number | string;
}

const DbCard = ({ title, count }: Props) => {
  return (
    <Box sx={CardStyle}>
      <Typography
        variant="subtitle1"
        fontWeight="medium"
        color="text.grey"
        textTransform="capitalize"
      >
        {title}
      </Typography>
      <Typography
        fontWeight="bold"
        sx={{ fontSize: "32px" }}
        color="background.econsentBg"
      >
        {count}
      </Typography>
    </Box>
  );
};

export default DbCard;
