import { Box, InputLabel, TextField, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import ReactQuill from "react-quill";
import SignatureBox from "./SignatureBox";

export const SignatureItem: React.FC<any> = ({ item }) => {
  return (
    <Box mt={5}>
      <Typography color="text.tertiary" mb={1} variant="h2" fontWeight="light">
        {item.header}
      </Typography>
      <ReactQuill
        readOnly={true}
        theme={"bubble"}
        className={"quill-container quill-bubble-md"}
        defaultValue={item.statement}
      />
      <Box sx={{ width: "70%" }}>
        <Typography variant="body1" fontWeight="medium" mt={2.5}>
          Date
        </Typography>
        <Typography variant="body1" fontWeight="regular" color="text.lightText">
          {moment().format("D MMM YYYY")}
        </Typography>
        <InputLabel
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            mt: "30px",
            mb: "7px",
          }}
          htmlFor="fullName"
        >
          Full name
        </InputLabel>
        <TextField
          fullWidth
          // disabled
          // value={item.name}
          id="fullName"
          type="text"
          variant="outlined"
        />
        <Typography mt={4} mb={1} variant="body1" fontWeight="medium">
          SIGNATURE
        </Typography>
        <SignatureBox />
      </Box>
    </Box>
  );
};
export default function MemberSignature({ data }: any) {
  return (
    <Box>
      {data?.map((item: any) => {
        return <SignatureItem key={item.id} item={item} />;
      })}
    </Box>
  );
}
