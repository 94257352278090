import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  InputLabel,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  addCheckbox,
  addCheckboxData,
  deleteCheckbox,
  toggleCheckbox,
} from "../../../../../Redux/reducers/consentSlice";
import { LabelStyle } from "../../consentForm.style";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const CheckboxComp = () => {
  const dispatch = useAppDispatch();
  const { checkbox } = useAppSelector((state) => state.consent);

  const handleAddCheckbox = () => {
    dispatch(addCheckbox());
  };

  const handleDeleteCheckbox = (id: string) => {
    dispatch(deleteCheckbox(id));
  };

  const handleInput = (index: number, key: string, value: string) => {
    dispatch(addCheckboxData({ index, key, value }));
  };

  const handleToggleCheckbox = (index: number, isChecked: boolean) => {
    dispatch(toggleCheckbox({ index, isChecked }));
  };

  return (
    <>
      <Typography
        variant="h3"
        color="text.tertiary"
        fontWeight="medium"
        mb={1.25}
      >
        Checkboxes
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.primary"
        lineHeight="19px"
        mb={3.75}
      >
        Add Checkboxes to the end of your form using the options below. You can
        only add a label to optional checkboxes.
      </Typography>
      {checkbox.map((item: any, index: number) => {
        return (
          <Box display="flex" gap="60px" alignItems="flex-start" key={index}>
            <Checkbox
              checked={item.isChecked}
              onChange={(e) => handleToggleCheckbox(index, e.target.checked)}
              color="primary"
              sx={{
                mt: 3,
              }}
            />
            <Box flexGrow={2}>
              <InputLabel htmlFor="checkboxInput" sx={LabelStyle}>
                Label
              </InputLabel>
              <Box display="flex" mb={3.75}>
                <TextField
                  fullWidth
                  id="checkboxInput"
                  placeholder="Enter"
                  sx={{
                    maxWidth: "500px",
                  }}
                  value={item.label}
                  onChange={(e) => handleInput(index, "label", e.target.value)}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: "#D9D9D9",
                    margin: "0 20px 0 30px",
                  }}
                />
                <DeleteIcon
                  sx={{ color: "#F05252", cursor: "pointer" }}
                  onClick={() => handleDeleteCheckbox(item.id)}
                />
              </Box>
              <InputLabel htmlFor="checkbox-statement" sx={LabelStyle}>
                Checkbox statement
              </InputLabel>{" "}
              <TextField
                rows="3"
                multiline
                fullWidth
                id="checkbox-statement"
                placeholder="Enter"
                sx={{
                  maxWidth: "500px",
                  marginBottom: "30px",
                }}
                value={item.statement}
                onChange={(e) =>
                  handleInput(index, "statement", e.target.value)
                }
              />
              {index + 1 < checkbox.length && (
                <Divider
                  sx={{
                    borderColor: "rgba(0,0,0,0.1)",
                    marginBottom: 3.75,
                    maxWidth: "500px",
                  }}
                />
              )}
            </Box>
          </Box>
        );
      })}
      <Button
        startIcon={<AddIcon />}
        variant="outlined"
        onClick={handleAddCheckbox}
      >
        Add Checkbox
      </Button>
    </>
  );
};

export default CheckboxComp;
