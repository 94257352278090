export const CTData = [
  {
    id: 1,
    form: "Diabetes, and Nutrition",
    version: "1.0",
    consent: true,
    sentDate: "30 Jan 2022 09:40 AM",
    consentDate: "30 Jan 2022 10:30 AM",
    status: "locked",
  },
];
