import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { EndCallIcon } from "./videoasset";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const EndCall = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const onOk = async () => {
    setOpen(false);
    navigate(-1);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to end the call?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={onOk} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="error"
        sx={{ ml: 1.5 }}
        onClick={openDialog}
        startIcon={<EndCallIcon />}
      >
        End Call
      </Button>
    </>
  );
};

export default EndCall;
