import { useState } from "react";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { HeaderContainer, StyledTextField } from "./subjects.style";

const HeaderBar = ({ setOpen }: { setOpen: () => void }) => {
  const [searchText, setSearchText] = useState("");

  return (
    <HeaderContainer>
      <Typography
        variant="subtitle2"
        fontWeight="medium"
        color="text.tertiary"
        mr={5}
      >
        Subjects
      </Typography>
      <StyledTextField
        placeholder="Search for Subjects"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {/* <Button onClick={setOpen} variant="contained" startIcon={<AddIcon />}>
        Add Subject
      </Button> */}
    </HeaderContainer>
  );
};

export default HeaderBar;
