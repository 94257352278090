import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  inputLabelStyle,
  afterInputTextStyle,
  grayBox,
  inputContainer,
  link,
  iconStyle,
} from "./style/SharedStyle";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate, useSearchParams } from "react-router-dom";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { getDefaultTimezone, timezones } from "../../utils/tz";

const yupSchema = Yup.object({
  firstName: Yup.string().required("First Name is Required"),
  lastName: Yup.string().required("Last Name is Required"),
  timezone: Yup.string().required("Timezone is Required"),
  password: Yup.string()
    .required("Password is required")
    // .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/),
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    ),
});

const ValidationMarks = ({ password }: { password: string }) => {
  const [passwordValidation, setPasswordValidation] = useState({
    upperCase: true,
    numeric: true,
    lowerCase: true,
    charReached: true,
  });

  useEffect(() => {
    const upperCaseRegex = /[A-Z]/;
    const numericRegex = /[0-9]/;
    const lowercaseRegex = /[a-z]/;

    let upperCase = true;
    let numeric = true;
    let lowerCase = true;
    let charReached = true;

    if (password.length >= 8) {
      charReached = false;
    }
    if (upperCaseRegex.test(password)) {
      upperCase = false;
    }
    if (numericRegex.test(password)) {
      numeric = false;
    }
    if (lowercaseRegex.test(password)) {
      lowerCase = false;
    }

    setPasswordValidation({
      upperCase: upperCase,
      numeric: numeric,
      lowerCase: lowerCase,
      charReached: charReached,
    });
  }, [password]);

  return (
    <Box mt={"16px"}>
      <Typography sx={afterInputTextStyle}>
        {passwordValidation.upperCase ? (
          <CloseIcon sx={iconStyle} />
        ) : (
          <DoneIcon sx={iconStyle} color="success" />
        )}{" "}
        1 uppercase character
      </Typography>
      <Typography sx={afterInputTextStyle}>
        {passwordValidation.numeric ? (
          <CloseIcon sx={iconStyle} />
        ) : (
          <DoneIcon sx={iconStyle} color="success" />
        )}{" "}
        1 numeric character
      </Typography>
      <Typography sx={afterInputTextStyle}>
        {passwordValidation.lowerCase ? (
          <CloseIcon sx={iconStyle} />
        ) : (
          <DoneIcon sx={iconStyle} color="success" />
        )}{" "}
        1 lowercase character
      </Typography>
      <Typography sx={afterInputTextStyle}>
        {passwordValidation.charReached ? (
          <CloseIcon sx={iconStyle} />
        ) : (
          <DoneIcon sx={iconStyle} color="success" />
        )}{" "}
        8 characters
      </Typography>
    </Box>
  );
};

function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (!(email && token)) {
      navigate("../login");
    }
  }, [email, token]);

  const submitHandler = async (values: any) => {
    try {
      setLoading(true);
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        token: token,
        timezone: values.timezone,
      };
      const res = await http.post("/auth/signup", body);
      toastMessage("success", res?.data?.message);
      navigate("../login");
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={grayBox}></Box>
      <Box sx={inputContainer} pt={15} pb={6} pl={9} pr={9}>
        <Typography variant="h1">Welcome to eConsent</Typography>
        <Typography
          fontWeight="regular"
          variant="subtitle2"
          sx={{
            opacity: 0.6,
          }}
        >
          To get started, please sign up
        </Typography>

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            password: "",
            timezone: getDefaultTimezone() || "",
          }}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "40px",
                  marginTop: "50px",
                }}
              >
                <Box>
                  <InputLabel sx={inputLabelStyle} htmlFor="firstName">
                    First Name
                  </InputLabel>
                  <TextField
                    error={
                      formik.touched.firstName && formik.errors.firstName
                        ? true
                        : false
                    }
                    id="firstName"
                    type="text"
                    {...formik.getFieldProps("firstName")}
                    variant="standard"
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : null
                    }
                  />
                </Box>
                <Box>
                  <InputLabel sx={inputLabelStyle} htmlFor="lastName">
                    Last Name
                  </InputLabel>
                  <TextField
                    error={
                      formik.touched.lastName && formik.errors.lastName
                        ? true
                        : false
                    }
                    id="lastName"
                    type="text"
                    {...formik.getFieldProps("lastName")}
                    variant="standard"
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : null
                    }
                  />
                </Box>
              </Box>

              <Box mt={6} mb={6}>
                <InputLabel sx={inputLabelStyle} htmlFor="email">
                  Email
                </InputLabel>
                <Typography mt={2} variant="h2" fontWeight="light">
                  {email}
                </Typography>
              </Box>

              <Box mt={6} mb={6}>
                <InputLabel sx={inputLabelStyle} htmlFor="timezone">
                  Timezone
                </InputLabel>
                <Select
                  variant="standard"
                  id="demo-simple-select"
                  fullWidth
                  value={formik.values.timezone}
                  label="Select time zone"
                  onChange={(e) =>
                    formik.setFieldValue("timezone", e.target.value)
                  }
                  sx={{
                    mt: 2,
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  displayEmpty
                  error={
                    formik.touched.timezone && formik.errors.timezone
                      ? true
                      : false
                  }
                >
                  {timezones.map((tz) => {
                    return (
                      <MenuItem key={tz.label} value={tz.tzCode}>
                        {tz.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formik.touched.timezone && formik.errors.timezone && (
                  <FormHelperText error>
                    {formik.errors.timezone}
                  </FormHelperText>
                )}
              </Box>

              <Box>
                <InputLabel sx={inputLabelStyle} htmlFor="password">
                  Create Password
                </InputLabel>
                <TextField
                  error={
                    formik.touched.password && formik.errors.password
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.password && formik.errors.password
                      ? "Minimum 8 characters with at least 1 number and a special character"
                      : null
                  }
                  {...formik.getFieldProps("password")}
                  id="password"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              </Box>
              <Box mt={"8px"}>
                <Typography sx={afterInputTextStyle}>
                  Please use at least:
                </Typography>
                <ValidationMarks password={formik.values?.password} />
              </Box>
              {loading ? (
                <Box
                  sx={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  size="large"
                  type="submit"
                  sx={{ marginTop: "40px" }}
                  variant="contained"
                  fullWidth
                  disabled={
                    !formik.dirty ||
                    (!formik.errors.password &&
                    !formik.errors.firstName &&
                    !formik.errors.lastName
                      ? false
                      : true)
                  }
                >
                  Sign Up
                </Button>
              )}
              <Typography align="center" mt={"10px"}>
                By signing in you agree to Econsent{" "}
                <Button sx={link}>Terms of service</Button> and{" "}
                <Button sx={link}>Privacy policy.</Button>
              </Typography>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default SignUp;
