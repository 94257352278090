import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
} from "@mui/material";
import {
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { useEffect, useMemo, useState } from "react";
import {
  HeaderContainer,
  StyledTextField,
  UserTableContainer,
} from "./userRole.style";
import AddUserModal from "./AddUserModal";
import AddIcon from "@mui/icons-material/Add";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../../utils/toast";
import { Cancel, CheckCircle } from "@mui/icons-material";
export interface UserRoleListType {
  email: string;
  roleArray: Array<Type>;
  id?: string;
  orgId?: string;
}

export interface Type {
  role: any;
  site: any;
}

const UserRole = () => {
  const { id } = useParams();
  const [loading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalId] = useState<string | null>(null);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);

  const [data, setData] = useState<any[]>([]);
  // const [rowId, setRowId] = useState("");
  const [sortColumn, setSortColumn] = useState<undefined | string>();
  const [sortOrder, setSortOrder] = useState<undefined | string>();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchText, setsearchText] = useState("");

  const handleModalOpen = (id?: any) => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (order: string, column: string) => {
    setSortOrder(order);
    setSortColumn(column);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        let url = `/studies/${id}/users?pageSize=${pageSize}&pageNo=${
          page + 1
        }`;
        if (sortColumn && sortOrder) {
          url += `&sortBy=${sortColumn}&orderBy=${sortOrder}`;
        }
        if (searchText) {
          url += `&q=${searchText}`;
        }
        const res: AxiosResponse = await http.get(url);
        const newCFList = res.data?.data?.map((item: any) => {
          return {
            id: item?.id,
            email: item?.mainUser?.email,
            status: item?.status,
            name:
              item?.mainUser?.firstName && item?.mainUser?.lastName
                ? item?.mainUser?.firstName + " " + item?.mainUser?.lastName
                : "-",
          };
        });
        setData(newCFList);
        setTotal(res.data?.meta?.paginationOptions?.totalItems || 0);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchUserDetails();
  }, [
    page,
    sortColumn,
    sortOrder,
    setTotal,
    setData,
    toggleLoader,
    searchText,
    id,
    setPage,
  ]);

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setsearchText(val);
        setPage(0);
      }, 500),
    [setsearchText, setPage]
  );

  const renderStatus = (status: string) => {
    if (status === "invite_sent") {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CheckCircle sx={{ color: "#FF5A1F", mr: 1 }} />
          Invite Sent
        </Box>
      );
    } else if (status === "rejected") {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Cancel sx={{ color: "#E02424", mr: 1 }} />
          Rejected
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CheckCircle sx={{ color: "#33BC28", mr: 1 }} />
          Accepted
        </Box>
      );
    }
  };

  return (
    <>
      <HeaderContainer>
        <Typography variant="subtitle2" fontWeight="medium" mr={5}>
          User and Roles
        </Typography>
        <StyledTextField
          placeholder="Search for Users"
          onChange={(e) => modifySearchTerm(e.target.value)}
        />
        <span style={{ marginLeft: "auto" }}></span>
        <Button
          onClick={handleModalOpen}
          variant="contained"
          startIcon={<AddIcon />}
          size="large"
        >
          Add User
        </Button>
      </HeaderContainer>
      {modalOpen && (
        <AddUserModal
          open={modalOpen}
          closeModal={handleModalClose}
          id={modalId}
          refreshPage={refreshPage}
        />
      )}
      <Box sx={UserTableContainer}>
        <Box sx={{ padding: "20px" }}>
          <Table>
            <TableHead sx={{ overflow: "scroll" }}>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Role(s)</StyledTableCell>
                <StyledTableCell>Site(s)</StyledTableCell>
                <StyledTableCell>Invite Status</StyledTableCell>
                <StyledTableCell>Options</StyledTableCell>
              </TableRow>
            </TableHead>
            {!loading && (
              <>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.id}>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            border: 1,
                            borderColor: "primary.main",
                            padding: "7px 11px",
                            width: "fit-content",
                            borderRadius: "8px",
                            typography: "body1",
                          }}
                        >
                          Study Admin
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>All</StyledTableCell>
                      <StyledTableCell>
                        {renderStatus(row.status)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          component="span"
                          sx={{
                            cursor: "pointer",
                          }}
                          //   onClick={() => handleModalOpen(row.id)}
                        >
                          Manage
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {total > pageSize && (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        sx={TablePaginationStyle}
                        count={total}
                        page={page}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={paginationLabel}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </>
            )}
          </Table>
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserRole;
