import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { MoreVert } from "@mui/icons-material";

import {
  Box,
  IconButton,
  LinearProgress,
  TablePagination,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  pageSize,
  paginationLabel,
  StyledDataGrid,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import moment from "moment";

const renderInviteStatus = (props: GridRenderCellParams<String>) => {
  const { value } = props;
  return (
    <>
      <FiberManualRecordIcon
        sx={{
          fontSize: "10px",
          color: "#FACA15",
          marginRight: "8px",
        }}
      />
      {value}
    </>
  );
};

const renderEligibilityStatus = (props: GridRenderCellParams<String>) => {
  const { value } = props;
  return (
    <Box
      component="span"
      sx={{
        backgroundColor: value ? "rgba(51, 188, 40, 0.1)" : "#FEECDC",
        color: value ? "#33BC28" : "#FF8A4C",
        padding: "2px 10px",
        borderRadius: "8px",
      }}
    >
      {value ? "Eligible" : "Pending"}
    </Box>
  );
};

const renderConsentStatus = (props: GridRenderCellParams<String>) => {
  const { value } = props;
  return (
    <Box
      component="span"
      sx={{
        backgroundColor: value ? "rgba(51, 188, 40, 0.1)" : "#FEECDC",
        color: value ? "#33BC28" : "#FF8A4C",
        padding: "2px 10px",
        borderRadius: "8px",
      }}
    >
      {value ? "Consented" : "Awaiting consent"}
    </Box>
  );
};

const columns: GridColDef[] = [
  {
    field: "screeningID",
    headerName: "Screening ID",
    cellClassName: "clickable-column",
    valueGetter: ({ value }) => (value ? value : "-"),
    // minWidth: 126,
    flex: 1,
  },
  {
    field: "subID",
    headerName: "Subject ID",
    cellClassName: "clickable-column",
    valueGetter: ({ value }) => (value ? value : "-"),
    flex: 1,
    // minWidth: 126,
  },
  {
    field: "inviteStatus",
    headerName: "Invite status",
    renderCell: renderInviteStatus,
    // minWidth: 145,
    flex: 1,
  },
  {
    field: "eligibilityStatus",
    headerName: "Eligibility status",
    renderCell: renderEligibilityStatus,
    // minWidth: 145,
    flex: 1,
  },
  {
    field: "consentStatus",
    headerName: "Consent status",
    renderCell: renderConsentStatus,
    // minWidth: 190,
    flex: 1,
  },
  {
    field: "inviteDate",
    headerName: "Invite on",
    valueGetter: ({ value }) => value ?? "-",
    // minWidth: 140,
    flex: 1,
  },
  {
    field: "consentDate",
    headerName: "Consented on",
    valueGetter: ({ value }) => value ?? "-",
    // minWidth: 140,
    flex: 1.5,
  },
  {
    field: "ICFversion",
    headerName: "ICF version",
    valueGetter: ({ value }) => value ?? "-",
    // minWidth: 126,
    flex: 1,
  },
  {
    field: "site",
    headerName: "Site",
    valueGetter: ({ value }) => value ?? "-",
    // minWidth: 180,
    flex: 1,
  },
  {
    field: "options",
    headerName: "Options",
    sortable: false,
    renderCell: () => (
      <IconButton>
        <MoreVert />
      </IconButton>
    ),
    align: "center",
    headerAlign: "center",
  },
];

type PaginationProps = {
  page: number;
  total: number;
  changePage: Function;
};

const CustomPagination: React.FC<PaginationProps> = ({
  changePage,
  page,
  total,
}) => {
  const handleChangePage = (_: any, newPage: number) => {
    changePage(newPage);
  };

  if (total < pageSize) {
    return <></>;
  }

  return (
    <TablePagination
      component="div"
      sx={TablePaginationStyle}
      count={total}
      page={page}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[pageSize]}
      onPageChange={handleChangePage}
      labelDisplayedRows={paginationLabel}
    />
  );
};

export default function SubjectTable() {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [subjectData, setSubjectData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;
    if (field === "screeningID" || field === "subID") {
      navigate(`${row?.id}?subjectId=${row?.screeningID}`);
    }
  };

  useEffect(() => {
    const fetchSubjectDetails = async () => {
      try {
        setIsLoading(true);
        let url = `/study-subject/${id}?pageSize=${pageSize}&pageNo=${
          page + 1
        }`;

        const res: AxiosResponse = await http.get(url);
        const newSubList = res.data?.data?.map((item: any) => {
          return {
            id: item?.subjectMainRefId,
            screeningID: item?.screeningId,
            subID: item?.subjectId,
            inviteStatus: item?.status,
            eligibilityStatus: item?.eligibilityStatus,
            inviteDate: "-",
            consentDate: item?.consentedOn
              ? moment(item?.consentedOn).format("DD MMM YYYY hh:mm A")
              : "-",
            consentStatus: item?.isConsented,
          };
        });

        setSubjectData(newSubList);
        setTotal(res.data?.meta?.paginationOptions?.totalItems || 0);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchSubjectDetails();
  }, [page, setTotal, setSubjectData, id, setPage]);

  const changePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <StyledDataGrid
        // sx={{ overflowX: "scroll" }}
        loading={isLoading}
        rows={isLoading ? [] : subjectData}
        getRowId={(row) => row.screeningID}
        paginationMode="server"
        rowCount={total}
        columns={columns}
        pageSize={pageSize}
        page={page}
        rowsPerPageOptions={[pageSize]}
        components={{
          Pagination: CustomPagination,
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          pagination: {
            page: page,
            total: total,
            changePage: changePage,
          },
        }}
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        rowHeight={65}
        headerHeight={46}
        disableColumnMenu
        onCellClick={handleCellClick}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
    </>
  );
}
