import { Box, SxProps, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import http from "../../utils/http";
import { rowFlexStyle } from "../Common/styles/flex";
import LogoutContainer from "../Common/UI/LogoutContainer";
import StudySidebar from "./StudySidebar";

const headerStyle: SxProps = {
  ...rowFlexStyle,
  alignItems: "center",
  height: "64px",
  width: "100%",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  position: "sticky",
  top: 0,
  backgroundColor: "#ffffff",
  px: 2.5,
};

const StudyHeader: React.FC = () => {
  const { id } = useParams();
  const [name, setName] = useState("");

  useEffect(() => {
    const fetchName = async () => {
      try {
        const res = await http.get(`/studies/${id}`);
        setName(res.data.data?.name);
      } catch (err) {}
    };
    fetchName();
  }, [setName, id]);

  return (
    <Box sx={headerStyle}>
      <Typography variant="subtitle2" fontWeight="bold">
        {name}
      </Typography>
      <span style={{ marginLeft: "auto" }}></span>
      <LogoutContainer />
    </Box>
  );
};

const StudyLayout: React.FC = () => {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <StudySidebar />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <StudyHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default StudyLayout;
