import React, { useEffect, useRef, useState } from "react";
import OT, { Publisher, Session } from "@opentok/client";
import {
  AudioOffIcon,
  AudioOnIcon,
  VideoOffIcon,
  VideoOnIcon,
} from "./videoasset";
import { errorToastMessage } from "../../../utils/toast";
import { Box, Button } from "@mui/material";
import { buttonContainer } from "./signing.style";
import EndCall from "./EndCall";

const PublisherComp: React.FC<any> = ({
  session,
  creds,
}: {
  session: Session;
  creds: any;
}) => {
  const publishRef = useRef<Publisher | any>(null);

  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);

  const toggleAudio = () => {
    if (publishRef.current) {
      (publishRef.current as Publisher).publishAudio(!audio);
      setAudio((prev) => !prev);
    }
  };

  const toggleVideo = () => {
    if (publishRef.current) {
      (publishRef.current as Publisher).publishVideo(!video);
      setVideo((prev) => !prev);
    }
  };

  useEffect(() => {
    const publisher = OT.initPublisher("ot-publisher", {
      insertMode: "append",
      showControls: false,
    });
    publishRef.current = publisher;
    const publish = () => {
      session.publish(publisher, (err: any) => {
        console.log(err);
        if (err) {
          errorToastMessage(err);
        } else {
          console.log("publishing");
        }
      });
    };
    //@ts-ignore
    if (session.isConnected()) {
      publish();
    } else {
      session.on("sessionConnected", () => {
        publish();
      });
    }

    return () => {
      if (publisher) {
        try {
          publisher.destroy();
        } catch (err) {
          console.log("Error on publisher destroy");
          console.log(err);
        }
      }
    };
  }, [session, creds]);

  return (
    <>
      <Box sx={buttonContainer}>
        <Button
          sx={{ ml: 1.5 }}
          variant="outlined"
          color={video ? undefined : "error"}
          onClick={toggleVideo}
          startIcon={video ? <VideoOnIcon /> : <VideoOffIcon />}
        >
          {video ? "Stop Video" : "Start Video"}
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: 1.5 }}
          color={audio ? undefined : "error"}
          startIcon={audio ? <AudioOnIcon /> : <AudioOffIcon />}
          onClick={toggleAudio}
        >
          {audio ? "Mute" : "Unmute"}
        </Button>
        <EndCall />
      </Box>
      <div id="ot-publisher" className="ot-publisher"></div>
    </>
  );
};

export default React.memo(PublisherComp);
