import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ModalBaseStyles } from "../../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../../utils/http";
import { ConfigLabelStyle } from "../updateStudy.style";
import { useEffect, useState } from "react";
import { ConfigModalProps } from "../updateStudy.type";

const yupSchema = Yup.object({
  autoSequence: Yup.string().required("Required"),
  siteAbbr: Yup.string().required("Required"),
  separator: Yup.string().required("Required"),
  maxDigits: Yup.number().required("Required"),
});

function ChangeConfigModal({ open, setClose, editable, id }: ConfigModalProps) {
  const [options, setOptions] = useState({
    autoSequence: "",
    siteAbbr: "",
    separator: "-",
    maxDigits: 4,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const handleUpdate = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        isScreeningIdAutoSeqEnabled:
          values?.autoSequence === "yes" ? true : false,
        isSiteAbbvIncluded: values?.siteAbbr === "yes" ? true : false,
        screeningIdSepartor: values?.separator,
        screeningIdMaxDigits: values?.maxDigits,
      };
      const res: AxiosResponse = await http.patch(
        `/studies/${id}/config/screening-id`,
        body
      );
      toastMessage("success", res.data?.message);
      setClose();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    const handleFetchSSIDOptions = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(
          `/studies/${id}/config/screening-id`
        );
        const {
          isScreeningIdAutoSeqEnabled,
          isSiteAbbvIncluded,
          screeningIdSepartor,
          screeningIdMaxDigits,
        } = res.data?.data;
        const newOptions = {
          autoSequence: isScreeningIdAutoSeqEnabled ? "yes" : "no",
          siteAbbr: isSiteAbbvIncluded ? "yes" : "no",
          separator: screeningIdSepartor,
          maxDigits: screeningIdMaxDigits,
        };
        setOptions(newOptions);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
        setClose();
      }
    };

    handleFetchSSIDOptions();
  }, [id, setOptions, setIsLoading, setClose]);

  return (
    <Modal open={open} onClose={setClose}>
      <Box sx={{ ...ModalBaseStyles, p: 4 }}>
        <Formik
          enableReinitialize={true}
          initialValues={options}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            handleUpdate(values);
          }}
        >
          {(formik) => (
            <Form>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2">Screening ID Configuration</Typography>
                <IconButton
                  sx={{
                    top: "-20px",
                    right: "-20px",
                    position: "relative",
                  }}
                  color="primary"
                  component="label"
                  onClick={setClose}
                >
                  <Close
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                </IconButton>
              </Box>
              <Divider
                variant="fullWidth"
                sx={{ bgcolor: "#ECECEF", mt: "12px", mb: "10px" }}
              />
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <InputLabel sx={ConfigLabelStyle} htmlFor="autoSequence">
                    Set up automatic Econsent screening ID sequence?
                  </InputLabel>
                  <FormControl>
                    <RadioGroup
                      name="autoSequence"
                      value={formik.values.autoSequence}
                      onChange={(e) => {
                        if (editable) formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      row
                    >
                      <FormControlLabel
                        value={"yes"}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={"no"}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>

                  <InputLabel sx={ConfigLabelStyle} htmlFor="siteAbbr">
                    Would you like to have a site abbreviation included?
                  </InputLabel>
                  <FormControl>
                    <RadioGroup
                      name="siteAbbr"
                      value={formik.values.siteAbbr}
                      onChange={(e) => {
                        if (editable) formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      row
                    >
                      <FormControlLabel
                        value={"yes"}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={"no"}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>

                  <InputLabel sx={ConfigLabelStyle} htmlFor="separator">
                    Would you like to add a “-” or “_” as separator between the
                    site abbreviation and increment?
                  </InputLabel>
                  <TextField
                    fullWidth
                    name="separator"
                    value={formik.values.separator}
                    onChange={(e) => {
                      if (editable) formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.separator && formik.errors?.separator
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched?.separator && formik.errors?.separator
                        ? formik.errors?.separator
                        : null
                    }
                    select
                    placeholder="Please Enter"
                    variant="outlined"
                  >
                    {["-", "_"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <InputLabel sx={ConfigLabelStyle} htmlFor="maxDigits">
                    Number of Digits
                  </InputLabel>
                  <TextField
                    fullWidth
                    name="maxDigits"
                    value={formik.values.maxDigits}
                    onChange={(e) => {
                      if (editable) formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.maxDigits && formik.errors?.maxDigits
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched?.maxDigits && formik.errors?.maxDigits
                        ? formik.errors?.maxDigits
                        : null
                    }
                    defaultValue={formik.values?.maxDigits}
                    select
                    placeholder="Please Enter"
                    variant="outlined"
                  >
                    {[4, 5, 6].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  {editable && (
                    <Box
                      sx={{
                        display: "flex",
                        mt: "35px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {submitLoader ? (
                        <CircularProgress size={25} />
                      ) : (
                        <>
                          <Button
                            sx={{ mr: "15px" }}
                            variant="outlined"
                            onClick={setClose}
                          >
                            Cancel
                          </Button>
                          <Button variant="contained" type="submit">
                            Set Screening ID
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default ChangeConfigModal;
