import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListScoreQuestions from "./listScoreQuestions";
import ScoreCalculator from "./scoreCalculator";
import ScoreValidation from "./scoreValidation";
import {
  scoresFromResponse,
  scoreEvalFromResponse,
  isEqual,
} from "../../../utils/score";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { ScoreContainer, ScoreContent, scoreHeaderStyle } from "./scores.style";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateStore: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const qid: string = query.get("id") || "";
  const propName = query.get("name");
  const scoreId = query.get("scoreId");

  const [questionLoading, setQuestionLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [DataLoading, setDataLoading] = useState(true);
  const [name, setName] = useState("");
  let [scoreEval, setEval] = useState<any[]>([]);
  const [scoreInfo, setScoreInfo] = useState<any>(null);
  let [scores, setScores] = useState<any[]>([]);

  const [values, setValues] = useState<{ clickedItem: any; editItem: boolean }>(
    {
      clickedItem: {},
      editItem: false,
    }
  );

  const [questions, setQuestions] = useState<any[]>([]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (qid) {
        try {
          setQuestionLoading(true);
          let { data } = await http.get(`/questionnaires/${qid}/questions`);
          let ques: any[] = [];
          const temp = data.data.questions;
          temp.sort(function (a: any, b: any) {
            return a.position - b.position;
          });
          temp.forEach((que: any) => {
            if (que.type !== "group") {
              ques.push({
                questionId: que.id,
                title: que.title,
                questionNo: `Q${que.position}`,
              });
            } else {
              const groupQues = que?.properties?.questions || [];
              groupQues.sort(function (a: any, b: any) {
                return a.position - b.position;
              });
              groupQues.forEach((q: any) => {
                ques.push({
                  questionId: q.id,
                  title: q.title,
                  questionNo: `Q${que.position}.${q.position}`,
                });
              });
            }
          });
          setQuestions(ques);
          setQuestionLoading(false);
        } catch (err) {
          errorToastMessage(err as Error);
          setQuestionLoading(false);
        }
      }
    };
    fetchDetails();
  }, [qid, setQuestions, setQuestionLoading]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (scoreId) {
        try {
          setDataLoading(true);
          const res = await http.get(`/score_expressions/${scoreId}`);
          const info = res.data.data;
          setScoreInfo(info);
          setScores(scoresFromResponse(info.scoreExpressionNodes));
          setEval(scoreEvalFromResponse(info.scoreExpressionConditions));
          setName(info.title);
          setDataLoading(false);
        } catch (err) {
          setDataLoading(false);
          errorToastMessage(err as Error);
        }
      } else {
        setDataLoading(false);
      }
    };
    fetchDetails();
  }, [scoreId, setScoreInfo, setName, setScores, setEval, setDataLoading]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  // add question or operator to expression
  const handleExpression = (item: any) => {
    let expressionType = item.hasOwnProperty("questionId")
      ? "question"
      : item.hasOwnProperty("type")
      ? "paranthesis"
      : "operator";
    let expressionValue = item.hasOwnProperty("func")
      ? item.func
      : item.questionId;

    if (expressionValue === "AC") {
      setScores([]);
    } else {
      if (!values.editItem) {
        if (expressionValue === "constant") {
          setScores((prevState) => [
            ...prevState,
            {
              type: "constant",
              value: 100,
              position: prevState.length + 1,
            },
          ]);
        } else {
          setScores((prevState) => [
            ...prevState,
            {
              type: expressionType,
              value: expressionValue,
              position: prevState.length + 1,
            },
          ]);
        }
      } else {
        let tempArray =
          expressionValue === "constant"
            ? [
                {
                  type: "constant",
                  value: 100,
                  position: 1,
                },
              ]
            : [
                {
                  type: expressionType,
                  value: expressionValue,
                  position: 1,
                },
              ];

        let newArr = scores;
        let position = Number(values.clickedItem["position"]);
        newArr.splice(position, 0, ...tempArray);
        newArr.forEach((item, index) => (item.position = index + 1));

        setScores(newArr);
        setValues((prevState) => ({
          ...values,
          clickedItem: { ...prevState.clickedItem, position: position + 1 },
        }));
      }
    }
  };

  // on click of question or operator in expression
  const handleEditExpression = (item: any) => {
    setValues({
      ...values,
      clickedItem: item,
      editItem: true,
    });
  };

  // on removal of operand
  const handleRemoveOperand = (item: any) => {
    let newArray = scores.filter((score: any) => {
      return score.position !== item.position;
    });

    newArray.forEach((item, index) => (item.position = index + 1));
    setScores(newArray);
    setValues({
      ...values,
      clickedItem: {},
      editItem: false,
    });
  };

  //handle constants
  const handleConstants = (item: any, val: any) => {
    let newArray = scores.map((score: any) => {
      return score.position === item.position
        ? {
            ...score,
            value: Number(val),
          }
        : score;
    });

    setScores(newArray);
  };

  // SCORE EVALUATION

  // Add score and evaluate
  const handleUpdateScore = (
    idx: number,
    type: string,
    key: string,
    value: any
  ) => {
    if (type === "ADD") {
      setEval([
        ...scoreEval,
        {
          condition: "",
          score: 0,
        },
      ]);
    } else if (type === "CHANGE") {
      let newScoreEval = scoreEval.map((f, i) =>
        i === idx ? { ...f, [key]: value } : f
      );
      setEval(newScoreEval);
    }
  };

  // remove score object from the eval array
  const handleRemoveScore = (idx: any) => {
    const FilteredArray = scoreEval.filter(
      (_: any, index: number) => index !== idx
    );
    setEval(FilteredArray);
  };

  const updateScore = async (body: any) => {
    try {
      const res = await http.put(`/score_expressions/${scoreId}`, body);
      toastMessage("success", res.data.message);
      navigate(-1);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  // Submit score exp with nodes and conditions
  const handleScoreSubmit = async (givenStatus: any) => {
    try {
      setLoading(true);
      let updatedEval = scoreEval.map((item: any, index) => {
        return { ...item, position: index + 1 };
      });
      if (scoreId) {
        let body = {
          title: name,
          description: name,
          status: givenStatus,
        };
        updateScore(body);
      } else {
        let body = {
          title: name,
          description: name,
          status: givenStatus,
          questionnaireId: qid,
          scoreExpressionNodes: scores,
          scoreExpressionConditions: updatedEval,
        };
        const res = await http.post(`/score_expressions`, body);
        toastMessage("success", res.data.message);
        navigate(-1);
      }
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  //update score exp with nodes and conditions
  const handleScoreUpdate = (givenStatus: any) => {
    setLoading(true);
    let updatedEval = scoreEval.map((item: any, index) => {
      return { ...item, position: index + 1 };
    });

    let scoreChange = isEqual(
      scores,
      scoresFromResponse(scoreInfo.scoreExpressionNodes)
    );
    let evalChange = isEqual(
      scoreEval,
      scoreEvalFromResponse(scoreInfo.scoreExpressionConditions)
    );
    let body;
    if (!scoreChange && evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionNodes: scores,
      };
    } else if (scoreChange && !evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionConditions: updatedEval,
      };
    } else if (!scoreChange && !evalChange) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
        scoreExpressionNodes: scores,
        scoreExpressionConditions: updatedEval,
      };
    } else if (name !== scoreInfo.title) {
      body = {
        title: name,
        description: name,
        status: givenStatus,
      };
    } else {
      toastMessage("warning", "No changes to Update");
      return;
    }
    updateScore(body);
  };

  //cancel score exp with nodes and condition

  const handleCancelScore = () => {
    if (scoreInfo.hasOwnProperty("id") && scoreId) {
      setScores(scoresFromResponse(scoreInfo.scoreExpressionNodes));
      setEval(scoreEvalFromResponse(scoreInfo.scoreExpressionConditions));
      setName(scoreInfo.title);
    } else {
      setScores([]);
      setEval([]);
      setName("");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <ScoreContainer>
      <Box sx={scoreHeaderStyle}>
        <IconButton onClick={handleBack}>
          <ChevronLeftIcon fontSize="large" sx={{ color: "text.tertiary" }} />
        </IconButton>
        <Typography
          variant="h2"
          color="text.secondary"
          sx={{ mx: 2.5 }}
        >{`${propName} Score`}</Typography>
      </Box>
      <ScoreContent>
        <Box mb={2} sx={{ maxWidth: "300px" }}>
          <Typography variant="subtitle2" fontWeight="regular" mb={2}>
            Score Name* :
          </Typography>
          <TextField
            name="name"
            placeholder="Enter the score name"
            fullWidth
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Box>
        <Grid mb={2.5} container spacing={2}>
          <Grid item xs={4}>
            <ListScoreQuestions
              name={propName}
              questionLoading={questionLoading}
              handleExp={handleExpression}
              questions={questions}
            />
          </Grid>
          {DataLoading ? (
            <Grid item xs={8}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "500px",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item xs={4}>
                <ScoreCalculator
                  scores={scores}
                  handleExp={handleExpression}
                  handleEditExp={handleEditExpression}
                  handleRemoveOperand={handleRemoveOperand}
                  handleConstants={handleConstants}
                  questions={questions}
                />
              </Grid>
              <Grid item xs={4}>
                <ScoreValidation
                  updateScore={handleUpdateScore}
                  scoreEval={scoreEval}
                  handleRemoveScore={handleRemoveScore}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box mt={2.5} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {loading ? (
            <Box sx={{ mr: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={handleCancelScore}
                sx={{ ml: 1.5 }}
              >
                Cancel
              </Button>
              {scoreInfo?.status === "draft" && scoreId ? (
                <Button
                  variant="contained"
                  sx={{ ml: 1.5 }}
                  onClick={() => handleScoreUpdate("draft")}
                >
                  Update draft
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ ml: 1.5 }}
                  onClick={() => handleScoreSubmit("draft")}
                >
                  Save as draft
                </Button>
              )}
              <Button
                variant="contained"
                sx={{ ml: 1.5 }}
                onClick={() => handleScoreSubmit("active")}
              >
                Submit
              </Button>
            </>
          )}
        </Box>
      </ScoreContent>
    </ScoreContainer>
  );
};

export default CreateStore;
