export const SmallLogo = () => (
  <svg
    width="42"
    height="27"
    viewBox="0 0 42 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5723 4.58658C17.2254 2.15679 14.1638 1.27889 11.734 2.62575C9.30421 3.97261 8.42632 7.03419 9.77318 9.46399L16.5633 21.7137C17.9101 24.1435 20.9717 25.0214 23.4015 23.6745C25.8313 22.3277 26.7092 19.2661 25.3624 16.8363L18.5723 4.58658Z"
      fill="#FBA306"
    />
    <path
      d="M32.7715 4.98502C31.4246 2.55522 28.363 1.67733 25.9332 3.02419C23.5034 4.37105 22.6255 7.43263 23.9724 9.86243L30.7625 22.1121C32.1094 24.5419 35.171 25.4198 37.6007 24.0729C40.0305 22.7261 40.9084 19.6645 39.5616 17.2347L32.7715 4.98502Z"
      fill="#00B9DC"
    />
    <path
      opacity="0.14"
      d="M14.6795 18.251L13.396 16.0048L18.4902 4.45312L20.4554 7.98275L14.6795 18.251Z"
      fill="#333333"
    />
    <path
      opacity="0.14"
      d="M28.6754 18.3331L27.4253 15.7134L32.0447 4.01367L34.5716 8.18512L28.6754 18.3331Z"
      fill="#333333"
    />
    <path
      d="M2.5908 16.3173C1.15996 18.6986 1.93047 21.7889 4.31179 23.2198C6.6931 24.6506 9.78346 23.8801 11.2143 21.4988L18.4278 9.49355C19.8586 7.11224 19.0881 4.02188 16.7068 2.59104C14.3255 1.1602 11.2351 1.93072 9.80428 4.31203L2.5908 16.3173Z"
      fill="#F54B5E"
    />
    <path
      d="M16.7871 16.8036C15.3562 19.1849 16.1268 22.2753 18.5081 23.7061C20.8894 25.137 23.9797 24.3664 25.4106 21.9851L32.6241 9.97988C34.0549 7.59856 33.2844 4.50821 30.9031 3.07737C28.5218 1.64653 25.4314 2.41704 24.0006 4.79836L16.7871 16.8036Z"
      fill="#FF843D"
    />
  </svg>
);

export const LargeLogo = () => (
  <svg
    width="150"
    height="32"
    viewBox="0 0 150 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0321 5.43898C20.4344 2.55661 16.8025 1.5152 13.9202 3.11292C11.0378 4.71065 9.99638 8.34248 11.5941 11.2249L19.6489 25.7562C21.2467 28.6385 24.8785 29.68 27.7609 28.0822C30.6433 26.4845 31.6847 22.8527 30.0869 19.9703L22.0321 5.43898Z"
      fill="#FBA306"
    />
    <path
      d="M38.8759 5.91164C37.2781 3.02927 33.6463 1.98785 30.7639 3.58558C27.8815 5.1833 26.8401 8.81514 28.4379 11.6975L36.4927 26.2288C38.0904 29.1112 41.7223 30.1526 44.6046 28.5549C47.487 26.9572 48.5284 23.3253 46.9307 20.4429L38.8759 5.91164Z"
      fill="#00B9DC"
    />
    <path
      opacity="0.14"
      d="M17.4146 21.6491L15.8921 18.9846L21.9351 5.28125L24.2664 9.4683L17.4146 21.6491Z"
      fill="#333333"
    />
    <path
      opacity="0.14"
      d="M34.0172 21.7463L32.5342 18.6387L38.014 4.75977L41.0116 9.70818L34.0172 21.7463Z"
      fill="#333333"
    />
    <path
      d="M3.0731 19.3557C1.37575 22.1806 2.28978 25.8465 5.11464 27.5439C7.93949 29.2412 11.6055 28.3272 13.3028 25.5023L21.8599 11.261C23.5572 8.43613 22.6432 4.77016 19.8183 3.07281C16.9935 1.37547 13.3275 2.2895 11.6302 5.11436L3.0731 19.3557Z"
      fill="#F54B5E"
    />
    <path
      d="M19.9144 19.9319C18.2171 22.7567 19.1311 26.4227 21.9559 28.12C24.7808 29.8174 28.4468 28.9034 30.1441 26.0785L38.7012 11.8372C40.3985 9.0123 39.4845 5.34633 36.6596 3.64899C33.8348 1.95164 30.1688 2.86567 28.4715 5.69053L19.9144 19.9319Z"
      fill="#FF843D"
    />
    <path
      d="M70.1045 11.457C70.9366 11.457 71.6914 11.6022 72.3687 11.8925C73.0654 12.1827 73.6556 12.5988 74.1394 13.1407C74.6232 13.6825 75.0006 14.3308 75.2715 15.0856C75.5425 15.821 75.6779 16.6531 75.6779 17.582V26.6099H72.3977V18.0755C72.3977 16.9531 72.1268 16.0726 71.5849 15.4339C71.0431 14.7953 70.2786 14.476 69.2917 14.476C68.3628 14.476 67.6177 14.7663 67.0565 15.3468C66.4953 15.9274 66.1663 16.6628 66.0695 17.553V17.6401V26.6099H62.7893V18.0755C62.7893 16.9531 62.5087 16.0726 61.9475 15.4339C61.4056 14.7953 60.6896 14.476 59.7994 14.476C58.7543 14.476 57.9222 14.8243 57.3029 15.521C56.703 16.1983 56.403 17.0595 56.403 18.1045V26.6099H53.1519V11.7183H56.2289V13.6922C56.6159 13.0342 57.1481 12.5021 57.8254 12.0957C58.5221 11.6699 59.422 11.457 60.5251 11.457C61.5701 11.457 62.499 11.6893 63.3118 12.1537C64.1246 12.6182 64.7729 13.2858 65.2567 14.1567C65.7018 13.3826 66.3211 12.744 67.1145 12.2408C67.9273 11.7183 68.924 11.457 70.1045 11.457Z"
      fill="white"
    />
    <path
      d="M85.1602 11.457C87.0955 11.457 88.5662 11.9602 89.5726 12.9665C90.5982 13.9728 91.1111 15.4339 91.1111 17.3498V26.6099H87.8308V25.2746C87.3083 25.7777 86.7084 26.1745 86.0311 26.4647C85.3731 26.755 84.628 26.9002 83.7959 26.9002C82.3058 26.9002 81.1059 26.5228 80.1964 25.7681C79.2868 25.0133 78.832 23.9393 78.832 22.5459C78.832 21.7525 78.9675 21.0848 79.2384 20.5429C79.5287 19.9817 79.9158 19.5269 80.3996 19.1786C80.9027 18.8109 81.4833 18.54 82.1413 18.3658C82.8186 18.1916 83.5346 18.0852 84.2894 18.0465L87.8308 17.8433V17.4079C87.8308 16.4402 87.618 15.6855 87.1922 15.1436C86.7858 14.5824 86.1182 14.3018 85.1893 14.3018C84.2603 14.3018 83.583 14.5534 83.1573 15.0566C82.7315 15.5597 82.5186 16.15 82.5186 16.8273L79.2384 16.7112C79.2384 16.0532 79.3739 15.4146 79.6448 14.7953C79.9158 14.1567 80.3028 13.5955 80.806 13.1117C81.3091 12.6085 81.9284 12.2118 82.6638 11.9215C83.3992 11.6118 84.2313 11.457 85.1602 11.457ZM84.7538 20.572C83.9797 20.6107 83.3411 20.7752 82.838 21.0654C82.3541 21.3364 82.1122 21.8105 82.1122 22.4878C82.1122 23.1071 82.3154 23.5425 82.7218 23.7941C83.1476 24.0457 83.6895 24.1715 84.3474 24.1715C85.3925 24.1715 86.2343 23.9102 86.8729 23.3877C87.5309 22.8652 87.8599 22.0331 87.8599 20.8913V20.3688L84.7538 20.572Z"
      fill="white"
    />
    <path
      d="M98.7361 13.3999C99.0844 12.8774 99.6069 12.4226 100.304 12.0356C101 11.6485 101.871 11.455 102.916 11.455C103.768 11.455 104.542 11.6001 105.238 11.8904C105.954 12.1613 106.564 12.5677 107.067 13.1096C107.59 13.6321 107.996 14.2804 108.286 15.0545C108.577 15.8093 108.722 16.6704 108.722 17.638V26.6078H105.442V18.0735C105.442 16.951 105.142 16.0705 104.542 15.4319C103.961 14.7933 103.177 14.4739 102.19 14.4739C101.145 14.4739 100.304 14.8223 99.665 15.519C99.0457 16.1963 98.7361 17.0575 98.7361 18.1025V26.6078H95.4849V4.05273H98.7361V13.3999Z"
      fill="white"
    />
    <path
      d="M117.817 11.457C119.753 11.457 121.223 11.9602 122.23 12.9665C123.255 13.9728 123.768 15.4339 123.768 17.3498V26.6099H120.488V25.2746C119.966 25.7777 119.366 26.1745 118.688 26.4647C118.03 26.755 117.285 26.9002 116.453 26.9002C114.963 26.9002 113.763 26.5228 112.854 25.7681C111.944 25.0133 111.489 23.9393 111.489 22.5459C111.489 21.7525 111.625 21.0848 111.896 20.5429C112.186 19.9817 112.573 19.5269 113.057 19.1786C113.56 18.8109 114.141 18.54 114.799 18.3658C115.476 18.1916 116.192 18.0852 116.947 18.0465L120.488 17.8433V17.4079C120.488 16.4402 120.275 15.6855 119.849 15.1436C119.443 14.5824 118.775 14.3018 117.846 14.3018C116.918 14.3018 116.24 14.5534 115.814 15.0566C115.389 15.5597 115.176 16.15 115.176 16.8273L111.896 16.7112C111.896 16.0532 112.031 15.4146 112.302 14.7953C112.573 14.1567 112.96 13.5955 113.463 13.1117C113.966 12.6085 114.586 12.2118 115.321 11.9215C116.056 11.6118 116.889 11.457 117.817 11.457ZM117.411 20.572C116.637 20.6107 115.998 20.7752 115.495 21.0654C115.011 21.3364 114.769 21.8105 114.769 22.4878C114.769 23.1071 114.973 23.5425 115.379 23.7941C115.805 24.0457 116.347 24.1715 117.005 24.1715C118.05 24.1715 118.892 23.9102 119.53 23.3877C120.188 22.8652 120.517 22.0331 120.517 20.8913V20.3688L117.411 20.572Z"
      fill="white"
    />
    <path d="M128.142 26.6078V4.05273H131.393V26.6078H128.142Z" fill="white" />
    <path
      d="M142.249 11.457C143.352 11.457 144.378 11.6409 145.326 12.0086C146.275 12.3569 147.087 12.8697 147.765 13.5471C148.461 14.2051 149.003 15.0179 149.39 15.9855C149.797 16.9337 150 17.9981 150 19.1786C150 20.3591 149.797 21.4235 149.39 22.3717C149.003 23.32 148.461 24.1328 147.765 24.8101C147.087 25.4681 146.275 25.9809 145.326 26.3486C144.378 26.7163 143.352 26.9002 142.249 26.9002C141.146 26.9002 140.121 26.7163 139.172 26.3486C138.224 25.9809 137.402 25.4681 136.705 24.8101C136.008 24.1328 135.457 23.32 135.05 22.3717C134.663 21.4235 134.47 20.3591 134.47 19.1786C134.47 17.9981 134.663 16.9337 135.05 15.9855C135.457 15.0179 136.008 14.2051 136.705 13.5471C137.402 12.8697 138.224 12.3569 139.172 12.0086C140.121 11.6409 141.146 11.457 142.249 11.457ZM142.249 14.2438C140.953 14.2438 139.879 14.6792 139.027 15.55C138.195 16.4015 137.779 17.6111 137.779 19.1786C137.779 20.7268 138.195 21.9363 139.027 22.8072C139.879 23.6587 140.953 24.0844 142.249 24.0844C142.888 24.0844 143.478 23.978 144.02 23.7651C144.562 23.5329 145.036 23.2136 145.442 22.8072C145.849 22.3814 146.159 21.8686 146.371 21.2686C146.604 20.6494 146.72 19.9527 146.72 19.1786C146.72 18.4045 146.604 17.7175 146.371 17.1176C146.159 16.4983 145.849 15.9758 145.442 15.55C145.036 15.1243 144.562 14.805 144.02 14.5921C143.478 14.3599 142.888 14.2438 142.249 14.2438Z"
      fill="white"
    />
  </svg>
);

export const LargeLogoBlack = () => (
  <svg
    width="274"
    height="74"
    viewBox="0 0 274 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.6123 18.4936C36.7398 13.3069 30.21 11.4329 25.0278 14.308C19.8456 17.183 17.9732 23.7183 20.8458 28.905L35.3276 55.0534C38.2002 60.2401 44.7299 62.1141 49.9121 59.239C55.0944 56.364 56.9667 49.8287 54.0942 44.642L39.6123 18.4936Z"
      fill="#FBA306"
    />
    <path
      d="M69.8975 19.3432C67.0249 14.1565 60.4952 12.2826 55.313 15.1576C50.1307 18.0326 48.2584 24.5679 51.1309 29.7546L65.6128 55.903C68.4853 61.0897 75.015 62.9637 80.1973 60.0886C85.3795 57.2136 87.2519 50.6783 84.3793 45.4916L69.8975 19.3432Z"
      fill="#00B9DC"
    />
    <path
      opacity="0.14"
      d="M31.3116 47.6641L28.5742 42.8694L39.439 18.2109L43.6305 25.7453L31.3116 47.6641Z"
      fill="#333333"
    />
    <path
      opacity="0.14"
      d="M61.1624 47.8359L58.4961 42.2439L68.3484 17.2695L73.7377 26.174L61.1624 47.8359Z"
      fill="#333333"
    />
    <path
      d="M5.528 43.5363C2.47633 48.6195 4.11966 55.2162 9.19849 58.2705C14.2773 61.3248 20.8684 59.68 23.9201 54.5968L39.3049 28.9702C42.3566 23.887 40.7132 17.2903 35.6344 14.236C30.5556 11.1817 23.9645 12.8265 20.9128 17.9097L5.528 43.5363Z"
      fill="#F54B5E"
    />
    <path
      d="M35.8053 44.5734C32.7537 49.6566 34.397 56.2533 39.4758 59.3076C44.5547 62.3619 51.1457 60.7172 54.1974 55.634L69.5822 30.0073C72.6339 24.9241 70.9906 18.3274 65.9117 15.2731C60.8329 12.2188 54.2418 13.8636 51.1902 18.9468L35.8053 44.5734Z"
      fill="#FF843D"
    />
    <path
      d="M126.042 29.3223C127.538 29.3223 128.895 29.5834 130.113 30.1058C131.365 30.6281 132.426 31.3769 133.296 32.3519C134.166 33.327 134.845 34.4936 135.332 35.8517C135.819 37.175 136.062 38.6724 136.062 40.3439V56.5891H130.165V41.2319C130.165 39.2121 129.678 37.6277 128.703 36.4785C127.729 35.3293 126.355 34.7547 124.58 34.7547C122.91 34.7547 121.571 35.2771 120.562 36.3218C119.553 37.3665 118.961 38.6898 118.787 40.2917V40.4484V56.5891H112.89V41.2319C112.89 39.2121 112.385 37.6277 111.376 36.4785C110.402 35.3293 109.115 34.7547 107.514 34.7547C105.635 34.7547 104.139 35.3816 103.026 36.6352C101.947 37.854 101.408 39.4037 101.408 41.2841V56.5891H95.5625V29.7924H101.095V33.3444C101.791 32.1604 102.747 31.2027 103.965 30.4714C105.218 29.7053 106.836 29.3223 108.819 29.3223C110.698 29.3223 112.368 29.7401 113.829 30.5759C115.291 31.4117 116.456 32.6131 117.326 34.1801C118.126 32.7872 119.24 31.638 120.666 30.7326C122.127 29.7924 123.919 29.3223 126.042 29.3223Z"
      fill="#111928"
    />
    <path
      d="M153.112 29.3223C156.591 29.3223 159.236 30.2277 161.045 32.0385C162.889 33.8493 163.811 36.4785 163.811 39.926V56.5891H157.913V54.1863C156.974 55.0917 155.895 55.8056 154.678 56.3279C153.495 56.8503 152.155 57.1114 150.659 57.1114C147.98 57.1114 145.823 56.4324 144.187 55.0743C142.552 53.7162 141.734 51.7834 141.734 49.2761C141.734 47.8484 141.978 46.647 142.465 45.6719C142.987 44.662 143.683 43.8437 144.553 43.2169C145.457 42.5552 146.501 42.0677 147.684 41.7543C148.902 41.4409 150.189 41.2493 151.546 41.1797L157.913 40.814V40.0305C157.913 38.2893 157.531 36.9312 156.765 35.9561C156.035 34.9463 154.834 34.4413 153.164 34.4413C151.494 34.4413 150.276 34.894 149.511 35.7994C148.745 36.7049 148.363 37.767 148.363 38.9858L142.465 38.7769C142.465 37.5929 142.709 36.4437 143.196 35.3293C143.683 34.1801 144.379 33.1703 145.283 32.2997C146.188 31.3943 147.301 30.6804 148.624 30.158C149.946 29.6009 151.442 29.3223 153.112 29.3223ZM152.381 45.7241C150.989 45.7938 149.841 46.0898 148.937 46.6122C148.067 47.0997 147.632 47.9529 147.632 49.1717C147.632 50.286 147.997 51.0696 148.728 51.5223C149.493 51.975 150.468 52.2013 151.651 52.2013C153.529 52.2013 155.043 51.7312 156.191 50.791C157.374 49.8507 157.966 48.3533 157.966 46.2987V45.3585L152.381 45.7241Z"
      fill="#111928"
    />
    <path
      d="M177.521 32.8217C178.147 31.8815 179.087 31.0631 180.339 30.3667C181.592 29.6702 183.158 29.322 185.037 29.322C186.567 29.322 187.959 29.5831 189.212 30.1055C190.499 30.593 191.595 31.3243 192.5 32.2994C193.439 33.2396 194.17 34.4062 194.692 35.7991C195.214 37.1572 195.475 38.7069 195.475 40.4481V56.5888H189.577V41.2316C189.577 39.2118 189.038 37.6274 187.959 36.4782C186.915 35.329 185.506 34.7544 183.732 34.7544C181.853 34.7544 180.339 35.3813 179.191 36.6349C178.078 37.8537 177.521 39.4034 177.521 41.2838V56.5888H171.676V16.002H177.521V32.8217Z"
      fill="#111928"
    />
    <path
      d="M211.827 29.3223C215.306 29.3223 217.95 30.2277 219.76 32.0385C221.604 33.8493 222.526 36.4785 222.526 39.926V56.5891H216.628V54.1863C215.689 55.0917 214.61 55.8056 213.392 56.3279C212.209 56.8503 210.87 57.1114 209.374 57.1114C206.695 57.1114 204.537 56.4324 202.902 55.0743C201.267 53.7162 200.449 51.7834 200.449 49.2761C200.449 47.8484 200.693 46.647 201.18 45.6719C201.702 44.662 202.398 43.8437 203.267 43.2169C204.172 42.5552 205.216 42.0677 206.399 41.7543C207.617 41.4409 208.904 41.2493 210.261 41.1797L216.628 40.814V40.0305C216.628 38.2893 216.246 36.9312 215.48 35.9561C214.749 34.9463 213.549 34.4413 211.879 34.4413C210.209 34.4413 208.991 34.894 208.226 35.7994C207.46 36.7049 207.077 37.767 207.077 38.9858L201.18 38.7769C201.18 37.5929 201.423 36.4437 201.911 35.3293C202.398 34.1801 203.094 33.1703 203.998 32.2997C204.903 31.3943 206.016 30.6804 207.338 30.158C208.661 29.6009 210.157 29.3223 211.827 29.3223ZM211.096 45.7241C209.704 45.7938 208.556 46.0898 207.651 46.6122C206.782 47.0997 206.347 47.9529 206.347 49.1717C206.347 50.286 206.712 51.0696 207.443 51.5223C208.208 51.975 209.182 52.2013 210.365 52.2013C212.244 52.2013 213.758 51.7312 214.906 50.791C216.089 49.8507 216.68 48.3533 216.68 46.2987V45.3585L211.096 45.7241Z"
      fill="#111928"
    />
    <path d="M230.387 56.5888V16.002H236.232V56.5888H230.387Z" fill="#111928" />
    <path
      d="M255.753 29.3223C257.736 29.3223 259.58 29.6531 261.285 30.3147C262.99 30.9416 264.451 31.8644 265.669 33.0832C266.921 34.2672 267.896 35.7298 268.592 37.471C269.322 39.1773 269.688 41.0926 269.688 43.2169C269.688 45.3411 269.322 47.2564 268.592 48.9627C267.896 50.6691 266.921 52.1317 265.669 53.3505C264.451 54.5345 262.99 55.4573 261.285 56.119C259.58 56.7806 257.736 57.1114 255.753 57.1114C253.769 57.1114 251.925 56.7806 250.22 56.119C248.516 55.4573 247.037 54.5345 245.784 53.3505C244.532 52.1317 243.54 50.6691 242.809 48.9627C242.114 47.2564 241.766 45.3411 241.766 43.2169C241.766 41.0926 242.114 39.1773 242.809 37.471C243.54 35.7298 244.532 34.2672 245.784 33.0832C247.037 31.8644 248.516 30.9416 250.22 30.3147C251.925 29.6531 253.769 29.3223 255.753 29.3223ZM255.753 34.3369C253.421 34.3369 251.49 35.1204 249.96 36.6874C248.463 38.2197 247.715 40.3962 247.715 43.2169C247.715 46.0027 248.463 48.1792 249.96 49.7463C251.49 51.2785 253.421 52.0446 255.753 52.0446C256.901 52.0446 257.962 51.8531 258.936 51.47C259.911 51.0522 260.763 50.4776 261.494 49.7463C262.224 48.9802 262.781 48.0573 263.164 46.9778C263.581 45.8634 263.79 44.6098 263.79 43.2169C263.79 41.8239 263.581 40.5877 263.164 39.5081C262.781 38.3938 262.224 37.4536 261.494 36.6874C260.763 35.9213 259.911 35.3467 258.936 34.9637C257.962 34.5458 256.901 34.3369 255.753 34.3369Z"
      fill="#111928"
    />
  </svg>
);
