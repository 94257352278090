import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import React, { useEffect, useState } from "react";
import { Public } from "@mui/icons-material";
import {
  containerStyle,
  itemsContainer,
  rightSideContainer,
  rightSideInternalContainer,
  dropDownContainer,
  dropDownStyle,
  iconStyle,
  rightSuccessContainer,
} from "./Scheduling.style";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BackButton } from "../auth/style/ForgotPasswordStyles";
import { getDefaultTimezone, timezones } from "../../utils/tz";
import http from "../../utils/http";
import PatientDetails from "./PatientDetails";
import { Slot } from "../../type";
import SlotContainer from "./SlotContainer";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { SuccessAptIcon } from "./assets";
import { DateTime, Duration } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CustomLuxonUtils from "../../utils/luxon";

const epochComparator = (a: Slot, b: Slot) => {
  const posA = a.milliseconds;
  const posB = b.milliseconds;

  let comparison = 0;
  if (posA > posB) {
    comparison = 1;
  } else if (posA < posB) {
    comparison = -1;
  }
  return comparison;
};

const Scheduling: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [apptBooked, setApptBooked] = useState(false);
  const [slotArray, setSlotArray] = useState<Slot[]>([]);
  const [selectedSlotLabel, setSelectedSlotLabel] = useState<string>("");

  const [searchParams] = useSearchParams();
  const studyId = searchParams.get("studyId");
  const id = searchParams.get("id");

  const [timezone, setTimezone] = useState(getDefaultTimezone());
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(
    DateTime.now().setZone(timezone).startOf("day")
  );
  const [minDate, setMinDate] = useState<DateTime>(
    DateTime.now().setZone(timezone).startOf("day")
  );

  useEffect(() => {
    setMinDate(DateTime.now().setZone(timezone).startOf("day"));
  }, [setMinDate, timezone]);

  const selectSlot = (value: string) => {
    setSelectedSlotLabel(value);
  };

  const handleTimezoneChange = (event: SelectChangeEvent) => {
    const newTz = event.target.value as string;
    localStorage.setItem("tz-preference", newTz);
    setTimezone(newTz);
    setSelectedDate(DateTime.now().setZone(newTz).startOf("day"));
  };

  useEffect(() => {
    const fetchData = async (date: DateTime) => {
      try {
        setLoading(true);
        const startOfDay = date.setZone(timezone).startOf("day");
        const endOfDay = date.setZone(timezone).endOf("day");
        const startIso = startOfDay.toUTC().toISO();
        const endIso = endOfDay.toUTC().toISO();
        const res = await http.get(
          `/appointments/calendar?startDate=${startIso}&endDate=${endIso}`
        );
        const slot = res.data.data.slotConfiguration;
        const appts: string[] = res.data.data.appointments.map((appt: any) => {
          return appt.scheduledStartTime;
        });
        const startOffset = Duration.fromISOTime(slot.startOffset).as(
          "minutes"
        );
        const endOffset = Duration.fromISOTime(slot.endOffset).as("minutes");
        const slotArray: Slot[] = [];
        let startSlot = startOfDay.plus({
          minutes: startOffset + startOfDay.offset,
        });
        let endSlot = startOfDay.plus({
          minutes: endOffset + startOfDay.offset,
        });
        if (endSlot < startSlot) {
          endSlot = endSlot.plus({ day: 1 });
        }
        let counter = 0;
        const currentTime = DateTime.now();
        while (startSlot <= endSlot && counter < 48) {
          counter++;
          let iso = startSlot.toUTC().toISO();
          let localSlot = startSlot.setZone(timezone);
          if (endOfDay < localSlot) {
            localSlot = localSlot.minus({ day: 1 });
            iso = localSlot.toUTC().toISO();
          } else if (startOfDay > localSlot) {
            localSlot = localSlot.plus({ day: 1 });
            iso = localSlot.toUTC().toISO();
          }
          const enabled = currentTime < localSlot && !appts.includes(iso);
          slotArray.push({
            label: localSlot.toFormat("hh:mm a"),
            enabled: enabled,
            milliseconds: localSlot.toMillis(),
          });
          startSlot = startSlot.plus({ minutes: 30 });
        }
        slotArray.sort(epochComparator);
        setSlotArray(slotArray);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    if (selectedDate) {
      fetchData(selectedDate);
    }
  }, [selectedDate, setSlotArray, setLoading, timezone]);

  const handleConfirm = async (epoch: number) => {
    try {
      setLoading(true);
      const startTime = DateTime.fromMillis(epoch).toUTC().toISO();
      const endTime = DateTime.fromMillis(epoch)
        .plus({ minutes: 30 })
        .toUTC()
        .toISO();
      const res = await http.post("/appointments", {
        studyId: studyId,
        subjectId: id,
        scheduledStartTime: startTime,
        scheduledEndTime: endTime,
      });
      toastMessage("success", res.data.message);
      setLoading(false);
      setApptBooked(true);
    } catch (err) {
      errorToastMessage(err as Error);
      setLoading(false);
    }
  };

  const handleDone = () => {
    navigate(-1);
  };

  return (
    <Box sx={containerStyle}>
      <BackButton />
      <Box sx={itemsContainer}>
        <PatientDetails />
        {apptBooked ? (
          <Box sx={rightSuccessContainer}>
            <SuccessAptIcon />
            <Typography variant="h2" mt={5} mb={2}>
              Confirmed
            </Typography>
            <Button onClick={handleDone} sx={{ color: "text.link" }}>
              Done
            </Button>
          </Box>
        ) : (
          <Box sx={rightSideContainer}>
            <Typography variant="h3">Select a Date & Time</Typography>
            <Box sx={rightSideInternalContainer}>
              <Box className="datePickerContainer">
                <StaticDatePicker
                  key={timezone}
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={selectedDate}
                  onChange={(newValue) => {
                    if (!loading) {
                      setSelectedDate(newValue);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={minDate}
                  dayOfWeekFormatter={(day) => {
                    return day;
                  }}
                  disableHighlightToday={true}
                />
                <Box sx={dropDownContainer}>
                  <Public sx={iconStyle} />
                  <FormControl fullWidth sx={dropDownStyle}>
                    <Select
                      variant="standard"
                      id="demo-simple-select"
                      value={timezone}
                      label="Select time zone"
                      onChange={handleTimezoneChange}
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      }}
                      displayEmpty
                    >
                      {timezones.map((tz) => {
                        return (
                          <MenuItem key={tz.label} value={tz.tzCode}>
                            {tz.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <SlotContainer
                handleConfirm={handleConfirm}
                selectSlot={selectSlot}
                selectedSlotLabel={selectedSlotLabel}
                slotArray={slotArray}
                selectedDate={selectedDate}
                loading={loading}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SchedulingWrapper = () => {
  return (
    <LocalizationProvider dateAdapter={CustomLuxonUtils}>
      <Scheduling />
    </LocalizationProvider>
  );
};

export default SchedulingWrapper;
