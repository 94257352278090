import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { CardHeader, CardLg } from "../updateStudy.style";
import ChangeConfigModal from "./ChangeConfigModal";

const AutoCopyOption = ({ editable, id }: any) => {
  const [option, setOption] = useState<string>("no");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editable) {
      setOption(event.target.value);
    }
  };
  
  const handleModalOpen = (id?: any) => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUpdate = async () => {
    try {
      setSubmitLoader(true);
      const body = {
        isScreeningIdAutocopyEnabled: option === "yes" ? true : false,
      };
      const res: AxiosResponse = await http.patch(
        `/studies/${id}/config/autocopy-screening-id`,
        body
      );
      toastMessage("success", res.data?.message);
      setToggleLoader((prev) => !prev);
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    const fetchAutoCopyOption = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(
          `/studies/${id}/config/screening-id`
        );
        const { isScreeningIdAutocopyEnabled } = res.data?.data;
        const newOption = isScreeningIdAutocopyEnabled ? "yes" : "no";
        setOption(newOption);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchAutoCopyOption();
  }, [id, toggleLoader]);

  return (
    <CardLg>
      <CardHeader>
        <Typography
          variant="subtitle2"
          fontWeight="medium"
          color="text.tertiary"
        >
          Screening and Subject ID'S
        </Typography>
      </CardHeader>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" fontWeight="regular" mb={2.5}>
            Screening ID’s
          </Typography>
          <Typography variant="subtitle1" mb={3.75}>
            Econsent automatically creates your screening ID’s. You can make
            changes to the automatic sequence or change to manual set up until
            your study goes live.
          </Typography>
          <Button variant="contained" onClick={handleModalOpen} sx={{ mb: 5 }}>
            Change configuration
          </Button>
          {modalOpen && (
            <ChangeConfigModal
              open={modalOpen}
              setClose={handleModalClose}
              editable={editable}
              id={id}
            />
          )}
          <Typography variant="subtitle1" fontWeight="regular" mb={2.5}>
            Subject ID’s
          </Typography>
          <Typography variant="subtitle1" mb={2.5}>
            Would you like to automatically copy your screening ID to use as
            your subject ID?
          </Typography>
          <FormControl>
            <RadioGroup value={option} onChange={(e) => handleChange(e)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {editable && (
            <Box sx={{ mt: 3.75 }}>
              {submitLoader ? (
                <CircularProgress size={25} />
              ) : (
                <Button variant="contained" onClick={handleUpdate}>
                  Save Changes
                </Button>
              )}
            </Box>
          )}
        </>
      )}
    </CardLg>
  );
};

export default AutoCopyOption;
