import moment from "moment";
import { consentViewFormat } from "../../utils/consent";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  setAdminSign,
  setContentData,
  setError,
  setFinalise,
  setFormSubmitting,
  setLoader,
  setOpentokData,
  setPatientSign,
  signAllCheckbox,
} from "../reducers/consentSignSlice";
import { AppThunk } from "../store";

export const loadSigningData =
  (studyId: string, subjectId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const res = await http.get(
        `/study-subject/consent-sign/admin/forms?studyId=${studyId}&subjectId=${subjectId}`
      );
      const forms = res.data?.data?.consentForms;
      if (forms[0]?.consentForm) {
        const form = forms[0]?.consentForm;
        let patientSign = null;
        let adminSign = null;
        let { formText, signature, memberSignature, checkbox } =
          consentViewFormat(form?.configuration?.config || []);
        memberSignature = memberSignature.slice(0, 1);
        const title = form?.name;
        const formId = form?.id;

        if (res.data?.data?.consentData) {
          const consent = res.data?.data?.consentData;
          const subject = consent?.subjectData;
          if (subject?.name && subject?.sign && subject?.date) {
            patientSign = {
              name: subject?.name,
              sign: subject?.sign,
              date: moment(subject?.date).format("D MMM YYYY"),
            };
            checkbox = checkbox.map((item) => {
              return {
                ...item,
                isChecked: true,
              };
            });
          }
          const admin = consent?.adminData;
          if (admin?.name && admin?.sign && admin?.date) {
            adminSign = {
              name: admin?.name,
              sign: admin?.sign,
              date: moment(admin?.date).format("D MMM YYYY"),
            };
          }
          dispatch(setAdminSign(adminSign));
          dispatch(setPatientSign(patientSign));
          if (adminSign && patientSign) {
            dispatch(setFinalise(true));
          }
        }
        if (res.data.data?.connectionData) {
          dispatch(setOpentokData(res.data.data?.connectionData));
        }
        dispatch(
          setContentData({
            formText,
            signature,
            memberSignature,
            checkbox,
            title,
            formId,
          })
        );
        dispatch(setLoader(false));
      } else {
        dispatch(setError("No consent forms found."));
        dispatch(setLoader(false));
      }
    } catch (err) {
      dispatch(setError("Something went wrong"));
      dispatch(setLoader(false));
      errorToastMessage(err as Error);
    }
  };

export const pollConsentRequest =
  (studyId: string, subjectId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const formId = getState().consentSign.formId;
      const res = await http.get(
        `/study-subject/consent-sign/status?studyId=${studyId}&subjectId=${subjectId}&consentFormId=${formId}`
      );
      let patientSign = null;
      const subject = res.data.data?.subjectData;
      if (subject?.name && subject?.sign && subject?.date) {
        patientSign = {
          name: subject?.name,
          sign: subject?.sign,
          date: moment(subject?.date).format("D MMM YYYY"),
        };
        dispatch(signAllCheckbox());
      }
      dispatch(setPatientSign(patientSign));
    } catch (err) {}
  };

export const sleep = async (ms: number) => {
  await new Promise((res: Function, rej) => {
    setTimeout(() => {
      res();
    }, ms);
  });
};

export const submitData =
  (studyId: string, subjectId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { patientSign, adminSign, formId } = getState().consentSign;
      if (!patientSign) {
        toastMessage(
          "error",
          "Document can be finalised only when the subject signs"
        );
        return;
      }
      if (!adminSign?.sign || !adminSign?.name) {
        toastMessage("error", "Please enter your full name and signature");
        return;
      }
      dispatch(setFormSubmitting(true));
      const date = moment().toISOString();
      await http.post(`/study-subject/consent-sign/admin/sign`, {
        studyId: studyId,
        consentFormId: formId,
        subjectId: subjectId,
        data: {
          ...adminSign,
          date,
        },
      });
      toastMessage("success", "Signature submitted successfully");
      dispatch(setFinalise(true));
      dispatch(setFormSubmitting(false));
    } catch (err) {
      dispatch(setFormSubmitting(false));
      errorToastMessage(err as Error);
    }
  };
