import { SxProps } from "@mui/material";

export const HeaderStyle: SxProps = {
  display: "flex",
  alignItems: "center",
  height: "80px",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  px: 2.5,
};

export const DividerStyle: SxProps = {
  marginY: 5,
  borderColor: "rgba(0,0,0,0.1)",
};

export const LabelStyle: SxProps = {
  fontSize: "16px",
  fontWeight: 600,
  color: "text.primary",
  mb: 1,
};

export const CardStyle: SxProps = {
  borderRadius: "16px",
  backgroundColor: "#FFFFFF",
  border: "1px solid",
  borderColor: "border.type1",
};

export const CardHeader: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "15px 20px",
  borderBottom: "1px solid",
  borderColor: "border.type1",
};

export const InputLabelStyle: SxProps = {
  fontSize: "14px",
  fontWeight: 600,
  mb: 1,
  textTransform: "uppercase",
};

export const InputStyle: SxProps = {
  height: "48px",
  mb: 3.75,
};

export const MemberEditLabel: SxProps = {
  color: "text.primary",
  mb: 1,
  fontSize: "14px",
  fontWeight: "medium",
  textTransform: "uppercase",
};
