import { Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { addSignatureText } from "../../../../../Redux/reducers/consentSlice";
import {
  quillFormatsThin,
  quillModulesThin,
} from "../../../../../utils/reactQuillOptions";

const SignatureText = () => {
  const dispatch = useAppDispatch();

  const { signature } = useAppSelector((state) => state.consent);

  const handleSignatureText = (value: string) => {
    dispatch(addSignatureText(value));
  };

  return (
    <>
      <Typography
        variant="h3"
        color="text.tertiary"
        fontWeight="medium"
        mb={2.5}
      >
        Signature Statements
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.tertiary"
        fontWeight="medium"
        mb={1.25}
      >
        Subject
      </Typography>
      <Typography variant="subtitle1" color="text.primary" mb={2.5}>
        Please use the box below to define the signature statement for when
        subjects are signing this consent form.
      </Typography>
      <ReactQuill
        modules={quillModulesThin}
        formats={quillFormatsThin}
        defaultValue={signature}
        onChange={handleSignatureText}
        className="quill-container quill-container-sm"
      />
    </>
  );
};

export default SignatureText;
