import { SxProps } from "@mui/material";

export const buttonLoaderSx:SxProps = {
  height: "35px",
  width: "165px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

export const ModalBaseStyles: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "white",
  boxShadow: 4,
  borderRadius: "0.7rem",
  p: 2,
  outline: "none",
  minHeight: "40vh",
  maxHeight: "80vh",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
};
