import { Videocam, WatchLater } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { LargeLogoBlack } from "../Assets/Logo";
import {
  iconStyle,
  leftSideBottomContainer,
  leftSideContainer,
  leftSideTopContainer,
  lightTextStyle,
  textWithIconStyle,
} from "./Scheduling.style";

const PatientDetails = () => {
  const [searchParams] = useSearchParams();
  const subjectId = searchParams.get("subjectId");
  const name = searchParams.get("name");
  const email = searchParams.get("email");

  return (
    <Box sx={leftSideContainer}>
      <Box sx={leftSideTopContainer}>
        <LargeLogoBlack />
      </Box>
      <Box sx={leftSideBottomContainer}>
        <Box my="16px">
          <Typography variant="subtitle1" fontWeight="bold">
            {name}
          </Typography>
          <Typography sx={lightTextStyle} variant="body1" fontWeight="medium">
            {subjectId}
          </Typography>
          <Typography sx={lightTextStyle} variant="body1" fontWeight="regular">
            {email}
          </Typography>
        </Box>
        <Typography variant="h2">30 Minute Meeting</Typography>
        <Box mt={4}>
          <Box sx={textWithIconStyle}>
            <WatchLater sx={iconStyle} />
            <Typography
              sx={lightTextStyle}
              variant="subtitle1"
              fontWeight="medium"
            >
              30 min
            </Typography>
          </Box>
          <Box sx={{ ...textWithIconStyle, marginTop: "19px" }}>
            <Videocam sx={iconStyle} />
            <Typography
              sx={lightTextStyle}
              variant="subtitle1"
              fontWeight="medium"
            >
              Web conferencing details provided upon confirmation
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientDetails;
