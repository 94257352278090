import { styled, SxProps } from "@mui/material";

export const signContainer: SxProps = {
  height: "calc(100% - 155px)",
  overflow: "auto",
  background: "#FAFAFA",
  display: "flex",
  alignItems: "flex-start",
  padding: "60px",
  position: "relative",
};

export const signHeader: SxProps = {
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  height: "91px",
  width: "100%",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  backgroundColor: "#ffffff",
};

export const LoaderContainer = styled("div")(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const consentContentWrapper: SxProps = {
  borderRadius: "6px",
  background: "#ffffff",
  width: "65%",
  padding: "40px",
};

export const consentCallWrapper: SxProps = {
  flex: 1,
  position: "sticky",
  top: "0px",
  minWidth: "1px",
  px: "20px",
};

export const buttonContainer: SxProps = {
  position: "fixed",
  top: "64px",
  right: "60px",
  height: "91px",
  display: "flex",
  alignItems: "center",
};
