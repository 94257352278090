import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import { AuditTrailData } from "../../../../utils/AuditTableData";
import {
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
  paginationLabel,
} from "../../../Common/styles/table";

const AuditTrailTab = () => {
  const [data] = useState(AuditTrailData);

  const [sortColumn, setSortColumn] = useState<undefined | string>();
  const [sortOrder, setSortOrder] = useState<undefined | string>();
  const [page, setPage] = useState(0);

  const handleSort = (order: string, column: string) => {
    setSortOrder(order);
    setSortColumn(column);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Date and Time
              <SortLabel column="dt" />
            </StyledTableCell>
            <StyledTableCell>
              Actor <SortLabel column="actor" />
            </StyledTableCell>
            <StyledTableCell>
              Type of user <SortLabel column="user" />
            </StyledTableCell>
            <StyledTableCell>
              Screening ID <SortLabel column="sid" />
            </StyledTableCell>
            <StyledTableCell>
              Action <SortLabel column="action" />
            </StyledTableCell>
            <StyledTableCell>
              Additional Info <SortLabel column="info" />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell>{row.dt}</StyledTableCell>
              <StyledTableCell>{row.actor}</StyledTableCell>
              <StyledTableCell>{row.user}</StyledTableCell>
              <StyledTableCell>{row.sid}</StyledTableCell>
              <StyledTableCell>{row.action}</StyledTableCell>
              <StyledTableCell>{row.info}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              sx={TablePaginationStyle}
              count={10}
              page={page}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
              onPageChange={handleChangePage}
              labelDisplayedRows={paginationLabel}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default AuditTrailTab;
