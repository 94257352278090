import { Box, Skeleton, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import AddStudyModal from "./AddStudyModal";
import { noItemsStyles } from "./dashboard.style";
import { StudiesListType, StudiesTabProps } from "./dashboard.type";
import StudyItem from "./StudyItem";

const StudiesTab = ({
  openAddModal,
  handleModalVisibility,
}: StudiesTabProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [studiesList, setStudiesList] = useState<StudiesListType[]>([]);

  const navigate = useNavigate();

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const studyNavigate = (id: string) => {
    navigate(`/study/${id}/screening`);
  };

  const handleModalClose = () => {
    handleModalVisibility(false);
  };

  useEffect(() => {
    const fetchStudies = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get("/studies");
        const newStudies = res.data?.data.map((study: any) => {
          return {
            id: study?.id,
            name: study?.name,
            description: study?.contact
              ? study?.contact?.firstName + " " + study?.contact?.lastName
              : "-",
            status: study?.status,
          };
        });
        setStudiesList(newStudies);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchStudies();
  }, [toggleLoader, setIsLoading, setStudiesList]);

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Skeleton variant="rounded" height="84px" animation="wave" />
          <Skeleton variant="rounded" height="84px" animation="wave" />
          <Skeleton variant="rounded" height="84px" animation="wave" />
        </Box>
      ) : (
        <>
          {studiesList.length > 0 ? (
            studiesList.map((study: StudiesListType) => (
              <StudyItem
                key={study.id}
                data={study}
                clickHandler={studyNavigate}
              />
            ))
          ) : (
            <Box sx={noItemsStyles}>
              <Typography variant="h2" color="text.grey">
                No Studies found
              </Typography>
            </Box>
          )}
          {openAddModal && (
            <AddStudyModal
              open={openAddModal}
              closeModal={handleModalClose}
              refreshPage={refreshPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default StudiesTab;
