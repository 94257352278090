import { styled, SxProps } from "@mui/material";
import { rowFlexStyleAlignCenter } from "../../Common/styles/flex";

export const QBPreviewContainer = styled("div")(() => ({
  height: "calc(100% - 64px)",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

export const QBPreviewContent = styled("div")(() => ({
  flex: 1,
  minHeight: "0px",
  overflow: "auto",
  padding: "20px",
}));

export const QBPreviewContentWrapper = styled("div")(() => ({
  width: "70%",
}));

export const QBLoader = styled("div")(() => ({
  flex: 1,
  minHeight: "1px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const headerStyle: SxProps = {
  height: "91px",
  backgroundColor: "#ffffff",
  width: "100%",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  px: 2.5,
  ...rowFlexStyleAlignCenter,
};

export const QuesBlockStyle: SxProps = {
  p: 2.5,
  border: 1,
  borderColor: "border.type2",
  borderRadius: 1,
  background: "#ffffff",
  "&:not(:last-child)": {
    marginBottom: 2.5,
  },
};

export const QuestionIndexStyle: SxProps = {
  mr: 2.5,
  typography: "subtitle1",
  fontWeight: "medium",
  width: "40px",
  height: "40px",
  backgroundColor: "primary.main",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#ffffff",
  flexShrink: 0,
};

export const QuestionTitleStyle: SxProps = {
  typography: "subtitle1",
  fontWeight: "regular",
};

export const QuestionStatementStyle: SxProps = {
  display: "flex",
  p: 1.25,
  marginY: 2,
  borderRadius: 1,
  width: "100%",
  backgroundColor: "primary.light",
  mb: 2.5,
};

export const QuestionsEmptyStyle: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  typography: "h1",
  color: "text.grey",
};

export const QuestionContentStyle: SxProps = {
  ml: "60px",
  mb: 2.5,
};

export const remarkContainerStyle: SxProps = {
  mt: 2,
  width: "100%",
};

export const remarkLabelStyle: SxProps = {
  mb: 2,
  typography: "subtitle1",
  fontWeight: "light",
};
