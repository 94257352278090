import { Box, InputLabel, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import SignatureCanvas from "react-signature-canvas";
import { useAppSelector } from "../../../Redux/hooks";

const PatientSignature = () => {
  const { signature, patientSign } = useAppSelector(
    (state) => state.consentSign
  );
  const sigCanvas = useRef<any>();

  useEffect(() => {
    if (sigCanvas.current) {
      sigCanvas.current.off();
    }
  }, []);

  return (
    <Box mt={5}>
      <Typography color="text.tertiary" mb={1} variant="h2" fontWeight="light">
        Subject Signature
      </Typography>
      <Box sx={{ width: "70%" }}>
        <ReactQuill
          readOnly={true}
          theme={"bubble"}
          className={"quill-container quill-bubble-md preview"}
          defaultValue={signature}
        />
        <Typography variant="body1" fontWeight="medium" mt={1}>
          Date
        </Typography>
        <Typography variant="body1" fontWeight="regular" color="text.lightText">
          {patientSign?.date
            ? patientSign?.date
            : moment().format("D MMM YYYY")}
        </Typography>
        <InputLabel
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            mt: "30px",
            mb: "7px",
          }}
          htmlFor="fullName"
        >
          Full name
        </InputLabel>
        {patientSign?.name ? (
          <Typography
            mb={1}
            variant="body1"
            fontWeight="regular"
            color="text.lightText"
          >
            {patientSign?.name}
          </Typography>
        ) : (
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        )}
        <Typography mt={4} mb={1} variant="body1" fontWeight="medium">
          SIGNATURE
        </Typography>
        {!patientSign?.sign ? (
          <>
            <SignatureCanvas
              ref={(ref) => {
                sigCanvas.current = ref;
              }}
              canvasProps={{ className: "canvas-element" }}
            />
          </>
        ) : (
          <img
            src={patientSign?.sign}
            alt="signature"
            style={{
              borderRadius: "6px",
              height: "160px",
              width: "100%",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PatientSignature;
