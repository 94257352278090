import {
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { LoadingContainer } from "./updateStudy.style";
import { PersonalConfigsType, TabProps } from "./updateStudy.type";

const PersonalTab = ({ editable, id }: TabProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [personalConfigs, setPersonalConfigs] = useState<PersonalConfigsType[]>(
    []
  );
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleUpdateConfig = async (values: any) => {
    try {
      setSubmitLoader(true);

      const body = {
        config: values?.personalConfigs?.map((data: any) => {
          return {
            id: data?.id,
            enabled: data?.enabled,
          };
        }),
      };
      const res: AxiosResponse = await http.patch(
        `/studies/${id}/config/personal-detail-fields`,
        body
      );
      toastMessage("success", res.data?.message);
      refreshPage();
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    const fetchPersonalConfigs = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(
          `/studies/${id}/config/personal-detail-fields`
        );
        const newConfigs = res.data.data.map((config: any) => {
          const { id, fieldName, fieldLabel, isAlwaysEnabled, enabled } =
            config;
          return {
            id: id,
            name: fieldName,
            label: fieldLabel,
            enabled: enabled,
            alwaysEnabled: isAlwaysEnabled,
          };
        });
        setPersonalConfigs(newConfigs);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchPersonalConfigs();
  }, [setPersonalConfigs, id, setIsLoading, toggleLoader]);

  return isLoading ? (
    <LoadingContainer>
      <CircularProgress size={50} />
    </LoadingContainer>
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ personalConfigs: personalConfigs }}
      onSubmit={(values) => {
        handleUpdateConfig(values);
      }}
    >
      {(formik) => (
        <Form style={{ maxWidth: "756px" }}>
          <Typography variant="h3" fontWeight="medium" mb={2.5}>
            Personal details fields
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="regular"
            color="text.grey"
            mb={3}
          >
            Please check the fields you would like to include in the subjects
            personal details. The subject will fill these in when they open the
            study for the first time.
          </Typography>
          <FormGroup sx={{ mb: 2.5 }}>
            {formik.values.personalConfigs?.map(
              (config: any, index: number) => {
                return (
                  <FormControlLabel
                    key={config?.id}
                    id={config?.id}
                    disabled={config?.alwaysEnabled}
                    checked={config?.enabled || false}
                    name={`personalConfigs[${index}].enabled`}
                    onChange={(e) => {
                      if (editable) formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    control={<Checkbox />}
                    label={config?.label}
                    labelPlacement="end"
                  />
                );
              }
            )}
          </FormGroup>
          {submitLoader ? (
            <CircularProgress size={25} />
          ) : (
            <Button variant="contained" type="submit">
              Save Changes
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PersonalTab;
