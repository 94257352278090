import { Box, Skeleton, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { noItemsStyles } from "./dashboard.style";
import AppointmentsItem from "./AppointmentsItem";

const AppointmentsTab = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [appointmentsList, setAppointmentsList] = useState<
    {
      id: string;
      studyName: string;
      patientsName: string;
      scheduledStartTime: string;
      subjectId: string;
      studyId: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        setIsLoading(true);
        const res: AxiosResponse = await http.get(`/appointments/list`);
        const apts = res.data?.data?.map((apt: any) => {
          return {
            id: apt?.id,
            subjectId: apt?.attendees[0]?.subjectMainRefId,
            studyId: apt?.study?.id,
            studyName: apt?.study?.name,
            patientsName: apt?.attendees[0]?.subject
              ? apt?.attendees[0]?.subject?.firstName +
                " " +
                apt?.attendees[0]?.subject?.lastName
              : "-",
            scheduledStartTime: apt?.scheduledStartTime,
          };
        });
        setAppointmentsList(apts);
        setIsLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setIsLoading(false);
      }
    };
    fetchAppointments();
  }, [setIsLoading, setAppointmentsList]);

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Skeleton variant="rounded" height="84px" animation="wave" />
          <Skeleton variant="rounded" height="84px" animation="wave" />
          <Skeleton variant="rounded" height="84px" animation="wave" />
        </Box>
      ) : appointmentsList?.length > 0 ? (
        appointmentsList.map((appointment) => (
          <AppointmentsItem key={appointment.id} data={appointment} />
        ))
      ) : (
        <Box sx={noItemsStyles}>
          <Typography variant="h2" color="text.grey">
            No Appointments found
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AppointmentsTab;
