export const languageMap: any = {
  en: "English",
  de: "German",
  es: "Spanish",
};
export const langArray: any[] = Object.entries(languageMap).map(
  ([key, value]) => {
    return {
      value: key,
      label: value,
    };
  }
);
