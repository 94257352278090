import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
export interface consentState {
  formText: string;
  checkbox: any[];
  signature: string;
  memberSignature: any[];
  loading: boolean;
  submitLoader: boolean;
  error: string;
}

const initialState: consentState = {
  formText: "",
  checkbox: [],
  signature: "I understand the information and I want to participate.",
  memberSignature: [],
  loading: false,
  submitLoader: false,
  error: "",
};

export const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    addFormText: (state, action) => {
      state.formText = action.payload;
    },
    addCheckbox: (state) => {
      state.checkbox.push({
        id: uuid(),
        label: "",
        statement: "",
        isChecked: true,
      });
    },
    deleteCheckbox: (state, action: PayloadAction<string>) => {
      state.checkbox = state.checkbox.filter(
        (item) => item.id !== action.payload
      );
    },
    toggleCheckbox: (state, action) => {
      state.checkbox[action.payload.index].isChecked = action.payload.isChecked;
    },
    addCheckboxData: (state, action) => {
      const { index, key, value } = action.payload;
      state.checkbox[index][key] = value;
    },
    addSignatureText: (state, action: PayloadAction<string>) => {
      state.signature = action.payload;
    },
    addMemberSignature: (state) => {
      state.memberSignature.push({
        id: uuid(),
        required: true,
        edit: false,
        header: "",
        statement: "",
        date: "",
        name: "",
      });
    },
    deleteMemberSignature: (state, action: PayloadAction<string>) => {
      state.memberSignature = state.memberSignature.filter(
        (item) => item.id !== action.payload
      );
    },
    editMemberSignature: (state, action) => {
      const { index, key, value } = action.payload;
      state.memberSignature[index][key] = value;
    },
    saveMemberSignature: (state, action) => {
      const { index, data } = action.payload;
      state.memberSignature[index] = data;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    saveAll: (state, action: PayloadAction<any>) => {
      const { formText, signature, memberSignature, checkbox } = action.payload;
      state.formText = formText;
      state.checkbox = checkbox;
      state.signature = signature;
      state.memberSignature = memberSignature;
    },
    setSubmitLoader: (state, action: PayloadAction<boolean>) => {
      state.submitLoader = action.payload;
    },
    setFetchingError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  addFormText,
  addCheckbox,
  deleteCheckbox,
  toggleCheckbox,
  addCheckboxData,
  addSignatureText,
  addMemberSignature,
  deleteMemberSignature,
  editMemberSignature,
  saveMemberSignature,
  setLoader,
  setSubmitLoader,
  setFetchingError,
  saveAll,
} = consentSlice.actions;
export default consentSlice.reducer;
