import ReactQuill from "react-quill";
import { useAppSelector } from "../../../Redux/hooks";

const FormText = () => {
  const { formText } = useAppSelector((state) => state.consentSign);

  return (
    <ReactQuill
      readOnly={true}
      theme={"bubble"}
      className={"quill-container quill-bubble-md preview"}
      defaultValue={formText}
    />
  );
};

export default FormText;
