import { styled, SxProps } from "@mui/material";
import { rowFlexStyleAlignCenter } from "../../Common/styles/flex";

export const ScoreContainer = styled("div")(() => ({
  height: "calc(100% - 64px)",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

export const ScoreContent = styled("div")(() => ({
  flex: 1,
  minHeight: "0px",
  overflow: "auto",
  padding: "20px",
}));

export const scoreHeaderStyle: SxProps = {
  height: "91px",
  backgroundColor: "#ffffff",
  width: "100%",
  borderBottom: "1px solid",
  borderColor: "border.type1",
  px: 2.5,
  ...rowFlexStyleAlignCenter,
};

export const questionListStyle: SxProps = {
  backgroundColor: "rgba(196, 196, 196, 0.14)",
};

export const questionListHeaderStyle: SxProps = {
  background: "#ffffff",
  border: 1,
  borderColor: "border.type1",
  borderRadius: "4px",
  typography: "subtitle2",
  fontWeight: "medium",
  padding: "12px",
};

export const questionListContentStyle: SxProps = {
  overflowY: "auto",
  height: "50vh",
};

export const questionListItemStyle: SxProps = {
  background: "#ffffff",
  border: 1,
  borderColor: "border.type1",
  borderRadius: "4px",
  typography: "subtitle1",
  fontWeight: "regular",
  p: 1.75,
  margin: 1,
  display: "flex",
  cursor: "pointer",
};

export const questionListItemNoStyle: SxProps = {
  flexShrink: 0,
  mr: 2,
  width: "10%",
};

export const scoreCalculatorStyle: SxProps = {
  border: 1,
  borderColor: "border.type1",
  background: "#ffffff",
  borderRadius: "4px",
  p: 2,
};

export const scoreCalculatorButtonStyle: SxProps = {
  cursor: "pointer",
  borderRadius: "4px",
  justifyContent: "center",
  py: 2,
  fontWeight: "bold",
  height: "60px",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  flex: "1 1 30%",
  minWidth: "0px",
};

export const scoreCalculatorButtonWrapperStyle: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: 2,
  flexWrap: "wrap",
  mb: 2,
};

export const scoreBoxStyle: SxProps = {
  borderRadius: "4px",
  py: 6,
  px: 2,
  background: "#ffffff",
  border: 1,
  borderColor: "border.type1",
  mb: 2,
};

export const scoreValidationHeaderStyle: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  padding: 1.5,
  borderBottom: 1,
  borderBottomColor: "border.type1",
};

export const scoreVItemActionStyle: SxProps = {
  display: "flex",
  justifyContent: "flex-end",
  borderTop: 1,
  borderTopColor: "border.type1",
  mt: 2,
};

export const previewQuestionWrapper: SxProps = {
  backgroundColor: "rgba(196, 196, 196, 0.14)",
  flexShrink: 0,
  width: "40%",
};
export const previewQuestionList: SxProps = {
  overflow: "auto",
  height: "60vh",
  p: 1,
};
