import { SxProps } from "@mui/material";

export const lightTextStyle: SxProps = {
  color: "text.grey",
};

export const iconStyle: SxProps = { marginBottom: "-5px", color: "#6B7280" };

export const textWithIconStyle: SxProps = {
  display: "flex",
  gap: "18px",
};

export const containerStyle: SxProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

export const itemsContainer: SxProps = {
  display: "flex",
  height: "600px",
  width: "100%",
  maxWidth: "1150px",
  border: "1px solid #D1D5DB",
  borderRadius: "12px",
  overflow: "hidden",
};

export const leftSideTopContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "35%",
  borderBottom: "1px solid #D1D5DB",
};

export const leftSideBottomContainer: SxProps = {
  padding: "16px",
  flex: 1,
  minHeight: "1px",
};

export const leftSideContainer: SxProps = {
  width: "34%",
  borderRight: "1px solid #D1D5DB",
  height: "100%",
  display: "flex",
  flexDirection: "column",
};

export const rightSideContainer: SxProps = {
  width: "66%",
  height: "100%",
  padding: "40px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

export const rightSuccessContainer: SxProps = {
  ...rightSideContainer,
  justifyContent: "center",
  alignItems: "center",
};

export const rightSideInternalContainer: SxProps = {
  display: "flex",
  gap: "25px",
  marginTop: "30px",
  flex: 1,
  minHeight: "1px",
};

export const timeSelectorContainer: SxProps = {
  borderLeft: "1px solid #D1D5DB",
  paddingLeft: "25px",
  width: "300px",
  flexShrink: 1,
  overflowY: "auto",
  pr: "10px",
  position: "relative",
};

export const timingheaderText: SxProps = {
  color: "text.grey",
  position: "sticky",
  top: 0,
  background: "#ffffff",
  zIndex: 2,
};

export const buttonStyle: SxProps = {
  borderColor: "text.link",
  color: "text.link",
  marginTop: "16px",
  height: "50px",
  "&:hover": {
    borderColor: "text.link",
    color: "text.link",
  },
};

export const confirmButtonStyle: SxProps = {
  backgroundColor: "text.link",
  "&:hover": {
    backgroundColor: "text.link",
  },
};

export const confirmTimeButtonStyle: SxProps = {
  backgroundColor: " #6B7280",
  height: "50px",
  "&:hover": {
    backgroundColor: " #6B7280",
  },
};

export const confirmButtonGroup: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
  height: "50px",
  marginTop: "16px",
};

export const dropDownContainer: SxProps = {
  display: "flex",
  gap: "20px",
  padding: "40px 0 0 5px",
};

export const dropDownStyle: SxProps = {
  "& .MuiInputBase-root": {
    fontWeight: 600,
    color: "text.grey",
  },
};
