import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import OpenTok from "./OpenTok";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FormText from "./FormText";
import PatientCheckbox from "./PatientCheckbox";
import MemberSignature from "./MemberSignature";
import PatientSignature from "./PatientSignature";
import "react-quill/dist/quill.snow.css";
import {
  loadSigningData,
  pollConsentRequest,
  submitData,
} from "../../../Redux/actions/consentSignAction";
import { signingCleanup } from "../../../Redux/reducers/consentSignSlice";
import { useInterval } from "../../../utils/customHooks";
import {
  signContainer,
  signHeader,
  LoaderContainer,
  consentContentWrapper,
  consentCallWrapper,
} from "./signing.style";

const ConsentPoll = () => {
  const dispatch = useAppDispatch();
  const { subjectId, id } = useParams();
  const { finalised, patientSign } = useAppSelector(
    (state) => state.consentSign
  );

  const callback = useCallback(() => {
    dispatch(pollConsentRequest(id || "", subjectId || ""));
  }, [dispatch, id, subjectId]);

  useInterval(callback, finalised || patientSign ? null : 5000);

  return <></>;
};

const ConsentSigning = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { subjectId, id } = useParams();
  const { title, finalised, openTokCreds, loading, error, formSubmitting } =
    useAppSelector((state) => state.consentSign);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(loadSigningData(id || "", subjectId || ""));
    return () => {
      dispatch(signingCleanup());
    };
  }, [dispatch, subjectId, id]);

  const finaliseData = () => {
    if (finalised) {
      navigate(-1);
    } else {
      dispatch(submitData(id || "", subjectId || ""));
    }
  };

  return (
    <>
      <Box sx={signHeader}>
        <IconButton onClick={handleBack}>
          <ChevronLeftIcon
            fontSize="large"
            sx={{
              color: "text.tertiary",
            }}
          />
        </IconButton>
        <Typography ml={2.5} variant="subtitle2" fontWeight="medium">
          {title || "Consent Form"}
        </Typography>
      </Box>
      <Box sx={signContainer}>
        {loading ? (
          <LoaderContainer>
            <CircularProgress size={25} />
          </LoaderContainer>
        ) : error ? (
          <Typography
            variant="h1"
            color="grey"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {error}
          </Typography>
        ) : (
          <>
            <Box sx={consentContentWrapper}>
              <ConsentPoll />
              <FormText />
              <PatientCheckbox />
              <PatientSignature />
              <MemberSignature />
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                {formSubmitting ? (
                  <Box sx={{ height: "50px" }}>
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  <Button
                    sx={{
                      height: "50px",
                      width: "65%",
                      borderRadius: "4px",
                    }}
                    variant="contained"
                    onClick={finaliseData}
                  >
                    {finalised ? "Go to Dashboard" : "Finalise Document"}
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={consentCallWrapper}>
              {openTokCreds && <OpenTok creds={openTokCreds} />}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ConsentSigning;
