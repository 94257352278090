import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  // MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  // FieldArray,
  Formik,
} from "formik";
import * as Yup from "yup";
import { ModalBaseStyles } from "../../Common/styles/modal";
import {
  inputLabelStyle,
  CloseButton,
  DividerWithBG,
} from "../../Common/UI/CommonModalDesign";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
// import { UserRoleListType } from "./userRole";

const yupSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email"),
  // roleArray: Yup.array().of(
  //   Yup.object().shape({
  //     role: Yup.string().required("Required"),
  //     site: Yup.string().required("Required"),
  //   })
  // ),
});

interface typeProps {
  open: boolean;
  closeModal: () => void;
  id?: string | null;
  refreshPage: Function;
}

function AddUserModal(props: typeProps) {
  const { id: studyId } = useParams();
  const { open, closeModal, id, refreshPage } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteUser = async (values: any) => {
    try {
      setIsLoading(true);
      const { email } = values;
      const body = {
        email: email,
        orgId: "80c9c35a-4e36-442f-acd3-6d4efb9691af",
      };
      const res = await http.post(`/studies/${studyId}/invite`, body);
      toastMessage("success", res.data?.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, p: 4, minHeight: "20vh" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h2">
            {id ? "Invite user" : "Manage user"}
          </Typography>
          <CloseButton buttonClick={closeModal} />
        </Box>
        <DividerWithBG />
        <Formik
          initialValues={{
            email: "",
            roleArray: [{ role: "", site: "" }],
          }}
          validationSchema={yupSchema}
          onSubmit={(values) => {
            handleInviteUser(values);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <InputLabel sx={inputLabelStyle} htmlFor="email">
                email
              </InputLabel>
              <TextField
                fullWidth
                error={
                  formik.touched?.email && formik.errors.email ? true : false
                }
                id="email"
                type="text"
                {...formik.getFieldProps("email")}
                helperText={
                  formik.touched?.email && formik.errors?.email
                    ? formik.errors?.email
                    : null
                }
                placeholder="Enter"
                variant="outlined"
              />

              {/* <Box sx={{ display: "flex", gap: "46%", mt: 2.5 }}>
                <Typography sx={inputLabelStyle}>role</Typography>
                <Typography sx={inputLabelStyle}>Site(s)</Typography>
              </Box>

              <FieldArray
                name="roleArray"
                render={(arrayHelpers) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    {formik.values.roleArray.map((value, index) => (
                      <Box sx={{ display: "flex", gap: 4 }} key={index}>
                        <TextField
                          fullWidth
                          error={
                            //@ts-ignore
                            formik.touched?.roleArray?.[index]?.role &&
                            //@ts-ignore
                            formik.errors?.roleArray?.[index]?.role
                              ? true
                              : false
                          }
                          type="text"
                          {...formik.getFieldProps(`roleArray[${index}].role`)}
                          helperText={
                            formik.touched?.roleArray?.[index]?.role &&
                            //@ts-ignore
                            formik.errors?.roleArray?.[index]?.role
                              ? //@ts-ignore
                                formik.errors?.roleArray?.[index]?.role
                              : null
                          }
                          select
                          placeholder="Please Enter"
                          variant="outlined"
                        >
                          {["Abc", "Xyz"].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          fullWidth
                          error={
                            //@ts-ignore
                            formik.touched?.roleArray?.[index]?.site &&
                            //@ts-ignore
                            formik.errors?.roleArray?.[index]?.site
                              ? true
                              : false
                          }
                          type="text"
                          {...formik.getFieldProps(`roleArray[${index}].site`)}
                          helperText={
                            formik.touched?.roleArray?.[index]?.site &&
                            //@ts-ignore
                            formik.errors?.roleArray?.[index]?.site
                              ? //@ts-ignore
                                formik.errors?.roleArray?.[index]?.site
                              : null
                          }
                          select
                          placeholder="Please Enter"
                          variant="outlined"
                        >
                          {["Abc", "Xyz"].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    ))}
                    <Box mb={2.5}>
                      {" "}
                      <Button
                        size="large"
                        onClick={() =>
                          arrayHelpers.push({ role: "", site: "" })
                        }
                        variant="outlined"
                      >
                        + Add another
                      </Button>
                    </Box>
                  </Box>
                )}
              /> */}

              <Box
                sx={{
                  mt: "40px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      mr: 3,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Button
                      sx={{ mr: 2.5 }}
                      variant="outlined"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Add User
                    </Button>
                  </>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default AddUserModal;
