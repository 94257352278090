export const AuditTrailData = [
    {
        id:1,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   ,
    {
        id:2,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:3,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:4,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:5,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:6,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:7,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:8,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:9,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , {
        id:10,
       dt: "30 Jan 2022 09:40 UTC",
        actor:"Study Team member",
        user:"Study Team member",
        sid:"100005",
        action:"Site Updated",
        info:'{“screeningId”.”100005”.”sitrName"}',
    }   , 
     
      ];
      