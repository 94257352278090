import { Typography } from "@mui/material";
import { CustomCard } from "./subjects.style";

type Props = {
  title: string;
  statusCount: number;
  color?: string;
};

const Card = ({ title, statusCount, color }: Props) => {
  return (
    <CustomCard
      sx={{
        "&:hover": {
          border: `2px solid ${color}`,
        },
      }}
    >
      <Typography variant="subtitle2" fontWeight="medium" color="text.tertiary">
        {title}
      </Typography>
      <Typography
        fontWeight="medium"
        sx={{ fontSize: "30px" }}
        color={color ? color : "#262626"}
      >
        {statusCount}
      </Typography>
    </CustomCard>
  );
};

export default Card;
