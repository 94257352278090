import {
  Box,
  Button,
  Typography,
  Avatar,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useState } from "react";
import AddQuestionnaireModal from "./AddQuestionnaireModal";
import { HeaderStyle, QuestionContainer } from "./screening.style";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  CardContentStyle,
  CardsContainer,
  StyledCard,
} from "./screening.style";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { useNavigate, useParams } from "react-router-dom";

const Screening = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState<any>(null);
  const [actionLoading, setActionLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [toggleLoader, setToggleLoader] = useState(false);

  const handleModalVisibility = () => {
    setSelected(null);
    setModalVisibility(true);
  };

  const handleModalClose = () => {
    setSelected(null);
    setModalVisibility(false);
  };

  const refreshPage = useCallback(() => {
    setToggleLoader((prev) => !prev);
  }, [setToggleLoader]);

  const fetchData = useCallback(
    async (page: number, append: boolean) => {
      try {
        if (!append) {
          setData([]);
        }
        setLoading(true);
        let url = `/questionnaires/list?page=${page}&size=12&studyId=${id}`;
        const res: AxiosResponse = await http.get(url);
        const questions = res?.data?.data?.questionnaires.map((q: any) => {
          return {
            id: q.id,
            name: q.name,
            imageUrl: q.imageUrl,
            status: q.status,
            description: q.description,
          };
        });
        if (append) {
          setData((prev) => [...prev, ...questions]);
          setPage((prev) => prev + 1);
        } else {
          setData(questions);
          setPage(1);
        }
        setTotal(res.data.data.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setTotal, setPage, setData, setLoading, id]
  );

  useEffect(() => {
    fetchData(1, false);
  }, [fetchData, toggleLoader]);

  const loadMore = () => {
    fetchData(page + 1, true);
  };

  const qCardAction = (q: any) => {
    if (q?.status === "draft") {
      navigate(`builder?id=${q.id}`);
    } else {
      navigate(`preview?id=${q.id}`);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editQuestion = () => {
    setModalVisibility(true);
    setAnchorEl(null);
  };

  const duplicateQuestion = async () => {
    try {
      setActionLoading(true);
      const res = await http.post(`/questionnaires/${selected.id}/duplicate`);
      setAnchorEl(null);
      setSelected(null);
      setActionLoading(false);
      toastMessage("success", res.data.message);
      refreshPage();
    } catch (err) {
      setActionLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const deleteQuestion = async () => {
    try {
      setActionLoading(true);
      const res = await http.delete(`/questionnaires/${selected.id}`);
      setAnchorEl(null);
      setSelected(null);
      setActionLoading(false);
      toastMessage("success", res.data.message);
      refreshPage();
    } catch (err) {
      setActionLoading(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      <Box sx={HeaderStyle}>
        <Typography variant="subtitle2" fontWeight="medium">
          Screening Questionnaires
        </Typography>
        <Button
          onClick={handleModalVisibility}
          variant="contained"
          startIcon={<AddIcon />}
          size="large"
        >
          New Screening Questionnaire
        </Button>
      </Box>
      <Box sx={QuestionContainer}>
        {!loading && data.length === 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <Typography variant="h2" color="text.grey">
              No Questionnaires found.
            </Typography>
          </Box>
        ) : (
          <Box sx={CardsContainer}>
            {data.map((item) => {
              return (
                <StyledCard
                  variant="outlined"
                  key={item.id}
                  onClick={() => qCardAction(item)}
                >
                  <CardHeader
                    sx={{
                      height: "56px",
                    }}
                    avatar={
                      <Avatar
                        sx={{
                          width: 25,
                          height: 25,
                        }}
                      />
                    }
                    action={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelected(item);
                          handleClick(e);
                        }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    }
                  />
                  <CardMedia
                    component="img"
                    height="161"
                    alt={item.name}
                    image={item.imageUrl || "/default.jpg"}
                  />
                  <CardContent sx={CardContentStyle}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      fontWeight="regular"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                      }}
                      color="rgba(33, 33, 33, 0.6)"
                      fontWeight="light"
                    >
                      {item.time}
                    </Typography>
                  </CardContent>
                </StyledCard>
              );
            })}
          </Box>
        )}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          data.length < total && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
              <Button variant="contained" onClick={loadMore}>
                Load More
              </Button>
            </Box>
          )
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {actionLoading && (
          <Box
            sx={{
              height: "100px",
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!actionLoading && <MenuItem onClick={editQuestion}>Edit</MenuItem>}
        {!actionLoading && (
          <MenuItem onClick={duplicateQuestion}>Duplicate</MenuItem>
        )}
        {!actionLoading && <MenuItem onClick={deleteQuestion}>Delete</MenuItem>}
      </Menu>
      {modalVisibility && (
        <AddQuestionnaireModal
          visibility={modalVisibility}
          closeModal={handleModalClose}
          id={selected?.id}
          refreshPage={refreshPage}
          questionData={selected}
        />
      )}
    </>
  );
};

export default Screening;
