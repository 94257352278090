import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  changeQuestionType,
  cloneQuestionItem,
  createNewOption,
  createQuestionSkeleton,
} from "../../utils/question";

export interface QuestionState {
  questionName: string;
  error: string;
  loading: boolean;
  formSubmitting: boolean;
  status: string;
  questions: any[];
  qid: string;
  formDirty: boolean;
  editable: boolean;
  //Modal Props
  showPropModal: boolean;
  modalQuestion: any;
  modalQIndex: number | null;
  modalQparentIndex: number | null;
}

const initialState: QuestionState = {
  questionName: "",
  error: "",
  loading: true,
  formSubmitting: false,
  status: "",
  questions: [],
  qid: "",
  formDirty: false,
  editable: false,
  showPropModal: false,
  modalQuestion: null,
  modalQIndex: null,
  modalQparentIndex: null,
};

export const questionSlice = createSlice({
  name: "question",
  initialState: initialState,
  reducers: {
    setQuestionError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setQuestionLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setQuestionSubmitting: (state, action: PayloadAction<boolean>) => {
      state.formSubmitting = action.payload;
    },
    setQuestionnaireDetails: (state, action: PayloadAction<QuestionState>) => {
      state.questionName = action.payload.questionName;
      state.error = action.payload.error;
      state.loading = action.payload.loading;
      state.formSubmitting = action.payload.formSubmitting;
      state.status = action.payload.status;
      state.questions = action.payload.questions;
      state.qid = action.payload.qid;
      state.formDirty = action.payload.formDirty;
      state.editable = action.payload.editable;
      state.showPropModal = action.payload.showPropModal;
      state.modalQuestion = action.payload.modalQuestion;
      state.modalQIndex = action.payload.modalQIndex;
      state.modalQparentIndex = action.payload.modalQparentIndex;
    },
    handleQuestionDrop: (
      state,
      action: PayloadAction<{
        type: string;
        index?: number;
      }>
    ) => {
      let newItem = createQuestionSkeleton(action.payload.type);

      if (typeof action.payload.index === "number") {
        state.questions.splice(action.payload.index, 0, newItem);
      } else {
        state.questions.push(newItem);
      }
      state.formDirty = true;
    },
    handleQuestionMove: (
      state,
      action: PayloadAction<{
        dragIndex: number;
        dropIndex: number;
      }>
    ) => {
      const question = state.questions[action.payload.dragIndex];
      state.questions.splice(action.payload.dragIndex, 1);
      state.questions.splice(action.payload.dropIndex, 0, question);
      state.formDirty = true;
    },
    handleQuestionDelete: (
      state,
      action: PayloadAction<{
        currentQIndex: number;
        parentQIndex?: number;
      }>
    ) => {
      if (action.payload.parentQIndex !== undefined) {
        const subs = state.questions[action.payload.parentQIndex]?.questions;
        if (subs && subs.length > 1) {
          subs.splice(action.payload.currentQIndex, 1);
          state.formDirty = true;
        }
      } else {
        state.questions.splice(action.payload.currentQIndex, 1);
        state.formDirty = true;
      }
    },
    handleQuestionClone: (
      state,
      action: PayloadAction<{
        currentQIndex: number;
        parentQIndex?: number;
      }>
    ) => {
      if (action.payload.parentQIndex !== undefined) {
        const subs = state.questions[action.payload.parentQIndex]?.questions;
        if (subs && subs.length > 1) {
          const sourceItem = subs[action.payload.currentQIndex];
          const clonedItem = cloneQuestionItem(sourceItem);
          subs.splice(action.payload.currentQIndex + 1, 0, clonedItem);
          state.formDirty = true;
        }
      } else {
        const sourceItem = state.questions[action.payload.currentQIndex];
        const clonedItem = cloneQuestionItem(sourceItem);
        state.questions.splice(action.payload.currentQIndex + 1, 0, clonedItem);
        state.formDirty = true;
      }
    },
    handleQuestionValueChange: (
      state,
      action: PayloadAction<{
        index: number;
        value: string;
        key: string;
        parentIndex?: number;
      }>
    ) => {
      let question;
      if (action.payload.parentIndex !== undefined) {
        const subs = state.questions[action.payload.parentIndex]?.questions;
        if (subs) {
          question = subs[action.payload.index];
        }
      } else {
        question = state.questions[action.payload.index];
      }

      if (question) {
        question[action.payload.key] = action.payload.value;
        state.formDirty = true;
      }
    },
    handleOptionChange: (
      state,
      action: PayloadAction<{
        optionIndex: number;
        questionIndex: number;
        value: string;
        key: string;
        parentIndex?: number;
      }>
    ) => {
      let option;
      if (action.payload.parentIndex !== undefined) {
        const subs = state.questions[action.payload.parentIndex]?.questions;
        if (subs) {
          option =
            subs[action.payload.questionIndex]?.fields[
              action.payload.optionIndex
            ];
        }
      } else {
        option =
          state.questions[action.payload.questionIndex]?.fields[
            action.payload.optionIndex
          ];
      }

      if (option) {
        option[action.payload.key] = action.payload.value;
        state.formDirty = true;
      }
    },
    handleOptionOps: (
      state,
      action: PayloadAction<{
        questionIndex: number;
        optionIndex: number;
        isAdd: boolean;
        parentIndex?: number;
      }>
    ) => {
      let options;
      if (action.payload.parentIndex !== undefined) {
        const subs = state.questions[action.payload.parentIndex]?.questions;
        if (subs) {
          options = subs[action.payload.questionIndex]?.fields;
        }
      } else {
        options = state.questions[action.payload.questionIndex]?.fields;
      }

      if (options && action.payload.isAdd) {
        options.push(createNewOption());
        state.formDirty = true;
      } else if (options && options.length > 1) {
        options.splice(action.payload.optionIndex, 1);
        state.formDirty = true;
      }
    },
    addSubquestion: (
      state,
      action: PayloadAction<{
        questionIndex: number;
      }>
    ) => {
      let newItem = createQuestionSkeleton("short_text");
      const question = state.questions[action.payload.questionIndex];
      if (question?.questions) {
        question?.questions.push(newItem);
        state.formDirty = true;
      }
    },
    changeSubquestionType: (
      state,
      action: PayloadAction<{
        questionIndex: number;
        parentIndex: number;
        type: string;
      }>
    ) => {
      const question =
        state.questions[action.payload.parentIndex]?.questions?.[
          action.payload.questionIndex
        ];
      if (question) {
        state.questions[action.payload.parentIndex].questions[
          action.payload.questionIndex
        ] = changeQuestionType(question, action.payload.type);
        state.formDirty = true;
      }
    },
    saveQuestionFromSettings: (
      state,
      action: PayloadAction<{
        newQuestion: any;
      }>
    ) => {
      if (state.modalQparentIndex !== null && state.modalQIndex !== null) {
        const subs = state.questions[state.modalQparentIndex]?.questions;
        subs[state.modalQIndex] = action.payload.newQuestion;
        state.formDirty = true;
      } else if (state.modalQIndex !== null) {
        state.questions[state.modalQIndex] = action.payload.newQuestion;
        state.formDirty = true;
      }
      state.showPropModal = false;
      state.modalQuestion = null;
      state.modalQparentIndex = null;
      state.modalQIndex = null;
    },
    setQuestionModalDetails: (
      state,
      action: PayloadAction<{
        show: boolean;
        question: any;
        currentIndex: number | null;
        parentIndex: number | null;
      }>
    ) => {
      state.showPropModal = action.payload.show;
      state.modalQuestion = action.payload.question;
      state.modalQparentIndex = action.payload.parentIndex;
      state.modalQIndex = action.payload.currentIndex;
    },
  },
});

export const {
  setQuestionnaireDetails,
  handleQuestionDrop,
  handleQuestionMove,
  handleQuestionClone,
  handleQuestionDelete,
  handleQuestionValueChange,
  handleOptionChange,
  handleOptionOps,
  addSubquestion,
  changeSubquestionType,
  saveQuestionFromSettings,
  setQuestionModalDetails,
  setQuestionLoader,
  setQuestionSubmitting,
  setQuestionError,
} = questionSlice.actions;

export default questionSlice.reducer;
