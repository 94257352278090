import { useState } from "react";
import Box from "@mui/material/Box";
import ProfileTab from "./ProfileTab";
import ConsentFormsTab from "./ConsentFormsTab";
import AuditTrailTab from "./AuditTrailTab";
import {
  a11yProps,
  StyledTab,
  StyledTabs,
  MainTabPanel,
} from "../../../Common/UI/TabPanel";

export default function TabsContainer() {
  const [value, setValue] = useState("profile");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "border.type1" }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Profile" value="profile" {...a11yProps(0)} />
          <StyledTab
            label="Consent forms"
            value="consent forms"
            {...a11yProps(1)}
          />
          <StyledTab
            label="Audit trail"
            value="audit trail"
            {...a11yProps(2)}
          />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 200px)",
          overflow: "auto",
          bgcolor: "background.lightBg",
        }}
      >
        <MainTabPanel value={value} index={"profile"}>
          <ProfileTab />
        </MainTabPanel>
        <MainTabPanel value={value} index={"consent forms"}>
          <ConsentFormsTab />
        </MainTabPanel>
        <MainTabPanel value={value} index={"audit trail"}>
          <AuditTrailTab />
        </MainTabPanel>
      </Box>
    </>
  );
}
