import { useEffect, useState } from "react";
import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import http from "../../utils/http";
import { rowFlexStyleAlignCenter } from "../Common/styles/flex";

const containerStyle: SxProps = {
  height: "100%",
  width: "100%",
  ...rowFlexStyleAlignCenter,
  justifyContent: "center",
};

function Check() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const handleStatus = async () => {
      try {
        const body = {
          token,
        };
        const res = await http.post("/auth/status", body);
        const { status, email } = res.data.data;
        if (status) {
          sessionStorage.setItem("dashboard-tabs", "invitation");
          navigate("/dashboard");
        } else {
          navigate(`../signup?email=${email}&token=${token}`);
        }
      } catch (err) {
        setError("Something went wrong. Please try again later.");
        setLoading(false);
      }
    };
    if (token) {
      handleStatus();
    } else {
      setError("Something went wrong. Please try again later.");
      setLoading(false);
    }
  }, [token, setError]);

  return (
    <Box sx={containerStyle}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography variant="h1" color="grey">
          {error}
        </Typography>
      ) : null}
    </Box>
  );
}

export default Check;
