import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useRef } from "react";
import ReactQuill from "react-quill";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import SignatureCanvas from "react-signature-canvas";
import { setAdminSign } from "../../../Redux/reducers/consentSignSlice";

// TODO: Class styles

const SignatureItem = ({ item }: any) => {
  const dispatch = useAppDispatch();
  const sigCanvas = useRef<any>();
  const { adminSign, finalised } = useAppSelector((state) => state.consentSign);

  const clear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const changeName = (value: string) => {
    let admin: any = {};
    if (adminSign) {
      admin = {
        ...adminSign,
      };
    }
    admin.name = value;
    dispatch(setAdminSign(admin));
  };

  const submitSignature = async () => {
    try {
      if (sigCanvas.current) {
        if (sigCanvas.current.isEmpty()) {
          toastMessage("warning", "Signature Cannot be empty");
          return;
        }
      } else {
        toastMessage("warning", "Unable to fetch Signature");
        return;
      }
      const image = sigCanvas.current.toDataURL();
      if (image) {
        let admin: any = {};
        if (adminSign) {
          admin = {
            ...adminSign,
          };
        }
        admin.sign = image;
        dispatch(setAdminSign(admin));
      }
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  return (
    <Box mt={5}>
      <Typography color="text.tertiary" mb={1} variant="h2" fontWeight="light">
        {item.header}
      </Typography>
      <Box sx={{ width: "70%" }}>
        <ReactQuill
          readOnly={true}
          theme={"bubble"}
          className={"quill-container quill-bubble-md preview"}
          defaultValue={item.statement}
        />
        <Typography variant="body1" fontWeight="medium" mt={1}>
          Date
        </Typography>
        <Typography variant="body1" fontWeight="regular" color="text.lightText">
          {adminSign?.date ? adminSign?.date : moment().format("D MMM YYYY")}
        </Typography>
        <InputLabel
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            mt: "30px",
            mb: "7px",
          }}
          htmlFor="fullName"
        >
          Full name
        </InputLabel>
        {finalised ? (
          <Typography
            mb={1}
            variant="body1"
            fontWeight="regular"
            color="text.lightText"
          >
            {adminSign?.name}
          </Typography>
        ) : (
          <TextField
            fullWidth
            value={adminSign?.name || ""}
            onChange={(e) => changeName(e.target.value)}
            id="fullName"
            type="text"
            variant="outlined"
            InputProps={{
              readOnly: finalised,
            }}
          />
        )}
        <Typography mt={4} mb={1} variant="body1" fontWeight="medium">
          SIGNATURE
        </Typography>
        {!adminSign?.sign ? (
          <>
            <SignatureCanvas
              ref={(ref) => {
                sigCanvas.current = ref;
              }}
              canvasProps={{ className: "canvas-element" }}
            />
            <Box mt={4} mb={5}>
              <Button variant="contained" onClick={submitSignature}>
                Submit
              </Button>
              <Button sx={{ ml: "10px" }} variant="outlined" onClick={clear}>
                Clear
              </Button>
            </Box>
          </>
        ) : (
          <img
            src={adminSign?.sign}
            alt="signature"
            style={{
              borderRadius: "6px",
              height: "160px",
              width: "100%",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default function MemberSignature() {
  const { memberSignature } = useAppSelector((state) => state.consentSign);
  return (
    <Box>
      {memberSignature?.map((item: any) => {
        return <SignatureItem key={item.id} item={item} />;
      })}
    </Box>
  );
}
