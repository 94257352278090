import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalBaseStyles } from "../../../Common/styles/modal";

const PublishModal = ({ open, closeModal }: any) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, p: 2.5, minHeight: "245px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="subtitle2"
            color="text.tertiary"
            fontWeight="medium"
          >
            Confirm subject identification
          </Typography>
          <IconButton onClick={closeModal}>
            <CloseIcon sx={{ color: "text.tertiary" }} />
          </IconButton>
        </Box>
        <Divider
          sx={{
            borderColor: "border.type1",
            margin: "10px 0 20px 0",
          }}
        />
        <Typography
          variant="body1"
          color="text.tertiary"
          fontWeight="regular"
          mb={5}
        >
          Are you sure you wish to publish Econsent Form{" "}
          <Box component="span" fontWeight="bold">
            {" "}
            ABC Consent form, Version 1.0, 28 April 2022?
          </Box>{" "}
          Please ensure you have completed the necessary review and approval
          processes prior to publishing as it will no longer be possible to edit
          the content.
        </Typography>
        <Box>
          <Button
            variant="contained"
            sx={{
              mr: 2.5,
            }}
          >
            Publish Now
          </Button>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PublishModal;
