import { Box, Typography } from "@mui/material";
import { ConfigContainer } from "../updateStudy.style";
import ScreeningOptions from "./ScreeningOptions";
import ConsentOptions from "./ConsentOptions";
import { TabProps } from "../updateStudy.type";
import AutoCopyOption from "./AutoCopyOption";

const ConfigurationsTab = ({ editable, id }: TabProps) => {
  return (
    <>
      <Typography variant="h3" fontWeight="medium" mb={2.5}>
        Configurations
      </Typography>
      <Box sx={ConfigContainer}>
        <ConsentOptions editable={editable} id={id} />
        <ScreeningOptions editable={editable} id={id} />
        <AutoCopyOption editable={editable} id={id} />
      </Box>
    </>
  );
};

export default ConfigurationsTab;
