import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { signCheckbox } from "../../../Redux/reducers/consentSignSlice";

export default function PatientCheckbox() {
  const dispatch = useAppDispatch();
  const { checkbox } = useAppSelector((state) => state.consentSign);

  const handleChange = (key: string, checked: boolean) => {
    // Disabled for admin
    // dispatch(signCheckbox({ key, checked }));
  };

  if (checkbox.length === 0) {
    return null;
  }

  return (
    <Box sx={{ my: 5 }}>
      {checkbox?.map((item: any) => {
        return (
          <Box key={item.id} title={item.label || ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isChecked}
                  onChange={(e) => handleChange(item.id, e.target.checked)}
                />
              }
              label={
                <Typography variant="subtitle1" fontWeight="medium">
                  {item.statement}
                </Typography>
              }
            />
          </Box>
        );
      })}
    </Box>
  );
}
