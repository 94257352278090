import { v4 as uuid } from "uuid";

export const consentViewFormat = (config: any[]) => {
  let formText = "";
  let checkbox: any[] = [];
  let memberSignature: any[] = [];
  let signature = "I understand the information and I want to participate.";
  config.forEach((item) => {
    if (item.type === "text") {
      formText = item.content;
    } else if (item.type === "checkbox") {
      checkbox.push({
        id: uuid(),
        label: item.label,
        statement: item.statement,
        isChecked: false,
      });
    } else if (item.type === "subject_signature") {
      signature = item.content;
    } else if (item.type === "signature") {
      memberSignature.push({
        id: uuid(),
        required: item.required,
        edit: false,
        header: item.header,
        statement: item.statement,
        date: true,
        name: true,
        signatureArea: true,
      });
    }
  });
  return { formText, signature, memberSignature, checkbox };
};

export const consentSaveFormat = (
  formtext: string,
  checkbox?: any[],
  signature?: string,
  memberSignature?: any[]
) => {
  const config = [];
  let id = 1;
  if (!formtext) {
    throw new Error("Form text cannot be empty");
  } else {
    config.push({ id: id++, type: "text", content: formtext });
  }

  checkbox?.forEach((item: any, idx) => {
    if (!item.label || !item.statement) {
      throw new Error(`Checkbox ${idx} has a missing property`);
    } else {
      config.push({
        id: id++,
        type: "checkbox",
        label: item.label,
        statement: item.statement,
      });
    }
  });

  if (!signature) {
    throw new Error("Signature cannot be empty");
  } else {
    config.push({ id: id++, type: "subject_signature", content: signature });
  }

  memberSignature?.forEach((item: any) => {
    config.push({
      id: id++,
      type: "signature",
      header: item.header,
      statement: item.statement,
      required: item.required,
      date: true,
      name: true,
      signatureArea: true,
    });
  });

  return config;
};
